import { useMutation } from "@tanstack/react-query"
import { Form, Input } from "antd"
import { Button } from "components"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"
import NewModalWrapper from "components/new-stuff/ui/modals/NewModalWrapper"
import React, { useState } from "react"
import { useAppNotificationStore } from "stores"
import getIdFromKey from "utils/getIdFromKey"
import { axiosInstance } from "utils/request"
type Props = {
  onClose: () => void
  invalidateQueries: () => void
  open: boolean
  paymentId: string
  x: string
}

export const UnreversePayment = ({
  onClose,
  open,
  paymentId,
  x,
  invalidateQueries,
}: Props) => {
  const { toast } = useAppNotificationStore()
  const [comment, setComment] = useState("")
  const {
    isLoading: isSubmittingUnreverseSingle,
    mutate: submitUnreverseSingle,
  } = useMutation({
    mutationFn: async (body: string) => {
      const res = await axiosInstance.post(
        `/admin/payment_schedules/unreverse_payment_schedule_item?id=${paymentId}&comment=${body}`
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data.status !== "error") {
        onClose()
        {
          data.msg && toast.success(data.msg)
        }
        invalidateQueries()
        setComment("")
        mixPanelEvent("pay-schedule-payout-unreverse-success")
      } else {
        onClose()
        setComment("")
        {
          data.msg && toast.error(data.msg)
        }
      }
    },
    onError: (data: any) => {
      onClose()
      setComment("")
      {
        data.msg && toast.error(data.msg)
      }
    },
  })
  const { isLoading: isSubmittingUnreverseAll, mutate: submitUnreverseAll } =
    useMutation({
      mutationFn: async (body: string) => {
        const res = await axiosInstance.post(
          `/admin/payment_schedules/unreverse_payment_schedule_items?id=${paymentId}&comment=${body}`
        )
        return res.data
      },
      onSuccess: (data) => {
        if (data.status !== "error") {
          onClose()
          {
            data.msg && toast.success(data.msg)
          }
          mixPanelEvent("pay-schedule-payout-unreverse-success")
          invalidateQueries()
          setComment("")
        } else {
          onClose()
          setComment("")
          {
            data.msg && toast.error(data.msg)
          }
        }
      },
      onError: (data: any) => {
        onClose()
        setComment("")
        {
          data.msg && toast.error(data.msg)
        }
      },
    })
  return (
    <NewModalWrapper
      open={open}
      title={
        paymentId == getIdFromKey(x || "")
          ? "Unreverse Payments"
          : "Unreverse Payment"
      }
      className="w-[750px]"
      onClose={onClose}
    >
      <div className="px-5 py-3">
        <p className=" text-neutral300 mb-5 text-sm">
          {paymentId == getIdFromKey(x || "")
            ? `Only reversed payments will be unreversed. Provide reason to
                continue.`
            : `This payment will be included to  future schedules for this beneficiary. Provide reason to continue.`}
        </p>
        <Form.Item
          name="reason"
          rules={[
            {
              required: true,
              message: "Please input your reason!",
            },
          ]}
        >
          <Input
            className="h-10 mb-5"
            placeholder="Reason"
            onChange={(e) => setComment(e.target.value)}
          />
        </Form.Item>
      </div>
      <div className="border py-5 px-6 flex gap-4  justify-end">
        <Button
          title="cancel"
          color="secondary"
          onClick={onClose}
        >
          Cancel
        </Button>

        <Button
          title="reverse"
          color="primary"
          // onClick={onFinish}
          submitting={isSubmittingUnreverseAll || isSubmittingUnreverseSingle}
          disabled={comment == "" ? true : false}
          onClick={() => {
            mixPanelEvent("pay-schedule-payout-unreverse-init")
            {
              paymentId == getIdFromKey(x || "")
                ? submitUnreverseAll(comment)
                : submitUnreverseSingle(comment)
            }
          }}
        >
          Unreverse
        </Button>
      </div>
    </NewModalWrapper>
  )
}

export default UnreversePayment
