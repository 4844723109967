import axios from "axios"
import React, { Dispatch, FC } from "react"
import ReactQuill, { ReactQuillProps } from "react-quill"
import "react-quill/dist/quill.snow.css"

type Props = {
  value?: string | undefined
}

const AppEditor: FC<Props & ReactQuillProps> = ({
  value,

  ...rest
}) => {
  const modules = {
    toolbar: {
      // Add image upload button to the toolbar
      container: [
        [],
        [{ header: "1" }, { header: "2" }, { font: [] }],
        ["bold", "italic", "underline"],
        // [
        //   { list: "ordered" },
        //   { list: "bullet" },
        //   { indent: "-1" },
        //   { indent: "+1" },
        // ],
        ["link"], // Add 'image' here
        // ["clean"],
      ],
    },
  }

  return (
    <>
      <div className="border rounded-md p-2">
        <ReactQuill
          theme="snow"
          value={value || ``}
          preserveWhitespace
          {...rest}
          modules={modules}
        />
      </div>
    </>
  )
}

export default AppEditor
