import { Button, H2, H4 } from "components"
import { useNavigate, useSearchParams } from "react-router-dom"
import { Form, Input, Spin } from "antd"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { IOfferletter } from "./typing"
import { formatCurrencyNoSymbol } from "utils/currency"
import { toast } from "react-toastify"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"

const AcceptOfferLetter = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const queryClient = useQueryClient()

  const userId = searchParams.get("uId")
  const code = searchParams.get("code")

  if (!userId) {
    navigate("/auth/login")
  }

  const { data: userData, isFetching: isUserDataLoading } =
    useQuery<IOfferletter>({
      queryKey: ["/offer/view", { uId: userId, code }],
      queryFn: async () => {
        const res = await axiosInstance.get(
          `/offer/view/index?uId=${encodeURIComponent(
            userId || ""
          )}&code=${encodeURIComponent(code || "")}`
        )
        return res.data
      },
      onError(err) {
        navigate("/auth/login")
      },
      retry: 1,
    })

  const { isLoading: isSubmitting, mutate: submit } = useMutation({
    mutationFn: async (body: any) => {
      const res = await axiosInstance.post(`/offer/accept`, body)
      return res.data
    },
    onSuccess: (data) => {
      if (data.status === "success") {
        queryClient.invalidateQueries({
          queryKey: ["/offer/view", { uId: userId, code }],
        })
        mixPanelEvent(`people-offers-accept-success`)
      } else {
        toast.error(data.msg)
      }
    },
  })

  const onFinish = (values: any) => {
    mixPanelEvent(`people-offers-accept-init`)
    submit({
      offer_id: userData?.offer_id?.toString(),
      code,
      signature: values.signature,
    })
  }

  return (
    <div className="">
      {/* form */}
      {isUserDataLoading ? (
        <div className="grid place-content-center grow ">
          <Spin size="large" />
        </div>
      ) : (
        <div className="">
          <div className="py-14 px-6 xl:px-16">
            <div className="border shadow-xl">
              <div className="flex justify-between p-6 mb-4 bg-neutral20">
                <H2 className="text-neutral700 font-bold">Job Offer</H2>
              </div>

              {userData?.offer_status_id === 1 ? (
                <div className="mb-14 mt-4 ">
                  <div className="px-6 mb-6 font-circular">
                    <H4 className="text-neutral600 mb-1">
                      Congratulations on your new job!
                    </H4>
                    <p className="text-neutral500 mb-6">
                      Your new teammates are super excited to have you join
                      them.
                    </p>
                    <p className="text-neutral500 max-w-screen-sm">
                      We’ve sent you an email with details on next steps and a
                      signed copy of your offer letter attached. We wish you the
                      very best with your new job.
                    </p>
                  </div>
                  <hr className="mb-4" />
                  <div className="flex flex-col space-y-2 my-4 px-6 text-neutral500 text-base font-circular">
                    <div className="g">
                      <span className="text-neutral400">Your Position:</span>{" "}
                      <span className="font-semibold">
                        {userData?.employee_title}
                      </span>
                    </div>
                    <div className="g">
                      <span className="text-neutral400">Your Salary:</span>{" "}
                      <span className="font-semibold">
                        {userData?.currency_symbol}{" "}
                        {formatCurrencyNoSymbol(userData?.gross || 0)} per year
                      </span>
                    </div>
                    <div className="g">
                      <span className="text-neutral400">Your Start Date:</span>{" "}
                      <span className="font-semibold">
                        {userData?.hire_date}
                      </span>
                    </div>
                    <div className="g">
                      <span className="text-neutral400">Your Workplace:</span>{" "}
                      <span className="font-semibold">
                        {userData?.office_display_name} -{" "}
                        {userData?.office_full_address}
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  {/* ======== Header======== */}
                  {/* ======== Header======== */}

                  <div className="flex flex-col space-y-2 my-4 px-6 text-neutral500 text-sm font-circular">
                    <div className="g">
                      <span className="text-neutral400">Your Position:</span>{" "}
                      <span className="font-semibold">
                        {userData?.employee_title}
                      </span>
                    </div>
                    <div className="g">
                      <span className="text-neutral400">Your Salary:</span>{" "}
                      <span className="font-semibold">
                        {userData?.currency_symbol}{" "}
                        {formatCurrencyNoSymbol(userData?.gross || 0)} per year
                      </span>
                    </div>
                    <div className="g">
                      <span className="text-neutral400">Your Start Date:</span>{" "}
                      <span className="font-semibold">
                        {userData?.hire_date}
                      </span>
                    </div>
                    <div className="g">
                      <span className="text-neutral400">Your Workplace:</span>{" "}
                      <span className="font-semibold">
                        {userData?.office_display_name} -{" "}
                        {userData?.office_full_address}
                      </span>
                    </div>
                    <div className="g">
                      <span className="text-neutral400">Expires after:</span>{" "}
                      <span className="font-semibold">
                        {userData?.expire_date}
                      </span>
                    </div>
                  </div>

                  <hr className="mb-6" />

                  <div className="px-6">
                    {/* ================ */}
                    {/* ======LETTER==== */}

                    <div
                      className="prose prose-sm"
                      dangerouslySetInnerHTML={{
                        __html: userData?.contents || "",
                      }}
                    />
                  </div>

                  <hr className="my-6" />

                  {/* ================ */}
                  {/* ======FORM==== */}
                  <div className="grow px-6 mb-14">
                    <Form
                      name="accept offer"
                      layout="vertical"
                      onFinish={onFinish}
                      style={{ maxWidth: 486 }}
                    >
                      <Form.Item
                        name="signature"
                        rules={[
                          {
                            required: true,
                            message: "Please enter signature",
                          },
                        ]}
                        label="Your signature"
                      >
                        <Input className=" font-autograph text-3xl" />
                      </Form.Item>

                      <Form.Item
                        name="agree"
                        rules={[
                          {
                            required: true,
                            message: "Please check the box",
                          },
                        ]}
                      >
                        <label
                          htmlFor="agree"
                          className="flex gap-4"
                        >
                          <input
                            type="checkbox"
                            name="agree"
                            id="agree"
                            className="checked:bg-primary500 checked:border-transparent"
                          />
                          <span>
                            I agree to electronically sign this document. I
                            acknowledge that this constitutes a legal signature
                            and that I am entering an employment agreement with
                            as defined in this letter.
                          </span>
                        </label>
                      </Form.Item>
                      <div className="pt-8">
                        <Button
                          className="w-full"
                          type="submit"
                          submitting={isSubmitting}
                          disabled={isSubmitting}
                          color="primary"
                        >
                          Sign Offer
                        </Button>
                      </div>
                    </Form>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default AcceptOfferLetter

interface IUser {
  userId: string
  code: string
  fullName: string
  email: string
}
