import { Form, Input, Spin } from "antd"
import React, { useState } from "react"
import { Button } from "components"

import passwordHidden from "../../../../assets/illustrations/password_hidden.svg"
import { useMutation } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { newBaseUrl } from "utils/newbaseurl"
import { useAppNotificationStore } from "stores"
import { AntDesignInput } from "components/antd/AntDesignInput"
import NewModalWrapper from "components/new-stuff/ui/modals/NewModalWrapper"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"

const UpdatePinModal: React.FC<{
  closeModal: Function
  invalidateQueries: Function
  refetch: any
  open: boolean
}> = ({ closeModal, invalidateQueries, refetch, open }) => {
  const { toast } = useAppNotificationStore()
  const [transactionPin, setTransactionPin] = useState("")
  const { isLoading: isUpdatingPinLoading, mutate: submitUpdate } = useMutation(
    {
      mutationFn: async (body: any) => {
        const res = await axiosInstance.post(
          `${newBaseUrl}/v1/employees/update-pin`,
          body
        )
        return res.data
      },
      onSuccess: (data) => {
        if (data.success) {
          invalidateQueries()
          mixPanelEvent(`empl-wallet-pin-creation-success`)

          refetch()
          {
            data.msg && toast.success(data.msg)
          }
          ;``
          closeModal()
        } else {
          {
            data.msg && toast.error(data.msg)
          }
        }
      },
    }
  )
  const handlePinUpdate = () => {
    mixPanelEvent(`empl-wallet-pin-creation-init`)
    submitUpdate({
      transactionPin: transactionPin,
    })
  }
  return (
    <NewModalWrapper
      title="Set Transaction Pin"
      subTitle="To be able to successfully withdraw from your daily earnings, you have to set up your transaction pin"
      open={open}
      onClose={() => {
        closeModal()
      }}
    >
      <div className="flex justify-center w-full py-4">
        {" "}
        <div className="w-full  sm:w-[570px]">
          <div className="flex flex-col gap-2 px-4 md:px-6 xl:px-[60px] justify-center items-center ">
            <img
              src={passwordHidden}
              alt="password"
              className="w-[59px] h-[59px]"
            />
            <p className="text-sm  text-bluesubtext mb-5">
              Please enter your 4-digit PIN
            </p>
            <div className="w-full flex flex-col justify-center">
              <Form
                layout="vertical"
                className="justify-center"
                onFinish={() => handlePinUpdate()}
              >
                <div className="flex flex-col w-full items-center">
                  <Form.Item
                    rootClassName="w-full sm:w-[70%]"
                    name="pin"
                    label="Transaction Pin"
                    rules={[
                      {
                        required: true,
                        message: "Please input your pin!",
                      },
                    ]}
                    hasFeedback
                  >
                    <Input.Password
                      maxLength={4}
                      style={{ width: "100%" }}
                      placeholder="Enter 4 digit pin"
                      onChange={(e) => {
                        setTransactionPin(e.target.value)
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    // style={{ width: "70%" }}
                    rootClassName="w-full sm:w-[70%]"
                    name="confirm"
                    label="Confirm Pin"
                    dependencies={["pin"]}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your pin!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("pin") === value) {
                            return Promise.resolve()
                          }
                          return Promise.reject(
                            new Error(
                              "The new pin that you entered do not match!"
                            )
                          )
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      maxLength={4}
                      style={{ width: "100%" }}
                      placeholder="Confirm pin"
                    />
                  </Form.Item>
                </div>
                <div className=" border px-0 sm:px-7 py-5   flex justify-end items-center w-full gap-5">
                  <Button
                    color="neutral"
                    title="Confirm"
                    onClick={() => {
                      closeModal()
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    title="confirm"
                    color="primary"
                    submitting={isUpdatingPinLoading}
                  >
                    Confirm
                  </Button>
                </div>
              </Form>
            </div>{" "}
          </div>
        </div>
      </div>
    </NewModalWrapper>
  )
}
export default UpdatePinModal
