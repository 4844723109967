import React, { useEffect, useState } from "react"

import { Form, Spin } from "antd"

import { useNavigate } from "react-router"
import FormSectionWrapper from "pages/dashboard/people/people/FormSectionWrapper"
import { CustomSelect } from "pages/dashboard/documents/custom-form"
import AntDesignSelect from "components/antd/AntDesignSelect"
import { AntDesignInput } from "components/antd/AntDesignInput"
import { IEwaBody, IEwaSettings } from "../EwaSettings"
import { useGet } from "utils/useFetch"
import { axiosInstance } from "utils/request"
import { useMutation } from "@tanstack/react-query"
import { Button } from "components"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"
import PrevNextButton from "components/new-stuff/form/PrevNextButton"

const CompanySettings = ({
  active,
  setActiveKey,
  setEwaBody,
  ewaBody,
}: {
  active: boolean
  setActiveKey: React.Dispatch<React.SetStateAction<string[]>>
  setEwaBody: Function
  ewaBody: IEwaBody
}) => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const [cutOffDays, setCuttOffDays] = useState<string[]>([])
  const onFinish = (values: any) => {
    mixPanelEvent("settings-hr-payroll-ewa-update-init")
    submit({
      ...ewaBody,
      tin: values?.tinnumber,
      registered_name: values?.companyname,
      email: values?.email,
      phone: values?.phonenumber,
      full_address: values?.address,
      registration_number: values?.rcnumber,
      can_employees_withdraw: "yes",
      withdrawal_stop_day: values?.cutOff,
      withdrawal_fee_payer_id: "1",
      withdrawal_fee_payer: "EMPLOYEE",
    })
  }
  const { isLoading: isGetEwaLoading, data: EwaDetails } = useGet<any>({
    url: `/admin/company/view/settings?id=ewa&x=`,
  })
  const { isLoading: isLoading, mutate: submit } = useMutation({
    mutationFn: async (body: IEwaBody) => {
      const res = await axiosInstance.post(`/admin/company/update_ewa`, body)
      return res.data
    },
    onSuccess: (data) => {
      if (data) {
        setActiveKey(["3"])
        mixPanelEvent("settings-hr-payroll-ewa-update-success")
      }
    },
  })
  const ewaSettings: IEwaSettings = EwaDetails?.ewaConfigSetting
  const getItemsBeforeSelectedOption = (
    array: string[],
    selectedOption: string
  ): string[] => {
    const selectedIndex = array.indexOf(selectedOption)
    if (selectedIndex !== -1) {
      return array.slice(0, selectedIndex)
    } else {
      return []
    }
  }
  useEffect(() => {
    if (ewaSettings?.payroll_run_day) {
      const offDaysOption = [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "Last Day",
      ]
      setCuttOffDays(
        getItemsBeforeSelectedOption(
          offDaysOption,
          String(ewaSettings?.payroll_run_day)
        )
      )
    }
  }, [ewaSettings?.payroll_run_day])
  return (
    <FormSectionWrapper
      name="Company Information"
      title="Company Information"
      subtitle="In order to facilitate earned wage access services, we will collect and securely store company information, ensuring smooth processing of wage advances for employees"
      isActive={active}
      initialValues={{
        companyname: ewaSettings?.registered_name,
        address: ewaSettings?.full_address,
        email: ewaSettings?.email,
        phonenumber: ewaSettings?.phone,
        rcnumber: ewaSettings?.registration_number,
        tinnumber: ewaSettings?.tin || "",
        cutOff: ewaSettings?.withdrawal_stop_day,
      }}
      layout="vertical"
      form={form}
      onFinish={onFinish}
      style={{ maxWidth: 1024 }}
      autoComplete="off"
      footerBtns={
        <PrevNextButton
          showPrevButton={false}
          submitting={isLoading}

          // onClickPrev={() => setActiveKey(["1"])}
        />
      }
    >
      <div className="grid md:grid-cols-2 gap-x-10 px-4 md:px-6 xl:px-[60px]">
        <AntDesignInput
          label="Company Name"
          name="companyname"
          required={true}
        />
        <AntDesignInput
          label="Address"
          name="address"
          required={true}
        />
        <AntDesignInput
          label="Email"
          name="email"
          required={true}
        />
        <AntDesignInput
          label="Company Phone Number"
          name="phonenumber"
          required={true}
        />
        <AntDesignInput
          label="RC Number"
          name="rcnumber"
          required={true}
        />
        <AntDesignInput
          label="TIN Number"
          name="tinnumber"
          required={true}
        />
        <AntDesignSelect
          label="Cut off day"
          name="cutOff"
          tooltip="Withdrawal cut-off day must be before payroll run day"
          required
          options={[...cutOffDays, "", "nil"]?.map((option) => ({
            label: option,
            value: option,
          }))}
          placeholder="Please select"
        />
        {/*
        <AntDesignCheckbox
          label="Are employees allowed to withdraw?"
          name="withdraw"
          onChange={(e) => {
            if (e.target.checked) {
              setEwaBody({
                ...ewaBody,
                can_employees_withdraw: "true",
              })
            } else {
              setEwaBody({
                ...ewaBody,
                can_employees_withdraw: "false",
              })
            }
          }}
        /> */}
        <div />
        {/* <AntDesignCheckbox
          label="Accept terms and conditions?"
          required={true}
        /> */}
      </div>
    </FormSectionWrapper>
  )
}

export default CompanySettings
