import { Button } from "components"
import React from "react"
import padewallet from "../../../../assets/illustrations/padewallet.svg"

import { useNavigate } from "react-router-dom"

const WalletNotEnabled = () => {
  const navigate = useNavigate()

  return (
    <div className="flex flex-col-reverse xl:flex-row  items-center justify-between py-6 xl:py-[59px] px-6 xl:px-[52px] bg-white max-w-[1047px]  ">
      <div className=" w-full xl:w-[511px]">
        <h2 className="text-[32px] text-padeBlack font-bold mb-[10px] font-circular">
          Welcome to your PaidHR Wallet
        </h2>
        <h6 className="text-base text-[#0c1b3dbf] mb-[33px] font-circular">
          Send, receive and make payments.
        </h6>
        <div className="py-[15px] px-[13px] bg-[#E8F0FE] rounded-5px">
          <p className="text-sm text-padeBlack font-circular">
            To get started, we need to verify your details to ensure compliance
            with Central Bank of Nigeria regulations and also enjoy benefits.
          </p>
        </div>
        <div className="w-full items-center grid grid-cols-2 mt-[69px] gap-8">
          <Button
            color="primary"
            onClick={() => navigate("/employee/dashboard/wallet/kyc")}
          >
            Proceed{" "}
          </Button>
          {/* <Button color="neutral">Skip to wallet</Button> */}
        </div>
      </div>
      <div className=" flex justify-end">
        <img
          src={padewallet}
          alt=""
        />
      </div>
    </div>
  )
}

export default WalletNotEnabled
