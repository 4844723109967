import React, { useState } from "react"
import { BankAccountInfoTab } from "../BankAccountInfoTab"
import { BusinessInfoTab } from "../BusinessInfoTab"
import { TermsAndConditionsTab } from "../TermsAndConditionsTab"
import { IKYCData, KYCInitialState, KYCWalletBody } from "../interface/kyc"
import { useGet, usePost } from "utils/useFetch"
import { LoadingIndicatorWhite } from "components/custom/LoaderWhite"
import { BvnTab } from "../BvnTab"

const BankAccountInfo = ({ redirectUrl }: { redirectUrl?: string }) => {
  const [activeKey, setActiveKey] = useState<string[]>(["1"])
  const [userInfo, setUserInfo] = useState<KYCWalletBody>(KYCInitialState)

  const { isLoading: isGetKYCLoading, data: kyc } = useGet<IKYCData>({
    url: `/admin/company/view/settings?id=verify&x=`,
  })
  const handleUpdateKYCInfo = (body: KYCWalletBody) => {}
  return (
    <div className="">
      {isGetKYCLoading ? (
        <div className="h-screen flex justify-center  items-center">
          <LoadingIndicatorWhite />
        </div>
      ) : (
        kyc?.verification && (
          <div className="max-w-[1024px] mt-6  rounded-md space-y-[20px]">
            <BankAccountInfoTab
              setActiveKey={setActiveKey}
              isActive={activeKey.includes("1")}
              setUserInfo={setUserInfo}
              userInfo={userInfo}
            />
            <BvnTab
              setActiveKey={setActiveKey}
              isActive={activeKey.includes("2")}
              setUserInfo={setUserInfo}
              userInfo={userInfo}
            />

            <BusinessInfoTab
              isActive={activeKey.includes("3")}
              setActiveKey={setActiveKey}
              setUserInfo={setUserInfo}
              userInfo={userInfo}
            />
            <TermsAndConditionsTab
              isActive={activeKey.includes("4")}
              setActiveKey={setActiveKey}
              userInfo={userInfo}
              handleUpdateKYCInfo={handleUpdateKYCInfo}
              redirectUrl={redirectUrl}
            />
          </div>
        )
      )}
    </div>
  )
}

export default BankAccountInfo
