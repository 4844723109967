import React, { useState } from "react"
import { DashboardContainer, LinkButton } from "components"
import EarnWageWithdrawalCard from "./components/Card"
import totalEmpAnalytics from "../../../assets/svg/totalEmpAnalytics.svg"
import enrolledEmpAnalytics from "../../../assets/svg/enrolledEmpAnalytics.svg"
import withdrawalEmpAnalytics from "../../../assets/svg/withdrawalEmpAnalytics.svg"

import { Alert, Dropdown, Select, Spin, Table } from "antd"
import { formatCurrencyNoSymbol } from "utils/currency"
import {
  EllipsisHorizontalIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline"

import { useGet } from "utils/useFetch"
import { newBaseUrl } from "utils/newbaseurl"
import { formatDateReadable, formatTimeReadble } from "utils/formatDate"
import { StatsCard } from "../documents/company-details/Cards"
import MobileWarner from "components/new-stuff/ui/MobileWarner"
import CustomCard from "components/new-stuff/ui/CustomCard"
import Barchart from "components/charts/Barchart"
import { useNavigate } from "react-router-dom"
import { IAnalytics } from "./interface/analytics"
import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"

const Analytics: React.FC<{}> = () => {
  const navigate = useNavigate()
  const [search, setSearch] = useState("")
  const [statusId, setStatusId] = useState("week")
  const { isLoading: isWithdrawalsLoading, data: withdrawalsData } =
    useGet<any>({
      url: `${newBaseUrl}/v1/employer/withdrawals?populate=employee`,
      external: true,
    })
  const { isLoading: isAnalyticsLoading, data: analyticsData } =
    useGet<IAnalytics>({
      url: `${newBaseUrl}/v1/employer/withdrawals/analytics?period=${statusId}`,
      external: true,
      onSuccess: (data: any) => {
        mixPanelEvent("ewa-analytics-listing")
      },
    })

  const TableData =
    withdrawalsData?.data?.docs
      .slice(0, 5)
      ?.filter((emp: any) => {
        const name = emp?.employee?.firstName
        return name?.toLowerCase().includes(search?.toLowerCase())
      })
      ?.map((transaction: any) => {
        return {
          key: transaction?._id,
          date: {
            date: formatDateReadable(String(transaction?.createdAt)),
            time: formatTimeReadble(String(transaction?.createdAt)),
          },
          type: transaction.channel,
          amount: `₦ ${formatCurrencyNoSymbol(
            Number(transaction?.amountToProcess)
          )}`,
          employee: {
            img: transaction.img,
            name: transaction?.employee?.bankAccountName,
          },
          charge: `₦ ${formatCurrencyNoSymbol(Number(transaction?.fee))}`,
          reference: transaction.reference,
          // balance: `₦ ${formatCurrencyNoSymbol(
          //   Number(transaction.currentBalance)
          // )}`,
          status: {
            id: transaction.status,
            title: transaction.status,
          },
          action: {
            key: transaction?._id,
            id: transaction?._id,
          },
        }
      }) || []
  const TableColumns =
    [
      {
        title: "Date",
        dataIndex: "date",
        render: (item: { date: string; time: string }) => (
          <div className="whitespace-nowrap flex flex-col ">
            <span className={` font-avenir text-sm text-[#42526D]`}>
              {item.date},
            </span>
            <span className={` font-avenir text-xs text-neutral300`}>
              {item.time}
            </span>
          </div>
        ),
      },
      {
        title: "Employee",
        dataIndex: "employee",
        render: (employee: { img: string; name: string }) => (
          <div className="flex items-center gap-2">
            <span
              className={`whitespace-nowrap  font-avenir text-sm text-[#42526D] lowercase`}
            >
              {employee.name}
            </span>
          </div>
        ),
      },

      {
        title: "Amount",
        dataIndex: "amount",
        render: (amount: string) => (
          <span
            className={`whitespace-nowrap  font-avenir text-sm text-[#42526D] `}
          >
            {amount}{" "}
          </span>
        ),
      },
      // {
      //   title: "Trn.Charge",
      //   dataIndex: "charge",
      //   render: (charge: string) => (
      //     <span
      //       className={`whitespace-nowrap  font-avenir text-sm text-[#42526D] `}
      //     >
      //       {charge}{" "}
      //     </span>
      //   ),
      // },

      // {
      //   title: "Reference",
      //   dataIndex: "reference",
      //   render: (reference: string) => (
      //     <span
      //       className={`font-avenir flex flex-wrap text-sm text-[#42526D] `}
      //     >
      //       {reference}
      //     </span>
      //   ),
      // },
      // {
      //   title: "Type",
      //   dataIndex: "type",
      //   render: (type: string) => (
      //     <span
      //       className={`font-avenir flex flex-wrap text-sm text-[#42526D]  lowercase `}
      //     >
      //       {type}
      //     </span>
      //   ),
      // },
      {
        title: "Status",
        dataIndex: "status",
        render: (status: { id: string; title: any; method: string }) => (
          <span
            className={`text-sm font-medium py-1 block text-center w-[80px] lowercase rounded-[4px]
                    ${
                      status?.title == "APPROVED"
                        ? "bg-success100 text-success800"
                        : ""
                    }
                    ${
                      status?.title == "PAID"
                        ? "bg-success100 text-success800"
                        : ""
                    }

                    ${
                      status?.title == "PROCESSING"
                        ? "bg-[#FEE3B1] text-[#E69702]"
                        : ""
                    }
                    ${
                      status?.title == "PENDING"
                        ? "bg-[#FEE3B1] text-[#E69702]"
                        : ""
                    }
                    ${status?.title == "DECLINED" ? "bg-neutral40 " : ""}
                    ${status?.title == "FAILED" ? "bg-neutral40 " : ""}
                    ${status?.title == "CUTOFF" ? "bg-neutral40 " : ""}

                    `}
          >
            {status?.title}
          </span>
        ),
      },
      {
        title: "",
        dataIndex: "action",
        render: (action: { key: string; id: string; status: string }) => (
          <Dropdown
            menu={{
              items: actionItems(action),
            }}
          >
            <button>
              <EllipsisHorizontalIcon className="w-6 h-6 text-[#004AF5]" />
            </button>
          </Dropdown>
        ),
      },
    ] || []
  const actionItems = (action: { key: string; id: string; status: string }) => {
    const { key, id, status } = action
    let arrayOptions: any = []
    {
      id &&
        (arrayOptions = [
          ...arrayOptions,
          // {
          //   key: "1",
          //   label: <span>Edit</span>,
          // },
        ])
    }

    return arrayOptions
  }
  const cardData = [
    {
      icon: totalEmpAnalytics,
      title: "Total Employees",
      value: analyticsData?.data?.totalEmployees || 0,
      id: "ewa-analytics-total-employees",
    },
    {
      icon: enrolledEmpAnalytics,
      title: "Registered employees",
      value: analyticsData?.data?.totalEnrolledEmployees || 0,
      id: "ewa-analytics-registered-employees",
    },
    {
      icon: withdrawalEmpAnalytics,
      title: "Withdrawal this month",
      value: `₦ ${formatCurrencyNoSymbol(
        Number(analyticsData?.data?.totalWithdrawalAmountForCurrentMonth || 0)
      )}`,
      id: "ewa-analytics-withdrawals",
    },
    {
      icon: withdrawalEmpAnalytics,
      title: "Total Amount withdrawn",
      value: `₦ ${formatCurrencyNoSymbol(
        Number(analyticsData?.data?.totalWithdrawalAmountForAllTime || 0)
      )}`,
      id: "ewa-analytics-total-withdrawals",
    },
  ]

  return (
    <DashboardWrapper>
      {isWithdrawalsLoading || isAnalyticsLoading ? (
        <div className="flex justify-center w-full h-screen items-center">
          <Spin />
        </div>
      ) : withdrawalsData || analyticsData ? (
        <div className="flex font-avenir flex-col  gap-6">
          <div>
            <h5 className="text-lg font-bold text-neutral700 mb-4  ">
              Analytics
            </h5>

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4  gap-8 ">
              {cardData.map((card, idx) => (
                <StatsCard
                  key={idx}
                  image={card.icon}
                  title={card.title}
                  text={card.value}
                  id={card.id}
                />
              ))}
            </div>
          </div>
          <CustomCard
            loading={false}
            id="ewa-analytics-withdrawals-chart"
          >
            <div className="flex justify-between items-center mb-4">
              <h6 className="text-neutral700 font-bold text-base">
                Withdrawal
              </h6>
              <Select
                defaultValue="week"
                placeholder="Filter by"
                onChange={(value) => {
                  setStatusId(value)
                }}
                options={[
                  { value: "year", label: "From 12 months" },
                  { value: "week", label: "From 7 days" },
                  { value: "month", label: "From 30 Days" },
                ]}
                rootClassName="font-avenir  text-neutral500 "
              />
            </div>
            <Barchart
              currency="#"
              radius="8"
              thick={20}
              labels={
                statusId == "year"
                  ? analyticsData?.data?.periodStats.map((stat) =>
                      String(stat?.month)
                    )
                  : analyticsData?.data?.periodStats.map((stat) =>
                      String(stat?.day)
                    ) || []
              }
              datasets={[
                {
                  // label: "# of Votes",
                  data: analyticsData?.data?.periodStats.map((stat) =>
                    String(stat?.totalAmount)
                  ),
                  backgroundColor: ["#93B8FA"],
                  borderColor: ["#93B8FA"],
                  borderWidth: 1,
                  borderRadius: 10,
                  barThickness: 20,
                },
              ]}
            />
          </CustomCard>
          <div
            id={"ewa-analytics-recent-transactions"}
            className="bg-white p-4  rounded-5px"
          >
            <div className="flex justify-between items-center mb-4">
              <h6 className="text-neutral700 font-bold text-base">
                Recent transaction
              </h6>
              <div className="flex  items-center gap-4">
                <div className="hidden md:flex border-2  h-[36px]  rounded-5px w-[268px]   items-center px-2 bg-white ">
                  <MagnifyingGlassIcon className=" text-neutral80 w-4" />
                  <input
                    placeholder="Search"
                    className="bg-none w-full px-2 outline-none  text-sm !h-full "
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value)
                    }}
                  />
                </div>
                <button
                  className="text-primary500 font-semibold"
                  onClick={() => navigate("/dashboard/ewa/withdrawals")}
                >
                  See more
                </button>
              </div>
            </div>
            <div className="border border-cardgrey my-5"></div>
            <Table
              columns={TableColumns}
              dataSource={TableData}
              loading={isWithdrawalsLoading}
              className="text-[#42526D]"
              pagination={{
                defaultPageSize: 20,
              }}
              scroll={{
                x: "100%",
              }}
            />
          </div>
        </div>
      ) : (
        <div className="w-full md:w-[80%]">
          <div className="p-4 md:p-5 md:pr-10 mb-6 bg-[#EBEDF0] ">
            <div className="flex  mb-4  items-center justify-between">
              <h5 className="text-neutral700 font-bold text-base">
                Earned wage access not turned on
              </h5>
              <LinkButton
                to="/dashboard/settings/hr-and-payroll/ewa"
                title="Next"
                color="primary"
                className="font-extrabold"
              >
                Update
              </LinkButton>
            </div>
            <p className="text-[#42526D] text-sm font-normal ">
              Earned wage access allows employees to access a portion of their
              earned wages before their regular payday. This service aims to
              provide greater financial flexibility and control for employees,
              especially for those who might face unexpected expenses or
              financial emergencies between pay periods.
            </p>
          </div>
          <Alert
            message="Turn on the earned wage access to enable your employees request for payment before regular pay-day. Go to settings to turn on"
            type="info"
            showIcon
            className=" bg-white py-1 sm:h-[64px] font-avenir text-neutral500 text-sm w-full flex justify-between rounded items-center "
          />
        </div>
      )}
    </DashboardWrapper>
  )
}
export default Analytics
