import { useLocation, useNavigate } from "react-router-dom"
import { useFormik } from "formik"
import { useState } from "react"
import {
  Button,
  CheckboxInput,
  H5,
  Input,
  Label,
  NotificationBar,
  P1,
  PhoneInput,
  SelectInput,
  SelectOption,
  TextButton,
  TextLink,
} from "components"
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline"
import { illustrations } from "assets"
import { useAppNotificationStore } from "stores"
import countries from "utils/detailed-countries.json"
import { LoadingIndicator } from "components/custom/Loader"
import { useMutation } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { useCustomizationSettingsStore } from "stores/customization-settings"
import { isPadeDomain } from "utils/utils"
import NewAuthWrapper from "pages/new-auth/NewAuthWrapper"
import { CheckCircleIcon } from "@heroicons/react/24/solid"

const OnboardingEmployee = () => {
  const [page, setPage] = useState(1)
  const [formSubmitted, toggleFormSubmitted] = useState(false)
  const navigate = useNavigate()

  const { customizationSettings } = useCustomizationSettingsStore()

  return (
    <>
      {isPadeDomain() === true ? (
        <NewAuthWrapper
          heading="Unleash Your Team's Potential with Our Innovative software"
          subHeading="Refer your company today and give them the tools they need to drive success in your organisation. Unlock a world of possibilities with our cutting-edge HR solutions."
          showGetHelp
          showCompanies={false}
          showBackBtn={!formSubmitted}
          progressData={{
            percent: formSubmitted ? 100 : page === 1 ? 75 : 100,
            text: page === 1 ? "3/4" : "4/4",
            completed: formSubmitted,
          }}
        >
          {formSubmitted ? (
            <div className=" flex flex-col  items-center justify-center min-h-full">
              <CheckCircleIcon className="text-[#38CB89] w-[87px] aspect-square" />
              <h2 className="font-avenir font-bold text-neutral700 text-2xl mt-4 mb-2 text-center">
                Your referral is a success
              </h2>
              <p className="font-avenir font-medium text-[16px] leading-[20.8px] text-neutral400 mb-4 text-center">
                Thank you for referring your employer to us! Your recommendation
                has been successfully processed and we are excited to work with
                them. We appreciate your confidence in our services and look
                forward to building a long-lasting relationship.
              </p>
              <TextLink
                to={"/get-started"}
                title="Back to home"
                className="text-primary500"
              >
                Back to home
              </TextLink>
            </div>
          ) : (
            <div className="">
              <h2 className="font-circular font-bold text-3xl mb-6">
                Refer your employer
              </h2>
              <p className="font-avenir font-semibold text-base text-neutral400 mb-6">
                Please fill your employer details in this form to refer your
                employer
              </p>
              <div className="">
                <RegForm
                  page={page}
                  setPage={setPage}
                  toggleFormSubmitted={toggleFormSubmitted}
                />
              </div>
            </div>
          )}
        </NewAuthWrapper>
      ) : (
        <div className="h-screen w-full">
          <NotificationBar />
          <div className="flex md:flex-row bg-primary50">
            <div className="hidden lg:block fixed top-0 bottom-0 left-0">
              <div
                className={`relative w-[542px] h-full md:pt-[120px] md:px-12 bg-primary500`}
              >
                {isPadeDomain() ? (
                  <img
                    className="w-[132px] mb-5"
                    src={illustrations.Referral}
                    alt="Referral"
                  />
                ) : null}

                <h1 className="text-a11y circularstdbold font-bold text-3xl mb-[38px] md:mr-14">
                  {customizationSettings?.pages?.employeeRegistration?.title}
                </h1>
                <p className="text-a11y/90 avenirstdroman font-normal text-[18px] leading-[25px]">
                  {
                    customizationSettings?.pages?.employeeRegistration
                      ?.description
                  }
                </p>
              </div>
            </div>
            <div className="lg:ml-[542px] lg:w-[calc(100%-542px)] min-h-screen h-full grow bg-[#EDF3FE] pt-[41px] px-4 md:pl-[83px] md:pr-[68.5px] pb-[30px]">
              {formSubmitted === false ? (
                <div>
                  <div className="flex justify-between gap-2">
                    <TextButton
                      title="Back"
                      className="flex gap-2 text-neutral500 font-medium items-center"
                      onClick={() => (page === 1 ? navigate(-1) : setPage(1))}
                    >
                      <ArrowLeftIcon className="w-4 text-primary500" />
                      <span>Back</span>
                    </TextButton>
                    {isPadeDomain() ? (
                      <TextButton
                        title="Get help"
                        className="flex gap-2 items-center"
                        onClick={() =>
                          (window.location.href = "https://support.padehcm.com")
                        }
                      >
                        <span>Having troubles?</span>
                        <span className="text-primary500 underline">
                          Get help
                        </span>
                        <ArrowRightIcon className="w-4 text-primary500" />
                      </TextButton>
                    ) : null}
                  </div>
                  <h2 className="mt-[50px] md:mt-[70px] circularstdbold font-bold text-[32px] leading-[40px] text-neutral700 mb-[16px]">
                    Refer Your Employer
                  </h2>
                  {page === 1 ? (
                    <h3 className="max-w-[533px] avenirstdbook text-[16px] leading-[21px] text-neutral100 mb-[48px]">
                      Please fill your employer details in this form to refer
                      your employer
                    </h3>
                  ) : (
                    <h3 className="max-w-[533px] avenirstdbook text-[16px] leading-[21px] text-neutral100 mb-[48px]">
                      Fill your personal details
                    </h3>
                  )}
                  <RegForm
                    page={page}
                    setPage={setPage}
                    toggleFormSubmitted={toggleFormSubmitted}
                  />
                </div>
              ) : (
                <div className="h-full max-w-[437px] flex flex-col justify-center items-center gap-y-[34px] md:pt-[120px]">
                  <CheckCircleIcon className="text-success500 w-52" />
                  <div className="w-full flex flex-col gap-4 items-center">
                    <H5 className="text-neutral600 font-bold">
                      Your referral is a success
                    </H5>
                    <P1 className="text-center text-neutral100">
                      Thank you for referring your employer to us! Your
                      recommendation has been successfully processed and we are
                      excited to work with them. We appreciate your confidence
                      in our services and look forward to building a
                      long-lasting relationship.
                    </P1>
                    <TextLink
                      to={"/get-started"}
                      title="Back to home"
                      className="text-primary500"
                    >
                      Back to home
                    </TextLink>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default OnboardingEmployee

const RegForm = ({
  page,
  setPage,
  toggleFormSubmitted,
}: {
  page: number
  toggleFormSubmitted: React.Dispatch<React.SetStateAction<boolean>>
  setPage: React.Dispatch<React.SetStateAction<number>>
}) => {
  const { toast } = useAppNotificationStore()

  const { state } = useLocation()
  const { mutate: saveReferral } = useMutation({
    mutationFn: async (data: saveReferralType) => {
      const res = await axiosInstance.post("/account/save_referral", data)
      return res.data
    },
  })

  const form1 = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      company_name: "",
      email: "",
      phone: "",
      referrer_first_name: "",
      referrer_last_name: "",
      referrer_email: state.email ? state.email : "",
      referrer_phone: "",
      country_id: "162",
      agreed: false,
    },
    initialErrors: {},
    onSubmit: (values, { setTouched, setSubmitting, resetForm }) => {
      setTouched({})
      setSubmitting(true)

      const { agreed, ...others } = values
      saveReferral(
        {
          ...others,
          country_id: countries
            .find((country) => country.description === others.country_id)
            ?.countryId.toString()!,
        },
        {
          onSuccess: (res) => {
            setSubmitting(false)
            if (res.status === "success") {
              resetForm()
              toggleFormSubmitted(true)
            } else {
              toast.error(res.title)
            }
          },
        }
      )
    },
  })

  return (
    <div className="max-w-full lg:max-w-[541px]">
      <div>
        {page === 1 ? (
          <form
            autoComplete="off"
            onSubmit={(e) => {
              e.preventDefault()
              setPage(2)
            }}
            className="flex flex-col gap-6"
          >
            <div className="flex flex-col gap-2">
              <Label
                required="true"
                htmlFor="country_id"
              >
                Country
              </Label>
              <SelectInput
                id="country_id"
                name="country_id"
                disabled={form1.isSubmitting}
                required={true}
                placeholder="Please select employer country"
                onChange={form1.handleChange}
                value={form1.values.country_id}
                className="border-primary50"
              >
                <SelectOption value={162}>Nigeria</SelectOption>
              </SelectInput>
            </div>
            <div className="flex flex-col gap-2 w-full">
              <Label
                required="true"
                htmlFor="company_name"
              >
                Company name
              </Label>
              <Input
                id="company_name"
                name="company_name"
                required={true}
                // pattern="[\w ]{1,}"
                disabled={form1.isSubmitting}
                placeholder="Enter employer company name"
                onChange={form1.handleChange}
                value={form1.values.company_name}
                className="border-primary50"
              />
            </div>
            <div className="flex flex-col md:flex-row md:justify-between gap-5">
              <div className="flex flex-col gap-2 w-full">
                <Label
                  required="true"
                  htmlFor="first_name"
                >
                  Employer first name
                </Label>
                <Input
                  id="first_name"
                  name="first_name"
                  required={true}
                  pattern="[\w]{1,}"
                  disabled={form1.isSubmitting}
                  placeholder="Enter employer first name"
                  onChange={form1.handleChange}
                  value={form1.values.first_name}
                  className="border-primary50"
                />
              </div>
              <div className="flex flex-col gap-2 w-full">
                <Label
                  required="true"
                  htmlFor="last_name"
                >
                  Employer last name
                </Label>
                <Input
                  id="last_name"
                  name="last_name"
                  required={true}
                  pattern="[\w]{1,}"
                  disabled={form1.isSubmitting}
                  placeholder="Enter employer last name"
                  onChange={form1.handleChange}
                  value={form1.values.last_name}
                  className="border-primary50"
                />
              </div>
            </div>
            <div className="flex flex-col gap-2 w-full">
              <Label
                required="true"
                htmlFor="phone"
              >
                Employer phone number
              </Label>
              <PhoneInput
                id="phone"
                name="phone"
                required={true}
                pattern="[\d]{10,}$"
                disabled={form1.isSubmitting}
                placeholder="Enter employer phone number"
                onChange={form1.handleChange}
                value={form1.values.phone}
                className="border-primary50"
              />
            </div>
            <div className="flex flex-col gap-2">
              <Label
                required="true"
                htmlFor="email"
              >
                Employer email address
              </Label>
              <Input
                id="email"
                name="email"
                required={true}
                type="email"
                disabled={form1.isSubmitting}
                placeholder="Enter employer email address"
                onChange={form1.handleChange}
                value={form1.values.email}
                className="border-primary50"
              />
            </div>
            <Button
              type="submit"
              color="primary"
              title="Create my account"
              className="font-bold"
            >
              {form1.isSubmitting && <LoadingIndicator />}
              Next
              <ArrowRightIcon className="w-4 stroke-2" />
            </Button>
          </form>
        ) : (
          <form
            autoComplete="off"
            onSubmit={form1.handleSubmit}
            className="flex flex-col gap-9"
          >
            <div className="flex flex-col md:flex-row md:justify-between gap-5">
              <div className="flex flex-col gap-2 w-full">
                <Label
                  required="true"
                  htmlFor="referrer_first_name"
                >
                  Your first name
                </Label>
                <Input
                  id="referrer_first_name"
                  name="referrer_first_name"
                  required={true}
                  pattern="[\w]{1,}"
                  disabled={form1.isSubmitting}
                  placeholder="Your first name"
                  onChange={form1.handleChange}
                  value={form1.values.referrer_first_name}
                  className="border-primary50"
                />
              </div>
              <div className="flex flex-col gap-2 w-full">
                <Label
                  required="true"
                  htmlFor="referrer_last_name"
                >
                  Your last name
                </Label>
                <Input
                  id="referrer_last_name"
                  name="referrer_last_name"
                  required={true}
                  pattern="[\w]{1,}"
                  disabled={form1.isSubmitting}
                  placeholder="Your last name"
                  onChange={form1.handleChange}
                  value={form1.values.referrer_last_name}
                  className="border-primary50"
                />
              </div>
            </div>
            <div className="flex flex-col gap-2 w-full">
              <Label
                required="true"
                htmlFor="referrer_phone"
              >
                Your phone number
              </Label>
              <PhoneInput
                id="referrer_phone"
                name="referrer_phone"
                required={true}
                pattern="[\d]{10,}$"
                disabled={form1.isSubmitting}
                placeholder="Enter your phone number"
                onChange={form1.handleChange}
                value={form1.values.referrer_phone}
                className="border-primary50"
              />
            </div>
            <div className="flex flex-col gap-2">
              <Label
                required="true"
                htmlFor="email"
              >
                Your email address
              </Label>
              <Input
                id="referrer_email"
                name="referrer_email"
                required={true}
                type="email"
                disabled={form1.isSubmitting}
                placeholder="Enter your email address"
                onChange={form1.handleChange}
                value={form1.values.referrer_email}
                className="border-primary50"
              />
            </div>

            <div className="w-full flex gap-2 items-start">
              <CheckboxInput
                id="agreed"
                name="agreed"
                required={true}
                className="h-4 w-4"
                onChange={form1.handleChange}
                checked={form1.values.agreed}
              />
              <Label htmlFor="agreed">
                By creating a referrer, you agree to our{" "}
                <a
                  className="text-primary500"
                  href={`${process.env.REACT_APP_WEBSITE_URL}/terms-and-conditions`}
                >
                  Terms
                </a>{" "}
                &{" "}
                <a
                  className="text-primary500"
                  href={`${process.env.REACT_APP_WEBSITE_URL}/privacy-policy`}
                >
                  Privacy
                </a>
                .
              </Label>
            </div>

            <div className="flex flex-col gap-4">
              <Button
                type="button"
                color="secondary"
                className="font-bold"
                onClick={() => setPage(1)}
              >
                Previous
              </Button>

              <Button
                type="submit"
                color="primary"
                className="font-bold"
                disabled={form1.isSubmitting}
                submitting={form1.isSubmitting}
              >
                Refer my employer
              </Button>
            </div>
          </form>
        )}
      </div>
    </div>
  )
}

type saveReferralType = {
  first_name: string
  last_name: string
  company_name: string
  email: string
  phone: string
  referrer_first_name: string
  referrer_last_name: string
  referrer_email: string
  referrer_phone: string
  country_id: string
}
