import { useState } from "react"
import {
  ArrowRightIcon,
  CalendarIcon,
  EyeIcon,
  EyeSlashIcon,
} from "@heroicons/react/24/outline"
import ProgressBar from "components/custom/ProgressBar"
import NoWallet from "../../../../assets/illustrations/no-wallet.svg"
import wallet_assess from "../../../../assets/illustrations/wallet_assess.svg"
import wallet_taken from "../../../../assets/illustrations/wallet_taken.svg"
import wallet_other_blue from "../../../../assets/illustrations/wallet_other_blue.svg"
import { useNavigate } from "react-router-dom"
import TransactionsTemplate from "../components/TransactionsTemplate"
import { IEarningsWalletData, IWithdrawalData } from "../interface/wallet"
import { useGet } from "utils/useFetch"
import { newBaseUrl } from "utils/newbaseurl"
import { formatCurrencyNoSymbol } from "utils/currency"
import { LoadingIndicatorWhite } from "components/custom/LoaderWhite"
import useCopyToClipboard from "hooks/useCopyToClipboard"
import UpdatePinModal from "../components/UpdatePinModal"
import { Table } from "antd"
import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"
import CustomCard from "components/new-stuff/ui/CustomCard"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"
import { useAppNotificationStore } from "stores"
export const divideEarningsByPercent = (number: number, percent: number) => {
  const decimalPercent = percent / 100
  const calculatedResult = number * decimalPercent
  return calculatedResult
}
const EarnedWageAccess = () => {
  const [_, copy] = useCopyToClipboard()
  const navigate = useNavigate()
  const { toast } = useAppNotificationStore()

  const [showPinModal, setShowPinModal] = useState(false)
  const [hideDetails, setHideDetails] = useState(true)

  const {
    isLoading: isEarningWalletLoading,
    data: earningWalletData,
    refetch,
  } = useGet<IEarningsWalletData>({
    url: `${newBaseUrl}/v1/earnings/wallet`,
    external: true,
  })
  const invalidateQueries = () => {
    refetch()
  }
  const { isLoading: isWithdrawalsLoading, data: withdrawalsData } =
    useGet<IWithdrawalData>({
      url: `${newBaseUrl}/v1/employee/withdrawals`,
      external: true,
    })
  const dataSource = [
    {
      key: "1",
      name: "Bank Name",
      value: earningWalletData?.data?.employee?.bankName || "",
    },
    //{
    //  key: "2",
    //  name: "Account Name",
    //  value: earningWalletData?.data?.employee?.bankAccountName || "",
    //  },
    {
      key: "3",
      name: "Account Number",
      value: earningWalletData?.data?.employee?.bankAccountNumber || "",
    },
  ]

  const columns = [
    {
      title: "",
      dataIndex: "name",
      key: "name",
      render: (name: string) => (
        <span className={` font-avenir text-sm text-[#42526D]`}>{name}</span>
      ),
    },
    {
      title: "",
      dataIndex: "value",
      key: "value",

      render: (value: string) => (
        <span className={` font-avenir text-sm text-[#42526D] lowercase`}>
          {value}
        </span>
      ),
    },
  ]
  const today = new Date()

  const currentMonth = today.getMonth()
  const currentYear = today.getFullYear()
  const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0).getDate()
  const daysLeft = lastDayOfMonth - today.getDate()
  const isLastDay = today.getDate() === lastDayOfMonth
  function calculateProgress(currentValue: number, totalValue: number) {
    const progress = (currentValue / totalValue) * 100
    return Number(progress.toFixed(2)) // Rounds the percentage to 2 decimal places
  }
  return (
    <DashboardWrapper>
      {isEarningWalletLoading ? (
        <div className=" z-50 h-screen flex justify-center items-center right-0 top-0 left-0 bottom-0 py-10">
          <LoadingIndicatorWhite />
        </div>
      ) : earningWalletData?.data ? (
        <div className="">
          <h5 className="text-[#111827]  font-black text-2xl">
            Welcome {earningWalletData?.data.employee.firstName}
          </h5>
          <p className="mt-1 mb-4 text-[#505F79] text-xs">
            {/* Today is 23rd May 2023 */}
          </p>
          <div className=" h-auto lg:h-[185px]  grid grid-cols-2 lg:grid-cols-3 gap-4">
            <div
              className="col-span-2  rounded-5px  px-4 lg:px-9 py-4 bg-primary500  h-full flex gap-2 flex-col justify-between"
              style={{
                // backgroundImage: `url(${wallet_bg})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className="flex justify-between items-center w-full">
                <p className="text-white text-base">
                  Your daily earning tracker
                </p>
                <button
                  className="outline-none sm:hidden flex"
                  onClick={() => setHideDetails(!hideDetails)}
                >
                  {hideDetails ? (
                    <EyeIcon className="text-white w-4 h-4 cursor-pointer" />
                  ) : (
                    <EyeSlashIcon className="text-white w-4 h-4 cursor-pointer" />
                  )}
                </button>
              </div>
              <div>
                <div className="flex flex-col sm:flex-row w-full justify-between  items-start sm:items-center">
                  {hideDetails ? (
                    <h3 className=" text-white font-bold text-[32px]">****</h3>
                  ) : (
                    <h3 className=" text-white font-bold  text-[22px] sm:text-[32px]">
                      ₦
                      {formatCurrencyNoSymbol(
                        Number(earningWalletData?.data?.totalEarnings)
                      )}{" "}
                      {""}/{""}₦
                      {formatCurrencyNoSymbol(
                        Number(earningWalletData?.data?.employee.netSalary)
                      )}{" "}
                    </h3>
                  )}
                  <button
                    className="outline-none hidden sm:flex"
                    onClick={() => setHideDetails(!hideDetails)}
                  >
                    {hideDetails ? (
                      <EyeIcon className="text-white w-6 h-6 cursor-pointer" />
                    ) : (
                      <EyeSlashIcon className="text-white w-6 h-6 cursor-pointer" />
                    )}
                  </button>
                </div>
                <ProgressBar
                  percent={
                    calculateProgress(
                      Number(earningWalletData?.data?.totalEarnings),
                      Number(earningWalletData?.data?.employee.netSalary)
                    ) || 0
                  }
                />
              </div>
              <div className="flex justify-between items-center">
                <p className=" text-sm text-white flex items-center font-normal ">
                  {" "}
                  <span className="mr-1">
                    {" "}
                    <CalendarIcon className="w-4 h-4 text-white" />
                  </span>
                  {isLastDay ? "Last day" : `${daysLeft} day(s) remaining`}
                </p>
                {/* <p className=" text-sm text-white">10 days remaining</p> */}
              </div>
            </div>
            <div className=" col-span-2 lg:col-span-1 lg:h-[185px]  ">
              <CustomCard rootClassName="h-full">
                <div className="">
                  <div>
                    {" "}
                    <h6 className="text-base  font-bold text-[#15294B]">
                      Account details
                    </h6>
                    <p className="text-sm mb-4  text-neutral700">
                      This is your salary account
                    </p>
                  </div>
                  <div className="">
                    <Table
                      dataSource={dataSource}
                      columns={columns}
                      showHeader={false}
                      className="custom-table"
                      pagination={false}
                      scroll={{ x: `100%` }}
                    />
                  </div>
                </div>
              </CustomCard>
            </div>
          </div>
          <div className=" h-auto lg:h-[148px]  mt-4 lg:mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            <div
              className={` rounded-5px bg-primary500  flex flex-col justify-between w-full h-full px-4 lg:px-7  py-4 sm:py-5 overflow-hidden`}
              style={{
                backgroundImage: `url(${wallet_assess})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "105%",
                backgroundPosition: "center",
              }}
            >
              <p className="text-white text-base ">What you have access to</p>

              {hideDetails ? (
                <h3 className=" text-white font-bold text-2xl sm:text-[32px]">
                  ****
                </h3>
              ) : (
                <h3 className=" text-white font-bold text-2xl sm:text-[32px]">
                  N
                  {formatCurrencyNoSymbol(
                    Number(
                      divideEarningsByPercent(
                        earningWalletData?.data?.totalEarnings || 0,
                        50
                      )
                    )
                  )}
                </h3>
              )}
              <p className=" text-sm text-white flex items-center font-normal ">
                50% of your daily earnings
              </p>
            </div>
            <div
              className="  rounded-5px bg-[#490FB2]  flex flex-col justify-between px-4 lg:px-7 py-4 sm:py-5"
              style={{
                backgroundImage: `url(${wallet_taken})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "105%",
                backgroundPosition: "center",
              }}
            >
              <p className="text-white text-base ">What you have taken</p>
              {hideDetails ? (
                <h3 className=" text-white font-bold text-2xl sm:text-[32px]">
                  ****
                </h3>
              ) : (
                <h3 className=" text-white font-bold text-2xl sm:text-[32px]">
                  N
                  {formatCurrencyNoSymbol(
                    Number(earningWalletData?.data?.totalWithdrawn)
                  )}
                </h3>
              )}

              <div className="flex justify-between items-center">
                <p className=" text-sm text-white flex items-center font-normal ">
                  This month
                </p>
              </div>
            </div>
            <div
              className=" rounded-5px bg-[#149AF4] flex flex-col justify-between px-4 lg:px-7  py-4 sm:py-5"
              style={{
                backgroundImage: `url(${wallet_other_blue})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "105%",
                backgroundPosition: "center",
              }}
            >
              <p className="text-white text-base ">Available for withdrawal</p>
              {hideDetails ? (
                <h3 className=" text-white font-bold text-2xl sm:text-[32px]">
                  ****
                </h3>
              ) : (
                <h3 className=" text-white font-bold text-2xl sm:text-[32px]">
                  N
                  {formatCurrencyNoSymbol(
                    Number(earningWalletData?.data?.availableBalance)
                  )}
                </h3>
              )}
              <div className="flex justify-between items-center">
                <p className=" text-sm text-white flex items-center font-normal ">
                  This month
                </p>

                <button
                  className="text-white h-[32px] w-[108px] rounded-[18px]  text-sm flex  items-center border border-white  justify-between px-[10px]"
                  onClick={() => {
                    if (earningWalletData?.data?.employee?.enrollmentStatus) {
                      navigate(
                        "/employee/dashboard/wallet/earned-wage-access/withdrawal"
                      )
                      mixPanelEvent("empl-withdrawal-process-started")
                    } else {
                      setShowPinModal(true)
                    }
                  }}
                >
                  Withdraw{" "}
                  <ArrowRightIcon className="w-[14px] h-[14px] text-white" />
                </button>
              </div>
            </div>
          </div>
          <div className=" mt-[40px]">
            <h6 className="mb-3 text-neutral500 text-lg">
              Recent Withdrawals{" "}
            </h6>
            <div className="rounded-5px bg-white">
              {withdrawalsData && withdrawalsData?.data?.docs.length > 0 && (
                <div className="flex justify-end items-center py-4 px-4">
                  <button
                    className="text-primary400 font-extrabold text-sm"
                    onClick={() =>
                      navigate(
                        "/employee/dashboard/wallet/earned-wage-access/transactions"
                      )
                    }
                  >
                    View all withdrawals
                  </button>
                </div>
              )}
              <TransactionsTemplate
                data={withdrawalsData?.data?.docs.slice(0, 5) || []}
                isLoading={isWithdrawalsLoading}
              />
            </div>
          </div>

          <UpdatePinModal
            open={showPinModal}
            closeModal={() => setShowPinModal(false)}
            invalidateQueries={invalidateQueries}
            refetch={refetch}
          />
        </div>
      ) : (
        <div className="w-full  h-screen justify-center items-center flex  ">
          <div className=" flex flex-col justify-center items-center w-full sm:w-[585px]  px-0 sm:px-8 font-avenir">
            <img
              src={NoWallet}
              alt="NoWallet"
              className="w-[338px] h-[253px]"
            />
            <h5 className="  mt-9 text-xl sm:text-2xl font-bold text-[#15294B] text-center">
              You're in, but your HR hasn't given you the green light for Earned
              Wage Access.!
            </h5>
            <p className="text-sm  text-bluesubtext mt-1 text-center mb-10">
              Don't miss out on instant access to your earnings.. Let's nudge
              your HR to get this amazing benefit rolling!
            </p>
            {/* <Button
              color="primary"
              title="activation"
              className="w-[294px] "
            >
              Request activation
            </Button> */}
          </div>
        </div>
      )}
    </DashboardWrapper>
  )
}
export default EarnedWageAccess
