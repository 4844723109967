import { InformationCircleIcon } from "@heroicons/react/24/outline"

import { Table, Tooltip } from "antd"
import { useAuthStore } from "stores"
import { formatDateReadable, formatTimeReadble } from "utils/formatDate"
import { formatCurrencyNoSymbol } from "utils/currency"
import { TransRes } from "../interface"
interface props {
  transactionsData: TransRes[] | undefined
  loading: boolean
}
const WalletTransactionsTable = ({ transactionsData, loading }: props) => {
  const { user } = useAuthStore()
  const EmployeeWalletID = user?.userWalletId

  const TableData =
    transactionsData?.map((transaction: TransRes) => {
      return {
        key: transaction?._id,
        date: {
          date: formatDateReadable(String(transaction?.createdAt)),
          time: formatTimeReadble(String(transaction?.createdAt)),
        },
        beneficiary: {
          name: transaction.beneficiaryAccountName,
          bank: transaction.beneficiaryBankName,
          account_number: transaction?.beneficiaryAccountNumber,
        },
        type: transaction.type,
        amount: {
          value: transaction.amount,
          code: transaction.type,
        },
        before: `₦ ${formatCurrencyNoSymbol(
          Number(transaction?.previousBalance)
        )}`,
        after: `₦ ${formatCurrencyNoSymbol(Number(transaction?.newBalance))}`,
        reference: transaction.reference,
        remark: transaction.description,
      }
    }) || []
  const TableColumns =
    [
      {
        title: "Date",
        dataIndex: "date",
        render: (item: { date: string; time: string }) => (
          <div className="whitespace-nowrap flex flex-col ">
            <span className={` font-avenir text-sm text-[#42526D]`}>
              {item.date},
            </span>
            <span className={` font-avenir text-xs text-neutral300`}>
              {item.time}
            </span>
          </div>
        ),
      },
      {
        title: "Amount",
        dataIndex: "amount",
        render: (amount: { value: string; code: string }) => (
          <div
            className={`font-avenir text-sm ${
              amount.code === "debit" ? "text-danger500" : "text-green"
            }`}
          >
            <p>₦{formatCurrencyNoSymbol(Number(amount.value))}</p>
          </div>
        ),
      },

      {
        title: "Beneficiary",
        dataIndex: "beneficiary",
        width: "10",
        render: (item: {
          name: string
          bank: string
          account_number: string
        }) =>
          item?.name ? (
            <div className=" flex flex-col ">
              <span
                title={item.name}
                className={`max-w-[130px]  font-avenir text-[13px] text-[#42526D] truncate`}
              >
                {item.name}
              </span>
              <span
                className={` font-avenir text-[11px] text-neutral300  flex-wrap`}
              >
                {item.account_number} {""}({item?.bank})
              </span>
            </div>
          ) : (
            <span className={` font-avenir text-xs text-neutral300`}>-</span>
          ),
      },
      {
        title: "Previous Balance",
        dataIndex: "before",
        render: (before: string) => (
          <div className={`font-avenir text-sm text-[#42526D]`}>
            <p>{before}</p>
          </div>
        ),
      },
      {
        title: "New Balance",
        dataIndex: "after",
        render: (after: string) => (
          <div className={`font-avenir text-sm text-[#42526D]`}>
            <p>{after}</p>
          </div>
        ),
      },
      {
        title: "Reference",
        dataIndex: "reference",
        render: (reference: string) => (
          <div
            className={`font-avenir text-sm text-[#42526D] w-[150px] overflow-hidden`}
          >
            <p>{reference}</p>
          </div>
        ),
      },
      {
        title: "Description",
        dataIndex: "remark",
        render: (remark: string) => (
          <div
            className={`font-avenir text-sm text-[#42526D] w-[170px] overflow-hidden`}
          >
            <p>{remark}</p>
          </div>
        ),
      },
    ] || []

  return (
    <>
      <Table
        dataSource={TableData}
        columns={TableColumns}
        loading={loading}
        className="text-[#42526D]"
        pagination={{
          defaultPageSize: 20,
        }}
        scroll={{
          x: "100%",
        }}
      />
    </>
  )
}
export default WalletTransactionsTable
