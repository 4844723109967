import UnauthourizedPage from "components/layouts/UnauthourizedPage"
import usePermissions from "hooks/usePermissions"
import { useAuthStore } from "stores"

const RequireRoutePermit = ({
  allowedPermissions,
  children,
}: {
  allowedPermissions: string[]
  children: React.ReactNode
}) => {
  const [canView] = usePermissions({ allowedPermissions })
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated)

  if (isAuthenticated && !canView) {
    return <UnauthourizedPage type="admin" />
  }

  return <> {children} </>
}

export default RequireRoutePermit
