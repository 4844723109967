import { useState, useEffect } from "react"
import { Form, Tooltip } from "antd"

import FormSectionWrapper from "pages/dashboard/people/people/FormSectionWrapper"
import PrevNextButton from "pages/dashboard/people/people/form/PrevNextButton"

import { CustomSelect } from "pages/dashboard/people/people/form"
import { useGet, usePost } from "utils/useFetch"
import { IBanksData, ICountry } from "pages/dashboard/people/people/typings"
import { ICurrencies, IKYCData, KYCWalletBody } from "./interface/kyc"
import { userInfo } from "os"
import { useMutation } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"
import { CustomInput } from "components/new-stuff/form"
import { InformationCircleIcon } from "@heroicons/react/24/outline"

export const BankAccountInfoTab = ({
  setActiveKey,
  isActive,
  setUserInfo,

  userInfo,
}: {
  isActive: boolean
  userInfo: KYCWalletBody
  setUserInfo: Function
  setActiveKey: React.Dispatch<React.SetStateAction<string[]>>
  // kyc: IKYCData | undefined
}) => {
  const [form] = Form.useForm()

  const [updateStatus, setUpdateStatus] = useState("")
  const [alpha2Code, setAlpha2Code] = useState<string>("")
  const { isLoading: isLoading, mutate: submit } = useMutation({
    mutationFn: async (body: KYCWalletBody) => {
      const res = await axiosInstance.post(
        `/admin/company/update_verification`,
        body
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data.status === "success") {
        mixPanelEvent("settings-integration-payment-config-edit-success")
      }
    },
  })

  const onFinish = (values: Values) => {
    const {
      phonenumber,
      accountNumber,
      bank,
      country,
      currency,
      full_name,
      bvn_number,
    } = values
    mixPanelEvent("settings-integration-payment-config-edit-init")
    setUserInfo({
      ...userInfo,
      phone: phonenumber,
      bank_code:
        bank == kyc?.verification?.bank_name
          ? kyc?.verification?.bank_code
          : userInfo?.bank_code,
      currency_id:
        currency == kyc?.verification?.currency_code
          ? String(kyc?.verification?.currency_id)
          : String(userInfo?.currency_id),
      bank_country_code:
        country == kyc?.verification?.bank_country
          ? kyc?.verification?.bank_country_code
          : userInfo?.bank_country_code,
      account_number: accountNumber,
    })

    submit({
      phone: phonenumber,
      bank_code:
        bank == kyc?.verification?.bank_name
          ? kyc?.verification?.bank_code
          : userInfo?.bank_code,
      currency_id:
        currency == kyc?.verification?.currency_code
          ? String(kyc?.verification?.currency_id)
          : String(userInfo?.currency_id),
      bank_country_code:
        country == kyc?.verification?.bank_country
          ? kyc?.verification?.bank_country_code
          : userInfo?.bank_country_code,
      account_number: String(accountNumber),
    })
    setActiveKey(["2"])
  }
  const reqBody = {
    q: "",
    page: "",
  }
  const { isLoading: isCountriesLoading, data: countries } = usePost<
    ICountry[]
  >({ url: "/commonutils/getcountries", body: reqBody })
  const { isLoading: isCurrencyLoading, data: currencies } = usePost<
    ICurrencies[]
  >({ url: "/commonutils/getcurrencies?forpayment=true", body: reqBody })
  const {
    data: banks,
    isLoading: isBanksDataLoading,
    refetch,
  } = usePost<IBanksData[]>({
    url: `/commonutils/getbanksbycountrycode?id=${alpha2Code}`,
    enabled: !!alpha2Code,
    body: reqBody,
  })

  const { isLoading: isGetKYCLoading, data: kyc } = useGet<IKYCData>({
    url: `/admin/company/view/settings?id=verify&x=`,
  })

  useEffect(() => {
    if (kyc?.verification) {
      setAlpha2Code(kyc?.verification?.country_code)
    }
  }, [kyc])

  return (
    <FormSectionWrapper
      name="Bank account info"
      title="Bank account info"
      subtitle="Enter bank account information where your withdrawals will be deposited"
      isActive={isActive}
      initialValues={{
        phonenumber: kyc?.verification?.phone,
        country: kyc?.verification?.bank_country,
        bank: kyc?.verification?.bank_name,
        currency: kyc?.verification?.currency_code,
        accountNumber: kyc?.verification?.account_number,
      }}
      layout="vertical"
      form={form}
      onFinish={onFinish}
      style={{ maxWidth: 1024 }}
      autoComplete="off"
      footerBtns={<PrevNextButton showPrevButton={false} />}
    >
      <div className="flex flex-col md:grid grid-cols-2 gap-x-10 px-4 md:px-6 xl:px-[50px]">
        <CustomInput
          label="Phone number"
          name="phonenumber"
          required
          type="number"
        />
        <CustomSelect
          label="Country"
          name="country"
          required
          onChange={(selected) => {
            const country = countries?.find(
              (country) => country.description === selected
            )
            setUserInfo({
              ...userInfo,
              bank_country_code: country?.alpha2Code,
            })
            setAlpha2Code(country?.alpha2Code || "")
            setTimeout(() => {
              refetch()
            }, 100)
          }}
          isLoading={isCountriesLoading}
          options={
            isCountriesLoading
              ? []
              : countries?.map((state) => ({
                  label: state.description,
                  value: state.description,
                }))
          }
          placeholder="Please select"
        />
        {alpha2Code !== "" && (
          <CustomSelect
            label="Bank"
            name="bank"
            required
            isLoading={isBanksDataLoading}
            onChange={(selected) => {
              const bank = banks?.find((bank) => bank.description === selected)
              setUserInfo({
                ...userInfo,
                bank_code: bank?.code,
              })
            }}
            options={
              isBanksDataLoading
                ? []
                : banks?.map((bank) => ({
                    label: bank.description,
                    value: bank.description,
                  }))
            }
            placeholder=""
          />
        )}
        <CustomInput
          label="Account number"
          name="accountNumber"
          required
        />

        <CustomSelect
          label="Currency"
          name="currency"
          required
          onChange={(selected) => {
            const currency = currencies?.find(
              (currency) => currency.code === selected
            )

            setUserInfo({
              ...userInfo,
              currency_id: currency?.currencyId,
            })
            setTimeout(() => {
              // form.setFieldsValue({
              //   state: "",
              // })
              refetch()
            }, 100)
          }}
          isLoading={isCurrencyLoading}
          options={
            isCurrencyLoading
              ? []
              : currencies?.map((state) => ({
                  label: state.code,
                  value: state.code,
                }))
          }
          placeholder="Please select"
        />
      </div>
    </FormSectionWrapper>
  )
}
interface Values {
  phonenumber: string
  country: string
  bank: string
  currency: string
  accountNumber: boolean
  full_name: string
  bvn_number: string
}
