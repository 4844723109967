import { logos } from "assets"
import { Button, DashboardContainer } from "components"
import { useLocation } from "react-router-dom"
import { useGet } from "utils/useFetch"

import { LoadingIndicatorWhite } from "components/custom/LoaderWhite"
import PensionReportToPrint from "../pension/PensionReportToPrint"
import React, { useRef } from "react"
import ReactToPrint from "react-to-print"
import NHFReportToPrint from "./NHFReportToPrint"

const NHFReport = () => {
  const typeId = "4"
  const myParam = useLocation().search
  const x = new URLSearchParams(myParam).get("x")
  const id = new URLSearchParams(myParam).get("id")
  const componentRef: any = useRef()
  const { isLoading: isPayslipLoading, data } = useGet<any>({
    url: `admin/reports/view/schedule?id=${x}&payment_schedule_item_type_id=${typeId}&x=false`,
  })

  return (
    <DashboardContainer>
      <>
        {isPayslipLoading ? (
          <div className="h-[200px] items-center flex justify-center">
            <LoadingIndicatorWhite />
          </div>
        ) : (
          <div>
            <div className="flex justify-end items-center mb-1">
              {" "}
              {componentRef && (
                <ReactToPrint
                  trigger={() => (
                    <Button
                      color="primary"
                      title="Download"
                    >
                      Download
                    </Button>
                  )}
                  content={() => componentRef.current}
                  documentTitle={`${data?.report_download_title}`}
                />
              )}
            </div>
            <NHFReportToPrint
              ref={componentRef}
              data={data}
            />
          </div>
        )}
      </>
    </DashboardContainer>
  )
}
export default NHFReport
