import { Modal } from "antd"
import { Button, H5, P2 } from "components"
import { useEffect, useState } from "react"
import { useIdleTimer } from "react-idle-timer"
import { useNavigate } from "react-router-dom"
import { useAuthStore } from "stores"

// Set timeout values
const timeout = 1000 * 60 * 30
// time modal will be shown before logout
const promptBeforeIdle = 1000 * 60

const IdleTimer = () => {
  // Time before idle
  const [remaining, setRemaining] = useState(timeout)
  const [openIdleModal, setOpenIdleModal] = useState(false)

  const navigate = useNavigate()

  const onPrompt = () => {
    setOpenIdleModal(true)
  }

  // this function is called when the user idle time limit is reached
  const onIdle = () => {
    setOpenIdleModal(false)
    handleLogOut()
  }

  // this function is called when the user resumes activity
  const onActive = () => {
    setOpenIdleModal(false)
    reset()
  }

  const { getRemainingTime, isPrompted, activate, reset } = useIdleTimer({
    timeout,
    promptBeforeIdle,
    onPrompt,
    onIdle,
    onActive,
    crossTab: true,
  })

  const handleStillHere = () => {
    // setOpenIdleModal(false)
    activate()
    reset()
  }

  const handleLogOut = () => {
    useAuthStore.getState().clearAuthDataStorage()
    const presentLocation = window.location.href
    navigate(`/auth/login?redirect=${encodeURIComponent(presentLocation)}`)
    reset()
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (isPrompted()) {
        setRemaining(Math.ceil(getRemainingTime() / 1000))
      }
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [getRemainingTime, isPrompted])

  return (
    <Modal
      closable={false}
      closeIcon={null}
      footer={null}
      open={openIdleModal}
      width={400}
      destroyOnClose={true}
    >
      <div className="">
        <H5 className="font-circular mb-3 !leading-none">For your security!</H5>
        <hr />
        <P2 className="font-avenir mt-4">
          You will be signed out of your account due to inactivity.
          <br />
        </P2>
        <P2 className="font-avenir mt-2">
          Redirecting in {remaining} seconds.
        </P2>
      </div>
      <div className="mt-6 flex justify-end gap-3 ">
        <Button
          title="Cancel"
          color="secondary"
          className="font-avenir"
          onClick={handleLogOut}
          type="button"
        >
          Log out
        </Button>
        <Button
          color="primary"
          onClick={handleStillHere}
        >
          Stay connected
        </Button>
      </div>
    </Modal>
  )
}

export default IdleTimer
