import { Form, FormInstance, Input, PaginationProps, Select, Table } from "antd"
import { ColumnsType } from "antd/es/table"
import TextArea from "antd/es/input/TextArea"
import { Button } from "components"
import { AppPeoplePicker } from "components/inputs/select/AppPeoplePicker"
import { useVerifyName } from "hooks/useVerifyName"
import {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
  useTransition,
} from "react"
import { IAddUserRequest, IRole } from "./interface/interface"
import { useMutation } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { useAppNotificationStore, useAuthStore } from "stores"
import usePermissions from "hooks/usePermissions"
import { usePost } from "utils/useFetch"

type Props = {
  form: FormInstance<any>
  employees: string[]
  setEmployees: Dispatch<SetStateAction<string[]>>
  onUpdateData?: Dispatch<SetStateAction<Partial<IRole>>>
  initialData?: IRole
  showPeople?: boolean
  isEditMode?: boolean
  onFinish?: (values: any) => void
  showButton?: boolean
  admins: any[]
  roleKey?: string
  onSuccess?: (status: boolean) => void
  showRolePeopleList?: boolean
  createMode?: boolean
  isCustomRole?: boolean
}

export const RoleForm = ({
  form,
  employees,
  setEmployees,
  showPeople = true,
  isEditMode = true,
  onUpdateData,
  initialData,
  onFinish,

  showButton = true,
  onSuccess,
  showRolePeopleList = true,
  createMode = false,
  isCustomRole,
  admins,
}: Props) => {
  const nameofRole = Form.useWatch("description", form)
  const description = Form.useWatch("alias", form)
  const { verify, status } = useVerifyName(
    `/admin/roles/check?description=${nameofRole}&custom_role_id=-1`
  )

  const [isPending, startTransition] = useTransition()
  const [current, setCurrent] = useState(1)
  const [pagination, setPagination] = useState({ start: 1, length: 6 })
  const [selectedPeople, setSelectedPeople] = useState<string[]>([])
  const [primaryAdmin, setPrimaryAdmin] = useState<string>("")

  const { user } = useAuthStore()

  const { toast } = useAppNotificationStore()
  const isPrimaryAdminRole = useMemo(
    () => initialData?.role_id === 1,
    [initialData]
  )

  const isPrimaryAdminUser = user.isPrimaryAdmin === "True"

  const { mutate: updatePrimaryAdmin, isLoading: updating } = useMutation({
    mutationFn: async (data: string) => {
      const res = await axiosInstance.post(`/admin/company/change_admin`, {
        new_admin_id: data,
      })
      return res.data
    },
    onSuccess: (data) => {
      if (data.status == "success") {
        data?.msg && setTimeout(() => toast.success(data?.msg), 100)
        onSuccess?.(true)
        setPrimaryAdmin("")
      } else {
        data?.msg && setTimeout(() => toast.error(data?.msg), 100)
      }
    },
  })

  const { mutate, isLoading } = useMutation({
    mutationFn: async (data: IAddUserRequest) => {
      const res = await axiosInstance.post(`/admin/roles/add_users`, data)
      return res.data
    },
    onSuccess: (data) => {
      if (data.status == "success") {
        data?.msg && setTimeout(() => toast.success(data?.msg), 100)
        onSuccess?.(true)
        setSelectedPeople([])
        form.setFieldValue("employees", [])
      } else {
        data?.msg && setTimeout(() => toast.error(data?.msg), 100)
      }
    },
  })

  //@: Delete member
  const { mutate: deleteMember, isLoading: deleting } = useMutation({
    mutationFn: async (data: IAddUserRequest) => {
      const res = await axiosInstance.post(`/admin/roles/remove_users`, data)
      return res.data
    },
    onSuccess: (data) => {
      if (data.status == "success") {
        data?.msg && setTimeout(() => toast.success(data?.msg), 100)

        onSuccess?.(true)
      } else {
        data?.msg && setTimeout(() => toast.error(data?.msg), 100)
      }
    },
  })

  useEffect(() => {
    onUpdateData &&
      onUpdateData({
        description: nameofRole,
        alias: description,
      })
  }, [nameofRole, description])

  useEffect(() => {
    form.setFieldValue("description", initialData?.description)
    form.setFieldValue("alias", initialData?.alias)

    // form.setFieldValue(
    //   "employees",
    //   initialData?.roleMembers?.map((x) => x?.nick_name)
    // )
  }, [initialData])

  const [canAddMember, canRemove] = usePermissions({
    allowedPermissions: [
      "can_change_team_member_roles",
      "can_remove_team_members",
    ],
  })

  const tableColumn: ColumnsType<any> = [
    {
      title: "Name",
      dataIndex: "nick_name",
      key: "nick_name",
      width: "70%",
    },
    {
      title: "",
      dataIndex: "",
      key: "action",
      align: "right",
      render(value, record, index) {
        return isPrimaryAdminRole ? (
          <></>
        ) : (
          <>
            <div
              className={`text-primary500 cursor-pointer select-none`}
              onClick={() => {
                if (canRemove) {
                  deleteMember({
                    role_id: initialData?.role_id?.toString() || "",
                    assignments: [record?.user_id.toString()],
                  })
                } else {
                  toast.error(
                    "You don't have the required permission to do this operation."
                  )
                }
              }}
            >
              Remove
            </div>
          </>
        )
      },
    },
  ]

  const handlePaginationChange: PaginationProps["onChange"] = (
    page,
    pageSize
  ) => {
    startTransition(() => {
      setCurrent(page)
      setPagination((prev) => ({
        ...prev,
        start: (page - 1) * pagination.length + 1,
      }))
    })
  }

  return (
    <Form
      form={form}
      className="lg:border-r !pr-6 pt-3"
      layout="vertical"
      onFinish={onFinish}
    >
      <Form.Item
        label={
          <span className="font-avenir text-sm text-neutral400">Name</span>
        }
        name={"description"}
        initialValue={initialData?.description}
        className="pr-2"
        rules={[
          {
            required: isEditMode,
            message: "Please add a role name",
          },
          {
            validator(rule, value, callback) {
              return new Promise(async (res, rej) => {
                if (value && isEditMode) {
                  const status = await verify(value)

                  if (status?.status) {
                    rej(status?.message)
                  } else res("success")
                }
              })
            },
          },
        ]}
      >
        <Input disabled={!isEditMode} />
      </Form.Item>
      <Form.Item
        label={
          <span className="font-avenir text-sm text-neutral400">
            Description
          </span>
        }
        name={"alias"}
        initialValue={initialData?.alias}
        className="pr-2"
      >
        <TextArea
          rows={3}
          className="!resize-none"
          maxLength={100}
          disabled={!isEditMode}
        />
      </Form.Item>
      {showPeople && (
        <div className="relative">
          {createMode && (
            <span className="font-avenir text-sm text-neutral400 absolute top-[-8px]">
              Members
            </span>
          )}
          <div className={`flex gap-2 h-[30px] w-full pr-2`}>
            {isPrimaryAdminRole ? (
              <Select
                placeholder="Select primary admin"
                options={admins?.map((it: any) => ({
                  label: it?.text,
                  value: it?.id,
                }))}
                value={primaryAdmin}
                onChange={(value) => setPrimaryAdmin(value)}
                className={`w-full mt-4`}
                allowClear
                showSearch
                filterOption={(input, option) => {
                  if (option) {
                    const value = (option?.label as string) || ""
                    return (
                      value?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                    )
                  } else {
                    return false
                  }
                }}
              />
            ) : (
              <AppPeoplePicker
                form={form}
                employees={selectedPeople}
                fieldName="employees"
                onUpdate={(values) => {
                  setEmployees((prev) =>
                    Array.from(new Set([...values, ...prev]))
                  )
                  setSelectedPeople(values)
                }}
                useCustomData
                filteredEmployees={admins?.map((value: any) => ({
                  full_name: value?.text,
                }))}
                isDisabled
                label=""
                maxTagCount={1}
              />
            )}

            <Button
              color="primary"
              className={`h-full mt-4 whitespace-nowrap ${
                showButton ? "" : "hidden"
              }`}
              type="submit"
              submitting={isLoading || updating}
              onClick={(e) => {
                e.preventDefault()
                if (canAddMember) {
                  if (isPrimaryAdminRole) {
                    if (!primaryAdmin) return
                    if (!isPrimaryAdminUser) {
                      toast.error(
                        "You don't have sufficient permission to change the primary admin"
                      )
                    } else {
                      updatePrimaryAdmin(
                        admins
                          ?.find((it: any) => it?.text === primaryAdmin)
                          ?.id?.toString()
                      )
                    }
                  } else {
                    if (!selectedPeople.length) return
                    mutate({
                      role_id: initialData?.role_id?.toString() || "",
                      assignments: selectedPeople?.map((x: string) =>
                        admins
                          ?.find((it: any) => it?.text === x)
                          ?.id?.toString()
                      ),
                    })
                  }
                } else {
                  toast.error(
                    "You dont't have the required permissions to do this."
                  )
                }
              }}
            >
              {isPrimaryAdminRole ? "Change admin" : "Add members"}
            </Button>
          </div>
          {showRolePeopleList && (
            <div className="mt-8 w-full lg:h-[350px] h-[150px] overflow-y-scroll">
              <Table
                dataSource={initialData?.roleMembers}
                columns={tableColumn}
                className="!w-full"
                scroll={{
                  x: "100%",
                  y: "100%",
                }}
                loading={deleting}
                bordered={false}
                pagination={
                  (initialData?.roleMembers?.length as number) > 6
                    ? {
                        defaultPageSize: 10,
                        current,
                        onChange(page, pageSize) {
                          handlePaginationChange(page, pageSize)
                        },
                      }
                    : false
                }
              />
            </div>
          )}
        </div>
      )}
    </Form>
  )
}
