import { ExclamationCircleIcon } from "@heroicons/react/24/outline"
import { Button, Label, P1, P2 } from "components"
import { IAddress, ICompany, IOfficeAddress } from "./typings"
import { useState } from "react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { useAppNotificationStore, useAuthStore } from "stores"
import { Form, Input, Select, Spin } from "antd"
import { usePost } from "utils/useFetch"
import FormSectionWrapper from "pages/dashboard/people/people/FormSectionWrapper"
import ImageUpload from "./ImageUpload"
import { useNavigate } from "react-router-dom"
import CustomModal from "components/new-stuff/ui/modals/CustomModal"
import { useCustomizationSettingsStore } from "stores/customization-settings"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"
import { CustomInput, CustomSelect } from "components/new-stuff/form"

export function EditCompanyNameModal({
  toggleEditCompanyNameModal,
  setActiveKey,
  active,
}: {
  active: boolean
  setActiveKey: React.Dispatch<React.SetStateAction<string[]>>
  toggleEditCompanyNameModal: Function
}) {
  const queryClient = useQueryClient()
  const [form] = Form.useForm()
  const { user } = useAuthStore()
  const { toast } = useAppNotificationStore()
  const navigate = useNavigate()

  const { isLoading: isSubmitting, mutate: submit } = useMutation({
    mutationFn: async (body: any) => {
      const res = await axiosInstance.post(`/admin/company/edit_info`, body)
      return res.data
    },
    onSuccess: (data) => {
      if (data.status === "success") {
        queryClient.invalidateQueries({
          queryKey: ["admin/company/view/details"],
        })
        toggleEditCompanyNameModal(false)
        mixPanelEvent("company-edit-company-info-success")
      } else {
        toast.error(data.msg)
      }
    },
  })

  const onFinish = (values: any) => {
    const { companyName, email, primaryContact, otherContact } = values
    submit({
      full_name: companyName,
      email: email,
      mobile_phone: primaryContact,
      telephone: otherContact,
    })
    mixPanelEvent("company-edit-company-info-init")
  }

  return (
    <FormSectionWrapper
      title="Edit Company Details"
      subtitle="Company name appears in reports and accounting integrations."
      name="editCompanyName"
      layout="vertical"
      isActive={active}
      form={form}
      initialValues={{
        companyName: user?.userOrganisation,
        email: user?.userOrganisationEmail,
        primaryContact: user?.userOrganisationMobilePhone,
        otherContact: user?.userOrganisationTelephone,
      }}
      onFinish={onFinish}
      autoComplete="off"
      footerBtns={
        <div className="flex justify-end gap-3 w-full">
          <Button
            color="secondary"
            onClick={(e) => {
              e.preventDefault()
              navigate("/dashboard/company-details")
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={(e) => {
              e.preventDefault()
              form.submit()
            }}
            submitting={isSubmitting}
          >
            Save
          </Button>
        </div>
      }
    >
      <div className="flex flex-col lg:flex-row lg:gap-10 px-4 md:px-6 xl:px-[60px]">
        <div className="w-full lg:w-1/2">
          <Form.Item
            label="Company image"
            name="profilePicture"
            className="[&_.ant-upload]:!w-[138px] [&_.ant-upload]:!h-[138px]"
          >
            <ImageUpload />
          </Form.Item>

          <CustomInput
            label="Primary contact"
            name="primaryContact"
            required
          />
        </div>

        <div className="w-full lg:w-1/2">
          <CustomInput
            label="Company name"
            name="companyName"
            required
          />
          <CustomInput
            label="Email address"
            name="email"
            required
          />
          <CustomInput
            label="Other contact"
            name="otherContact"
          />
        </div>
      </div>
    </FormSectionWrapper>
  )
}

export function PensionRefNumberModal({
  toggleEditPensionRefNumberModal,
  company,
}: {
  isLoading: boolean
  company: ICompany | undefined
  toggleEditPensionRefNumberModal: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const [pensionRefNo, setPensionRefNo] = useState(
    company?.pension_reference_number
  )
  const queryClient = useQueryClient()
  const { user } = useAuthStore()
  const { toast } = useAppNotificationStore()

  const { isLoading: isSubmitting, mutate: submit } = useMutation({
    mutationFn: async (body: any) => {
      const res = await axiosInstance.post(
        `/admin/company/edit_pension_details`,
        body
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data?.status === "success") {
        queryClient.invalidateQueries({
          queryKey: ["admin/company/view/details"],
        })
        toggleEditPensionRefNumberModal(false)
        mixPanelEvent("company-edit-pension-details-success")
      } else {
        toast.error(data.msg)
      }
    },
  })

  const handleSave = () => {
    mixPanelEvent("company-edit-pension-details-init")
    submit({
      pension_reference_number: pensionRefNo,
    })
  }

  return (
    <CustomModal
      onCancel={() => {
        toggleEditPensionRefNumberModal(false)
      }}
      title="Company Pension Reference Number"
      open
      footer={
        <div className="flex justify-end gap-4">
          <Button
            color="secondary"
            onClick={(e) => {
              e.preventDefault()
              toggleEditPensionRefNumberModal(false)
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={handleSave}
            disabled={isSubmitting}
            submitting={isSubmitting}
          >
            Save
          </Button>
        </div>
      }
    >
      <div className=" flex flex-col gap-4">
        <div className="bg-primary50 flex justify-start items-center gap-3 py-4 px-4">
          <ExclamationCircleIcon className="w-5 text-primary500" />
          <P2 className="text-neutral300">
            We wont remit your pension unless you ask us to.
          </P2>
        </div>

        <div className="w-full space-y-1">
          <Label
            required="true"
            htmlFor="reference-number"
            className=""
          >
            Reference number
          </Label>
          <Input
            id="reference-number"
            required={true}
            className="w-full h-10"
            value={pensionRefNo}
            onChange={(e) => setPensionRefNo(e.target.value)}
            maxLength={20}
          />
        </div>
      </div>
    </CustomModal>
  )
}

export function TaxInfoModal({
  toggleEditTaxInfoModal,
  company,
}: {
  isLoading: boolean
  company: ICompany | undefined
  toggleEditTaxInfoModal: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const queryClient = useQueryClient()
  const [form] = Form.useForm()
  const { user } = useAuthStore()
  const { toast } = useAppNotificationStore()

  const { isLoading: isCompanyTypesLoading, data: companyTypes } = usePost<
    { companyTypeId: number; description: string }[]
  >({
    url: "/admin/company/gettypes",
    body: {
      q: "",
      page: "",
    },
  })

  const { isLoading: isSubmitting, mutate: submit } = useMutation({
    mutationFn: async (body: any) => {
      const res = await axiosInstance.post(
        `/admin/company/edit_tax_details`,
        body
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data?.status === "success") {
        queryClient.invalidateQueries({
          queryKey: ["admin/company/view/details"],
        })
        toggleEditTaxInfoModal(false)
        mixPanelEvent("company-edit-tax-details-success")
      } else {
        data?.msg && toast.error(data?.msg)
        return
      }
    },
  })

  const onFinish = (values: any) => {
    const { companyType, tin } = values
    submit({
      company_type_id: companyType ? companyType : "",
      tax_identification_number: tin ? tin : "",
    })
    mixPanelEvent("company-edit-tax-details-init")
  }

  return (
    <>
      <CustomModal
        onCancel={() => {
          toggleEditTaxInfoModal(false)
        }}
        title="Edit Tax Info"
        open
        footer={
          <div className="flex justify-end gap-4">
            <Button
              color="secondary"
              onClick={(e) => {
                e.preventDefault()
                toggleEditTaxInfoModal(false)
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={isSubmitting}
              submitting={isSubmitting}
              onClick={(e) => {
                e.preventDefault()
                form.submit()
              }}
            >
              Save
            </Button>
          </div>
        }
      >
        <div className=" flex flex-col gap-4">
          <div className="bg-primary50 flex justify-start items-center gap-3 py-4 px-4">
            <ExclamationCircleIcon className="w-5 text-primary500" />
            <P2 className="text-neutral300">
              We won't file your tax unless you ask us to.
            </P2>
          </div>

          <Form
            name="addWorkspace"
            layout="vertical"
            form={form}
            onFinish={onFinish}
            autoComplete="off"
            initialValues={{
              companyType:
                company?.companyType?.company_type_id !== -1 &&
                company?.companyType?.company_type_id
                  ? company?.companyType?.company_type_id?.toString()
                  : "",
              tin: company?.tax_identification_number,
            }}
          >
            <CustomSelect
              showSearch
              label="Company Type"
              name="companyType"
              required
              isLoading={isCompanyTypesLoading}
              options={
                companyTypes?.map((companyType) => ({
                  value: companyType.companyTypeId?.toString(),
                  label: companyType.description,
                })) || []
              }
              placeholder="Select Company Type"
            />

            <CustomInput
              label="Tax Identification number"
              name="tin"
            />
            <div className="w-full">
              <P2 className="text-sm font-avenir text-neutral300">
                Filling schedule
              </P2>
              <P1 className="font-medium text-neutral500 font-avenir text-sm ">
                Monthly
              </P1>
            </div>
          </Form>
        </div>
      </CustomModal>
    </>
  )
}

export function CompanyStatutoryModal({
  toggleEditCompanyStatutoryModal,
  company,
}: {
  isLoading: boolean
  company: ICompany | undefined
  toggleEditCompanyStatutoryModal: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const queryClient = useQueryClient()
  const [form] = Form.useForm()
  const { toast } = useAppNotificationStore()
  const { user } = useAuthStore()
  const { customizationSettings } = useCustomizationSettingsStore()

  const { isLoading: isPeopleLoading, data: people } = usePost<
    { personId: number; fullName: string }[]
  >({
    url: "/admin/company/getsignatoryemployees",
    body: {
      q: "",
      page: "",
    },
  })

  const { isLoading: isSubmitting, mutate: submit } = useMutation({
    mutationFn: async (body: any) => {
      const res = await axiosInstance.post(`/admin/company/add_signatory`, body)
      return res.data
    },
    onSuccess: (data) => {
      if (data?.status === "success") {
        queryClient.invalidateQueries({
          queryKey: ["admin/company/view/details"],
        })
        toggleEditCompanyStatutoryModal(false)
        mixPanelEvent("company-add-signatory-success")
      } else {
        toast.error(data.msg)
      }
    },
  })

  const onFinish = (values: any) => {
    mixPanelEvent("company-add-signatory-success")
    submit({
      person_id: values.companySignatory,
    })
  }

  return (
    <>
      <CustomModal
        onCancel={() => {
          toggleEditCompanyStatutoryModal(false)
        }}
        title="Add New Company Signatory"
        open
        footer={
          <div className="flex justify-end gap-4">
            <Button
              color="secondary"
              onClick={(e) => {
                e.preventDefault()
                toggleEditCompanyStatutoryModal(false)
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={isSubmitting}
              submitting={isSubmitting}
              onClick={(e) => {
                e.preventDefault()
                form.submit()
              }}
            >
              Save
            </Button>
          </div>
        }
      >
        <div className=" flex flex-col gap-4">
          <P2 className="text-neutral300 font-avenir">
            The company signatories are responsible for electronically signing
            all documents created on {customizationSettings?.brand.platformName}
            . When a new form is available to be e-signed, the Signatories are
            notified to electronically sign it online.
          </P2>

          <Form
            name="companySignatories"
            layout="vertical"
            form={form}
            onFinish={onFinish}
            autoComplete="off"
          >
            <CustomSelect
              label="Company signatory"
              name="companySignatory"
              required
              onSelectClick={() => {}}
              isLoading={isPeopleLoading}
              options={
                isPeopleLoading
                  ? []
                  : people?.map((person) => ({
                      label: person.fullName,
                      value: person.personId.toString(),
                    }))
              }
              placeholder="Please select"
            />
          </Form>
        </div>
      </CustomModal>
    </>
  )
}

export function NhfReferenceNumberModal({
  toggleNhfReferenceNumberModal,
  company,
  isLoading,
}: {
  isLoading: boolean
  company: ICompany | undefined
  toggleNhfReferenceNumberModal: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const queryClient = useQueryClient()
  const [form] = Form.useForm()
  const { user } = useAuthStore()
  const { toast } = useAppNotificationStore()

  const { isLoading: isSubmitting, mutate: submit } = useMutation({
    mutationFn: async (body: any) => {
      const res = await axiosInstance.post(
        `/admin/company/edit_housing_details`,
        body
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data?.status === "success") {
        queryClient.invalidateQueries({
          queryKey: ["admin/company/view/details"],
        })
        toggleNhfReferenceNumberModal(false)
        mixPanelEvent("company-edit-housing-details-success")
      } else {
        toast.error(data.msg)
      }
    },
  })

  const onFinish = (values: any) => {
    values
    submit({
      housing_reference_number: values.referenceNumber
        ? values.referenceNumber
        : "",
    })
    mixPanelEvent("company-edit-housing-details-init")
  }

  return (
    <>
      <CustomModal
        onCancel={() => {
          toggleNhfReferenceNumberModal(false)
        }}
        title="Company NHF Reference Number"
        open
        footer={
          <div className="flex justify-end gap-4">
            <Button
              color="secondary"
              onClick={(e) => {
                e.preventDefault()
                toggleNhfReferenceNumberModal(false)
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={isSubmitting}
              submitting={isSubmitting}
              onClick={(e) => {
                e.preventDefault()
                form.submit()
              }}
            >
              Save
            </Button>
          </div>
        }
      >
        <div className=" flex flex-col gap-4">
          <div className="bg-primary50 flex justify-start items-center gap-3 py-4 px-4">
            <ExclamationCircleIcon className="w-5 text-primary500" />
            <P2 className="text-neutral300">
              We won’t remit your NHF unless you ask us to.
            </P2>
          </div>

          <Form
            name="NHFForm"
            layout="vertical"
            form={form}
            onFinish={onFinish}
            autoComplete="off"
            initialValues={{
              referenceNumber: company?.housing_reference_number,
            }}
          >
            <CustomInput
              label="Reference Number"
              name="referenceNumber"
              required
            />
          </Form>
        </div>
      </CustomModal>
    </>
  )
}

export function NsitfModal({
  toggleNsitfModal,
  company,
  isLoading,
}: {
  isLoading: boolean
  company: ICompany | undefined
  toggleNsitfModal: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const queryClient = useQueryClient()
  const [form] = Form.useForm()
  const { user } = useAuthStore()
  const { toast } = useAppNotificationStore()

  const { isLoading: isSubmitting, mutate: submit } = useMutation({
    mutationFn: async (body: any) => {
      const res = await axiosInstance.post(
        `/admin/company/edit_nsitf_details`,
        body
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data?.status === "success") {
        queryClient.invalidateQueries({
          queryKey: ["admin/company/view/details"],
        })
        toggleNsitfModal(false)
        mixPanelEvent("company-edit-nsitf-details-success")
      } else {
        toast.error(data.msg)
      }
    },
  })

  const onFinish = (values: any) => {
    values
    submit({
      nsitf_reference_number: values.referenceNumber
        ? values.referenceNumber
        : "",
    })
    mixPanelEvent("company-edit-nsitf-details-init")
  }

  return (
    <>
      <CustomModal
        onCancel={() => {
          toggleNsitfModal(false)
        }}
        title="Company NSITF Reference Number"
        open
        footer={
          <div className="flex justify-end gap-4">
            <Button
              color="secondary"
              onClick={(e) => {
                e.preventDefault()
                toggleNsitfModal(false)
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={isSubmitting}
              submitting={isSubmitting}
              onClick={(e) => {
                e.preventDefault()
                form.submit()
              }}
            >
              Save
            </Button>
          </div>
        }
      >
        <div className=" flex flex-col gap-4">
          <div className="bg-primary50 flex justify-start items-center gap-3 py-4 px-4">
            <ExclamationCircleIcon className="w-5 text-primary500" />
            <P2 className="text-neutral300">
              We won’t remit your NSITF unless you ask us to.
            </P2>
          </div>

          <Form
            name="NHFForm"
            layout="vertical"
            form={form}
            onFinish={onFinish}
            autoComplete="off"
            initialValues={{
              referenceNumber: company?.nsitf_reference_number,
            }}
          >
            <CustomInput
              label="Reference Number"
              name="referenceNumber"
              required
            />
          </Form>
        </div>
      </CustomModal>
    </>
  )
}

export function ItfModal({
  toggleItfModal,
  company,
  isLoading,
}: {
  isLoading: boolean
  company: ICompany | undefined
  toggleItfModal: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const queryClient = useQueryClient()
  const [form] = Form.useForm()
  const { user } = useAuthStore()
  const { toast } = useAppNotificationStore()

  const { isLoading: isSubmitting, mutate: submit } = useMutation({
    mutationFn: async (body: any) => {
      const res = await axiosInstance.post(
        `/admin/company/edit_itf_details`,
        body
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data?.status === "success") {
        queryClient.invalidateQueries({
          queryKey: ["admin/company/view/details"],
        })
        toggleItfModal(false)
        mixPanelEvent("company-edit-itf-details-success")
      } else {
        toast.error(data.msg)
      }
    },
  })

  const onFinish = (values: any) => {
    values
    submit({
      itf_reference_number: values.referenceNumber
        ? values.referenceNumber
        : "",
    })
    mixPanelEvent("company-edit-itf-details-init")
  }

  return (
    <>
      <CustomModal
        onCancel={() => {
          toggleItfModal(false)
        }}
        title="Company ITF Reference Number"
        open
        footer={
          <div className="flex justify-end gap-4">
            <Button
              color="secondary"
              onClick={(e) => {
                e.preventDefault()
                toggleItfModal(false)
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={isSubmitting}
              submitting={isSubmitting}
              onClick={(e) => {
                e.preventDefault()
                form.submit()
              }}
            >
              Save
            </Button>
          </div>
        }
      >
        <div className=" flex flex-col gap-4">
          <div className="bg-primary50 flex justify-start items-center gap-3 py-4 px-4">
            <ExclamationCircleIcon className="w-5 text-primary500" />
            <P2 className="text-neutral300">
              We won’t remit your ITF unless you ask us to.
            </P2>
          </div>

          <Form
            name="NHFForm"
            layout="vertical"
            form={form}
            onFinish={onFinish}
            autoComplete="off"
            initialValues={{
              referenceNumber: company?.itf_reference_number,
            }}
          >
            <CustomInput
              label="Reference Number"
              name="referenceNumber"
              required
            />
          </Form>
        </div>
      </CustomModal>
    </>
  )
}

export function SwitchFilingMailingAddress({
  toggleEditSwitchFilingMailingAddress,
  company,
}: {
  isLoading: boolean
  company:
    | {
        filing_address_id: number
        filing_address_key: string
        mailing_address_id: number
        mailing_address_key: string
        officeAddresses: IAddress[]
      }
    | undefined
  toggleEditSwitchFilingMailingAddress: React.Dispatch<
    React.SetStateAction<boolean>
  >
}) {
  const queryClient = useQueryClient()
  const [form] = Form.useForm()
  const { toast } = useAppNotificationStore()
  const { customizationSettings } = useCustomizationSettingsStore()

  const { isLoading: isAddressesLoading, data: addresses } = usePost<
    IOfficeAddress[]
  >({
    url: "/admin/company/getofficeaddresses?canadd=false",
    body: {
      q: "",
      page: "",
    },
  })

  const { isLoading: isSubmitting, mutate: submit } = useMutation({
    mutationFn: async (body: any) => {
      const res = await axiosInstance.post(
        `/admin/company/switch_officeaddress`,
        body
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data?.status === "success") {
        queryClient.invalidateQueries({
          queryKey: ["/admin/company/getofficeaddresses?canadd=true"],
        })
        toggleEditSwitchFilingMailingAddress(false)
        mixPanelEvent("company-switch-office-address-success")
      } else {
        toast.error(data.msg)
      }
    },
  })

  const onFinish = (values: any) => {
    values
    const filing_address_id = values.filingAddress.split("-")[0]
    const _filingAddress = values.filingAddress.split("-")[1]
    const mailing_address_id = values.mailingAddress.split("-")[0]
    const _mailingAddress = values.mailingAddress.split("-")[1]

    submit({
      filing_address_id,
      _filingAddress,
      mailing_address_id,
      _mailingAddress,
      au_comment: "",
    })
    mixPanelEvent("company-switch-office-address-init")
  }

  return (
    <>
      <CustomModal
        onCancel={() => {
          toggleEditSwitchFilingMailingAddress(false)
        }}
        title="Select Filing & Mailing Addresses"
        open
        width={700}
        footer={
          <div className="flex justify-end gap-4">
            <Button
              type="button"
              color="secondary"
              onClick={(e) => {
                e.preventDefault()
                toggleEditSwitchFilingMailingAddress(false)
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={isSubmitting}
              submitting={isSubmitting}
              onClick={(e) => {
                e.preventDefault()
                form.submit()
              }}
            >
              Save
            </Button>
          </div>
        }
      >
        <div className=" flex flex-col gap-4">
          <div className="space-y-2">
            <P2 className="text-neutral300">
              <span className="font-semibold">Filing Address:</span> This is
              your primary physical place of business. It can’t be a P.O. Box.
              The filing address should match the address you have on file with
              your State Tax Authority or IRS, which you can find on your TIN
              assignment form. We’ll use it for all local, state and federal
              references.
            </P2>
            <P2 className="text-neutral300">
              <span className="font-semibold">Mailing Address:</span> This is
              where you’d like to receive mail from{" "}
              {customizationSettings?.brand.platformName}. It’s usually the same
              as your filing address.
            </P2>
            <P2 className="text-neutral300">
              <span className="font-semibold">Please Note:</span> Before
              updating your address on{" "}
              {customizationSettings?.brand.platformName}, you must notify your
              State Tax Authority and/or the IRS of your address change.
            </P2>
          </div>

          <Spin spinning={isAddressesLoading}>
            <Form
              name="addWorkspace"
              layout="vertical"
              form={form}
              onFinish={onFinish}
              autoComplete="off"
              initialValues={{
                filingAddress: company?.filing_address_id?.toString(),
                mailingAddress: company?.mailing_address_id?.toString(),
              }}
            >
              <Form.Item
                label={
                  <span className="font-circular text-neutral300 text-sm">
                    Filing address
                  </span>
                }
                name="filingAddress"
                rules={[{ required: true, message: "Please enter this field" }]}
              >
                <Select
                  style={{ width: "100%" }}
                  placeholder="Select employees"
                  loading={isAddressesLoading}
                  showSearch
                  filterOption={(input, option) =>
                    (option?.children ?? "")
                      // @ts-ignore
                      ?.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {addresses?.map((item, idx) => (
                    <Select.Option
                      value={item.officeAddressId?.toString()}
                      key={idx}
                    >
                      {item.displayName}
                    </Select.Option>
                  ))}

                  {["first", "second"].map((item) => (
                    <Select.Option
                      key={"empty" + item}
                      className="pointer-events-none"
                      disabled
                    >
                      {" "}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              {/* mailing address */}

              <Form.Item
                label={
                  <span className="font-circular text-neutral300 text-sm">
                    Mailing address
                  </span>
                }
                name="mailingAddress"
                rules={[{ required: true, message: "Please enter this field" }]}
              >
                <Select
                  style={{ width: "100%" }}
                  placeholder="Select employees"
                  loading={isAddressesLoading}
                  showSearch
                  filterOption={(input, option) =>
                    (option?.children ?? "")
                      // @ts-ignore
                      ?.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {addresses?.map((item, idx) => (
                    <Select.Option
                      value={item.officeAddressId?.toString()}
                      key={idx}
                    >
                      {item.displayName}
                    </Select.Option>
                  ))}

                  {["first", "second"].map((item) => (
                    <Select.Option
                      key={"empty" + item}
                      className="pointer-events-none"
                      disabled
                    >
                      {" "}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>
          </Spin>
        </div>
      </CustomModal>
    </>
  )
}
