import React from "react"
import RequireRoutePermit from "RequireRoutePermit"
import { Route } from "react-router-dom"
import PayrollConfigurations from "./PayrollConfigurations"

const PaySchedules = React.lazy(() => import("./pay-schedules/PaySchedules"))
const CreateSchedule = React.lazy(
  () => import("./pay-schedules/CreateSchedule")
)
const EditScheduleRegular = React.lazy(
  () => import("./pay-schedules/EditRegularSchedule")
)
const EditScheduleCustom = React.lazy(
  () => import("./pay-schedules/EditCustomSchedule")
)

const Paybands = React.lazy(() => import("./paybands/Paybands"))
const CreateNewPayband = React.lazy(() => import("./paybands/AddNewPayband"))
const EditPayband = React.lazy(() => import("./paybands/EditPayband"))
const ViewPayband = React.lazy(() => import("./paybands/ViewPayband"))

const Paygrades = React.lazy(() => import("./paygrades/Paygrades"))
const PaygradeEmployees = React.lazy(
  () => import("./paygrades/PaygradeEmployees")
)
const PaybandEmployees = React.lazy(() => import("./paybands/PaybandEmployees"))
const CreateNewPaygrade = React.lazy(() => import("./paygrades/AddNewPaygrade"))
const EditPaygrade = React.lazy(() => import("./paygrades/EditPaygrade"))
const ViewPaygrade = React.lazy(() => import("./paygrades/ViewPaygrade"))
const ViewPaygradePaye = React.lazy(
  () => import("./paygrades/ViewPaygradePaye")
)

const Allowances = React.lazy(() => import("./allowances/Allowances"))
const PargradeAllowanceForm = React.lazy(
  () => import("./allowances/PargradeAllowanceForm")
)
const EditAllowance = React.lazy(() => import("./allowances/EditAllowance"))

const TaxReliefs = React.lazy(() => import("./tax-relief/TaxReliefs"))
const TaxReliefForm = React.lazy(() => import("./tax-relief/TaxReliefForm"))
const StatutoryBenefits = React.lazy(
  () => import("./statutory-benefits/StatutoryBenefits")
)
const StatutoryBenefitForm = React.lazy(
  () => import("./statutory-benefits/StatutoryBenefitForm")
)
const TaxTable = React.lazy(() => import("./tax-table/TaxTables"))

// const TaxTables = React.lazy(
//   () => import("./hr-payroll/TaxTables")
// )

const payrollConfigRoutes = (
  <>
    <Route
      path="payroll/configuration/"
      element={
        <RequireRoutePermit
          allowedPermissions={["can_manage_payroll_configurations"]}
        >
          <PayrollConfigurations />
        </RequireRoutePermit>
      }
    >
      <Route
        path="pay-schedules"
        element={<PaySchedules />}
      />

      <Route
        path="paygrades"
        element={<Paygrades />}
      />
      <Route
        path="paybands"
        element={<Paybands />}
      />

      <Route
        path="allowances"
        element={<Allowances />}
      />
      <Route
        path="tax-reliefs"
        element={<TaxReliefs />}
      />
      <Route
        path="statutory-benefits"
        element={<StatutoryBenefits />}
      />
      <Route
        path="tax-tables"
        element={<TaxTable />}
      />
    </Route>

    <Route
      path="payroll/configuration/pay-schedules/create"
      element={
        <RequireRoutePermit
          allowedPermissions={["can_manage_payroll_configurations"]}
        >
          <CreateSchedule />
        </RequireRoutePermit>
      }
    />
    <Route
      path="payroll/configuration/pay-schedules/edit-custom"
      element={
        <RequireRoutePermit
          allowedPermissions={["can_manage_payroll_configurations"]}
        >
          <EditScheduleCustom />
        </RequireRoutePermit>
      }
    />
    <Route
      path="payroll/configuration/pay-schedules/edit"
      element={
        <RequireRoutePermit
          allowedPermissions={["can_manage_payroll_configurations"]}
        >
          <EditScheduleRegular />
        </RequireRoutePermit>
      }
    />
    <Route
      path="payroll/configuration/paygrades/create"
      element={
        <RequireRoutePermit
          allowedPermissions={["can_manage_payroll_configurations"]}
        >
          <CreateNewPaygrade />
        </RequireRoutePermit>
      }
    />
    <Route
      path="payroll/configuration/paygrades/edit"
      element={
        <RequireRoutePermit
          allowedPermissions={["can_manage_payroll_configurations"]}
        >
          <EditPaygrade />
        </RequireRoutePermit>
      }
    />
    <Route
      path="payroll/configuration/paygrades/paye"
      element={
        <RequireRoutePermit
          allowedPermissions={["can_manage_payroll_configurations"]}
        >
          <ViewPaygradePaye />
        </RequireRoutePermit>
      }
    />
    <Route
      path="payroll/configuration/paygrades/view"
      element={
        <RequireRoutePermit
          allowedPermissions={["can_manage_payroll_configurations"]}
        >
          <ViewPaygrade />
        </RequireRoutePermit>
      }
    />
    <Route
      path="payroll/configuration/paygrades/view/employees"
      element={
        <RequireRoutePermit
          allowedPermissions={["can_manage_payroll_configurations"]}
        >
          <PaygradeEmployees />
        </RequireRoutePermit>
      }
    />

    {/* === */}
    <Route
      path="payroll/configuration/paybands/create"
      element={
        <RequireRoutePermit
          allowedPermissions={["can_manage_payroll_configurations"]}
        >
          <CreateNewPayband />
        </RequireRoutePermit>
      }
    />
    <Route
      path="payroll/configuration/paybands/edit"
      element={
        <RequireRoutePermit
          allowedPermissions={["can_manage_payroll_configurations"]}
        >
          <EditPayband />
        </RequireRoutePermit>
      }
    />
    <Route
      path="payroll/configuration/paybands/view"
      element={
        <RequireRoutePermit
          allowedPermissions={["can_manage_payroll_configurations"]}
        >
          <ViewPayband />
        </RequireRoutePermit>
      }
    />
    <Route
      path="payroll/configuration/paybands/view/employees"
      element={
        <RequireRoutePermit
          allowedPermissions={["can_manage_payroll_configurations"]}
        >
          <PaybandEmployees />
        </RequireRoutePermit>
      }
    />
    {/* === */}

    <Route
      path="payroll/configuration/allowances/create"
      element={
        <RequireRoutePermit
          allowedPermissions={["can_manage_payroll_configurations"]}
        >
          <PargradeAllowanceForm />
        </RequireRoutePermit>
      }
    />
    <Route
      path="payroll/configuration/allowances/edit"
      element={
        <RequireRoutePermit
          allowedPermissions={["can_manage_payroll_configurations"]}
        >
          <EditAllowance />
        </RequireRoutePermit>
      }
    />

    <Route
      path="payroll/configuration/tax-reliefs/create"
      element={
        <RequireRoutePermit
          allowedPermissions={["can_manage_payroll_configurations"]}
        >
          <TaxReliefForm />
        </RequireRoutePermit>
      }
    />
    <Route
      path="payroll/configuration/statutory-benefits/create"
      element={
        <RequireRoutePermit
          allowedPermissions={["can_manage_payroll_configurations"]}
        >
          <StatutoryBenefitForm />
        </RequireRoutePermit>
      }
    />
    <Route
      path="payroll/configuration/statutory-benefits/edit/:statutorybenefitid"
      element={
        <RequireRoutePermit
          allowedPermissions={["can_manage_payroll_configurations"]}
        >
          <StatutoryBenefitForm />
        </RequireRoutePermit>
      }
    />
  </>
)

export default payrollConfigRoutes
