import { useEffect } from "react"

import { useNavigate, useSearchParams } from "react-router-dom"
import { useAuthStore } from "stores"

import { LoadingIndicator } from "components/custom/Loader"

import "react-toastify/dist/ReactToastify.css"

const SwitchApp = () => {
  const navigate = useNavigate()
  const { switchApp, clearAuthDataStorage } = useAuthStore()

  const [searchParams] = useSearchParams()

  const handleLogin = async () => {
    try {
      const token = searchParams.get("token") || ""
      const code = searchParams.get("code") || ""
      const response = await switchApp(token, code)

      if (response.status === "Success") {
        if (response.user?.isAdmin === "True") {
          if (
            response.user?.setupPercentage === "100" ||
            response?.user?.setupStatus === "Approved"
          ) {
            navigate("/dashboard/")
          } else {
            navigate("/dashboard/company-onboarding")
          }
        } else {
          if (response.user.userStatusId === "1") {
            navigate("/employee/dashboard/")
          } else {
            navigate("/employee/dashboard/welcome")
          }
        }
      } else {
        clearAuthDataStorage()

        navigate("/auth/login")
      }
    } catch (error) {
      clearAuthDataStorage()
      navigate("/auth/login")
    }
  }

  useEffect(() => {
    handleLogin()
  }, [])

  return (
    <div>
      <div className="z-[2] flex  items-center justify-center py-10 bg-white bg-opacity-75  h-screen absolute left-0 top-0 right-0  ">
        <div>
          <span className="mr-[10px]">Loading</span>
          <LoadingIndicator />
        </div>
      </div>
    </div>
  )
}

export default SwitchApp
