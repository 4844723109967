import { XMarkIcon } from "@heroicons/react/24/outline"
import { Button, Input, Label, ModalWrapper, TextareaInput } from "components"
import React, { useState } from "react"

import { FileDragAndDrop } from "components/inputs/FileDragAandDrop"
import { useMutation } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { toast } from "react-toastify"

type props = {
  metric: string
  progress: string
  closeModal: Function
  handleSuccess: Function
  employee_objective_id: string
}
export const UpdateMeasurement: React.FC<props> = ({
  metric,
  progress,
  closeModal,
  employee_objective_id,
  handleSuccess,
}) => {
  const [target, setTarget] = useState(progress || "")
  const [comment, setComment] = useState("")
  const [file, setFile] = useState("")

  const handleUpdateStatus = () => {
    updateProgress({
      id: employee_objective_id,
      progress: target,
      comment: comment,
      file_upload: file,
    })
  }
  const { isLoading, mutate: updateProgress } = useMutation({
    mutationFn: async (body: any) => {
      const headers = {
        "Content-Type": "multipart/form-data",
      }

      const res = await axiosInstance.post(
        `employee/performance/update_employeeobjective_progress`,
        body,
        { headers }
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data.status === "success") {
        data.msg && toast.success(data.msg)
        closeModal()
        handleSuccess()
      } else {
        toast.error(data?.msg)
      }
    },
  })
  const allMetrics = [
    {
      label: "%",
      id: 2,
    },
    {
      label: "N",
      id: 3,
    },
    {
      label: "#",
      id: 4,
    },
  ]
  const updatedMetric = allMetrics.find((met) => met.id == Number(metric))
  console.log(updatedMetric)

  return (
    <ModalWrapper
      useBgColor={true}
      title="            Update measurement progress
"
      onClose={() => {
        closeModal()
      }}
    >
      <div className=" bg-white py-7 px-4">
        <div className="mb-6">
          <Label
            htmlFor="work-location"
            className=" text-sm text-neutral500 font-semibold"
          >
            Current progress<span className="text-[#F64346]">*</span>
          </Label>
          <div className="border  border-neutral40 flex rounded-5px overflow-hidden h-9">
            <div className="w-[30px] h-full bg-backgrounddarker flex justify-center items-center text-neutral100">
              {updatedMetric?.label}
            </div>
            <Input
              type={"number"}
              name={""}
              // id="mobile-number"
              required={true}
              placeholder="e.g 100"
              value={target}
              onChange={(e) => {
                setTarget(e.target.value)
              }}
              className=" rounded-none border-none h-full px-1"
            />
          </div>
        </div>{" "}
        <div className="mb-6">
          <Label
            htmlFor="work-location"
            className=" text-sm text-neutral500 font-semibold"
          >
            Comment<span className="text-[#F64346]">*</span>
          </Label>
          <TextareaInput
            placeholder="Comment"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </div>
        <div>
          <Label
            htmlFor="work-location"
            className=" text-sm text-neutral500 font-semibold"
          >
            Proof(optional)
          </Label>
          <FileDragAndDrop setFile={setFile} />
        </div>
        <div className="w-full flex gap-6 justify-end mt-6   shadow-footer  bg-white ">
          <Button
            title="Previous"
            color="secondary"
            // disabled={currentTab === 1}
            onClick={() => {
              closeModal()
            }}
          >
            Cancel
          </Button>

          <Button
            title="Next"
            color="primary"
            submitting={isLoading}
            // submitting={isLoadingUpdateKeyIntiativeStatus}
            disabled={target == "" || comment == "" ? true : false}
            onClick={() => {
              handleUpdateStatus()
            }}
          >
            Update{" "}
          </Button>
        </div>
      </div>
    </ModalWrapper>
  )
}
