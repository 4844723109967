import { Button, NotificationBar } from "components"
import SignBoard from "assets/images/sign-board 1.png"
import { useEffect, useState } from "react"
import Subtract from "assets/images/Subtract.svg"
import { CustomInput } from "pages/dashboard/documents/custom-form"
import PrevNextButton from "pages/dashboard/documents/custom-form/PrevNextButton"
import { Checkbox, Form, Input, Radio, Spin, Tooltip } from "antd"
import FormSectionWrapper from "pages/dashboard/people/people/FormSectionWrapper"
import { useGet, usePost } from "utils/useFetch"
import {
  ICheckEmailData,
  ICountry,
} from "pages/dashboard/people/people/typings"
import { CustomSelect } from "pages/dashboard/extras/settings/form"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { useAppNotificationStore } from "stores"
import { LoadingIndicatorWhite } from "components/custom/LoaderWhite"
import { InformationCircleIcon } from "@heroicons/react/24/outline"
import onboarding_img from "assets/images/onboarding_img.svg"
import { isPadeDomain } from "utils/utils"
import { useCustomizationSettingsStore } from "stores/customization-settings"
import NewAuthWrapper from "pages/new-auth/NewAuthWrapper"
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/solid"

const EmployerOnboarding = () => {
  const myParam = useLocation().search
  const code = new URLSearchParams(myParam).get("code")
  const { customizationSettings } = useCustomizationSettingsStore()

  const navigate = useNavigate()

  const [successMessage, setSuccessMessage] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)

  const [country, setCountry] = useState({
    id: "",
    name: "",
  })

  const reqBody = {
    q: "",
    page: "",
  }
  const { isLoading: isCountriesLoading, data: countries } = usePost<
    ICountry[]
  >({ url: "/commonutils/getcountries", body: reqBody })

  const {
    isFetching: isLoading,
    data,
    isError,
  } = useGet<IDetails>({
    url: `/account/view/register?code=${encodeURIComponent(code || "")}`,
  })

  useEffect(() => {
    if (data) {
      if (data?.isPasswordSet) {
        navigate("/auth/login")
      }

      if (data?.companyName === null) {
        setErrorMessage(true)
      }
    }
  }, [data])
  useEffect(() => {
    if (data && countries) {
      const selectedCountry = countries?.find(
        (country) => country.countryId == data?.countryId
      )
      if (selectedCountry) {
        setCountry({
          id: String(selectedCountry.countryId),
          name: selectedCountry.description,
        })
      }
    }
  }, [countries, data])

  return (
    <>
      <NotificationBar />
      {isPadeDomain() === true ? (
        <NewAuthWrapper
          heading={`Create your ${customizationSettings?.brand.platformName} account`}
          subHeading={
            "We’ve made managing your workforce really simple - helping you spend less time on desk work and managing routine tasks."
          }
          showGetHelp
          showCompanies={false}
          progressData={{
            percent: 100,
            text: "4/4",
            completed: successMessage,
          }}
          childrenWrapperClassName={
            successMessage || errorMessage ? "" : "lg:w-[650px]"
          }
          yelloBgClassName="!min-h-[200vh] !right-[280px]"
        >
          {isLoading ? (
            <div className="grid place-content-center grow min-h-full">
              <Spin size="large" />
            </div>
          ) : errorMessage ? (
            <div className="w-full  mt-3 flex justify-center">
              <div className=" flex flex-col justify-center items-center">
                <ExclamationCircleIcon className="text-[#FF0000] w-20 mb-4 " />

                <div className="w-full flex flex-col gap-4 items-center">
                  <h4 className=" text-neutral500 text-xl font-bold mb-4">
                    Missing Registration Link!
                  </h4>
                  <p className="text-[#5D6B82] text-base text-center mb-2">
                    It seems the registeration link you are trying to use is
                    broken. Please reach out to our customer success team to
                    resolve this problem.
                  </p>
                  <p className="text-[#5D6B82] text-base text-center  ">
                    Please proceed to{" "}
                    <Link
                      to="/auth/login"
                      className="text-primary500 font-semibold cursor-pointer"
                    >
                      sign in
                    </Link>{" "}
                    if you already have an account with us.
                  </p>

                  <Button
                    type="submit"
                    className="h-full w-full font-bold mt-10"
                    color="primary"
                    onClick={() => {
                      navigate("/onboarding/business-registration")
                    }}
                  >
                    Register your account
                  </Button>
                </div>
              </div>
            </div>
          ) : successMessage ? (
            <div className="w-full mt-3 flex justify-center">
              <div className="h-full flex flex-col justify-center items-center">
                <h6 className="text-neutral600 font-medium text-base mb-4">
                  Registration complete
                </h6>

                <CheckCircleIcon className="text-[#38CB89] w-[87px] aspect-square" />

                <div className="w-full flex flex-col gap-4 items-center">
                  <h4 className=" text-neutral500 text-xl font-bold mb-4">
                    Congratulations!
                  </h4>
                  <p className="text-[#5D6B82] text-sm text-center mb-2">
                    We’ve made managing your workforce really simple - helping
                    you spend less time on desk work and managing routine tasks.
                  </p>
                  <p className="text-[#5D6B82] text-sm text-center  ">
                    You’re about to experience payroll, benefits and HR
                    automation like never before.
                  </p>

                  <Button
                    type="submit"
                    className="h-full font-bold mt-10"
                    color="primary"
                    onClick={() => {
                      navigate("/auth/login")
                    }}
                  >
                    Sign in
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            country.id !== "" && (
              <div className="">
                <h2 className="font-circular font-bold text-3xl mb-4">
                  Confirm Your Details
                </h2>
                <p className="font-avenir text-base text-neutral400 mb-6 ">
                  Review your information and set your password.
                </p>

                <RegForm
                  country={country}
                  data={data}
                  setCountry={setCountry}
                  countries={countries}
                  setSuccessMessage={setSuccessMessage}
                  isCountriesLoading={isCountriesLoading}
                  code={code}
                />
              </div>
            )
          )}
        </NewAuthWrapper>
      ) : (
        <div className="h-screen w-full">
          <div className="flex md:flex-row bg-primary50 font-avenir">
            <div className="hidden lg:block fixed top-0 bottom-0 left-0">
              <div className="relative w-[417px] h-full bg-primary500 md:pt-[157px] md:px-12">
                <img
                  className="absolute h-[119px] bottom-[66px] right-0"
                  src={SignBoard}
                  alt=""
                />
                <img
                  className="absolute w-[70px] bottom-[76px] right-0"
                  src={Subtract}
                  alt=""
                />
                <h1 className="text-[#F5F6F7] circularstdbold font-bold text-[32px] leading-[40.48px] mb-[38px] md:mr-4">
                  A few clicks from creating your account
                </h1>
                <p className="text-[#DFE2E6] avenirstdroman font-normal text-[18px] leading-[25px]">
                  We've made managing your workforce really simple - helping you
                  spend less time on desk work and managing routine tasks.
                </p>
              </div>
            </div>
            <div className="lg:ml-[417px] lg:w-[calc(100%-417px)] min-h-screen h-full grow pt-[35px] px-4 md:pl-[83px] md:pr-[68.5px] pb-[30px] ">
              {isLoading ? (
                <div className="absolute  bg-white z-50 flex justify-center items-center right-0 top-0 left-0 bottom-0">
                  <LoadingIndicatorWhite />
                </div>
              ) : errorMessage ? (
                <div className="w-full mt-3 flex justify-center">
                  <div className=" max-w-[600px] mx-auto flex flex-col justify-center items-center">
                    <ExclamationCircleIcon className="text-[#FF0000] w-20 mb-4 " />

                    <div className="w-full flex flex-col gap-4 items-center">
                      <h4 className=" text-neutral500 text-xl font-bold mb-4">
                        Missing Registration Link!
                      </h4>
                      <p className="text-[#5D6B82] text-base text-center mb-2">
                        It seems the registeration link you are trying to use is
                        broken. Please reach out to our customer success team to
                        resolve this problem.
                      </p>
                      <p className="text-[#5D6B82] text-base text-center  ">
                        Please proceed to{" "}
                        <Link
                          to="/auth/login"
                          className="text-primary500 font-semibold cursor-pointer"
                        >
                          sign in
                        </Link>{" "}
                        if you already have an account with us.
                      </p>

                      <Button
                        type="submit"
                        className="h-full w-full font-bold mt-10"
                        color="primary"
                        onClick={() => {
                          navigate("/onboarding/business-registration")
                        }}
                      >
                        Register your account
                      </Button>
                    </div>
                  </div>
                </div>
              ) : successMessage ? (
                <div className="w-full mt-3 flex justify-center">
                  <div className="h-full w-9/12 flex flex-col justify-center items-center p-10 bg-white">
                    <h6 className="text-neutral600 font-medium text-base mb-4">
                      Registration complete
                    </h6>

                    <CheckCircleIcon className="text-[#38CB89] w-[87px] aspect-square" />

                    <div className="w-full flex flex-col gap-4 items-center">
                      <h4 className=" text-neutral500  text-xl font-bold mb-4">
                        Congratulations!
                      </h4>
                      <p className="text-[#5D6B82] text-sm text-center mb-2">
                        We’ve made managing your workforce really simple -
                        helping you spend less time on desk work and managing
                        routine tasks.
                      </p>
                      <p className="text-[#5D6B82] text-sm text-center  ">
                        You’re about to experience payroll, benefits and HR
                        automation like never before.
                      </p>

                      <Button
                        type="submit"
                        className="h-full font-bold mt-10"
                        color="primary"
                        onClick={() => {
                          navigate("/auth/login")
                        }}
                      >
                        Sign in
                      </Button>
                    </div>
                  </div>
                </div>
              ) : (
                country.id !== "" && (
                  <>
                    {isPadeDomain() ? (
                      <div className="flex justify-end text-sm text-neutral500 mb-[6px] px-4">
                        Having troubles?{" "}
                        <a
                          className="text-primary500  font-medium cursor-pointer  ml-0.5"
                          href="https://support.padehcm.com/help"
                          target="_blank"
                        >
                          {" "}
                          Get help
                        </a>
                      </div>
                    ) : null}

                    <RegForm
                      country={country}
                      data={data}
                      setCountry={setCountry}
                      countries={countries}
                      setSuccessMessage={setSuccessMessage}
                      isCountriesLoading={isCountriesLoading}
                      code={code}
                    />
                  </>
                )
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}
export default EmployerOnboarding

const RegForm = ({
  country,
  data,
  setCountry,
  countries,
  setSuccessMessage,
  isCountriesLoading,
  code,
}: {
  data: IDetails | undefined
  country: { id: string; name: string }
  setCountry: React.Dispatch<React.SetStateAction<{ id: string; name: string }>>
  countries?: ICountry[] | undefined
  setSuccessMessage: React.Dispatch<React.SetStateAction<boolean>>
  isCountriesLoading: boolean
  code: string | null
}) => {
  const [email, setEmail] = useState("")

  const queryClient = useQueryClient()
  const { customizationSettings } = useCustomizationSettingsStore()
  const [form] = Form.useForm()
  const { toast } = useAppNotificationStore()

  const {
    isFetching: isValidatingEmail,
    data: isValidEmail,
    isError: IsValidatingEmailError,
  } = useGet<ICheckEmailData>({
    url: `/admin/people/checkemail?email=${email}&person_id=-1`,
    enabled: !!email,
  })

  const { isLoading: isLoadingMembers, mutate: createAccount } = useMutation({
    mutationFn: async (body: any) => {
      const res = await axiosInstance.post(`/account/createpassword`, body)
      return res.data
    },
    onSuccess: async (data) => {
      if (data.status == "success") {
        data?.msg && toast.success(data?.msg)
        setSuccessMessage(true)
      } else {
        data?.msg && toast.error(data.msg)
      }
    },
  })

  const onFinish = (values: any) => {
    const body = {
      first_name: values?.firstName,
      last_name: values?.lastName,
      company_name: values?.companyName,
      email: values?.email,
      phone: values?.mobileNumber,
      country_id: country.id,
      // registration_code: "",
      registration_code: code,
      password: values?.password,
      employees: String(values?.employeeCount),
      is_employee: values.payroll,
    }
    createAccount(body)
  }

  const formContent = (
    <div
      className={`gap-x-10  flex flex-col md:grid md:grid-cols-2 ${
        isPadeDomain() === true ? "" : "px-4 md:px-6 xl:px-[60px]"
      }`}
    >
      <div className="col-span-2">
        {" "}
        <CustomInput
          label="Company name"
          name="companyName"
          required
          className="w-full"
        />
      </div>
      <div>
        <CustomSelect
          label="Country"
          name="country"
          required
          onChange={(selected) => {
            const country = countries?.find(
              (country) => country.description === selected
            )
            if (country) {
              setCountry({
                id: String(country.countryId),
                name: country.description,
              })
            }
            // setAlpha2Code(country?.alpha2Code || "")
            // setTimeout(() => {
            //   refetch()
            // }, 100)
          }}
          isLoading={isCountriesLoading}
          options={countries?.map((state) => ({
            label: state.description,
            value: state.description,
          }))}
          placeholder="Please select"
        />
      </div>
      <div>
        <CustomInput
          label="Number of employees"
          name="employeeCount"
          required
          className="w-full"
        />
      </div>
      <CustomInput
        label="First name"
        name="firstName"
        required
        className="w-full"
      />{" "}
      <CustomInput
        label="Last name"
        name="lastName"
        required
        className="w-full"
      />
      <Form.Item
        label={
          <span className="font-circular text-neutral300 text-sm  ">
            Email address
          </span>
        }
        name="email"
        className="!text-neutral300"
        hasFeedback
        validateStatus={
          isValidatingEmail
            ? "validating"
            : isValidEmail?.status === "success"
            ? "success"
            : isValidEmail?.status === "error"
            ? IsValidatingEmailError
              ? "error"
              : "error"
            : undefined
        }
        help={
          isValidatingEmail
            ? "Checking email..."
            : isValidEmail?.status === "success"
            ? "Email is valid"
            : isValidEmail?.status === "error"
            ? "Email is already taken"
            : ""
        }
        rules={[
          {
            required: true,
            message: "Please enter this field!",
          },
        ]}
      >
        <Input
          type="email"
          placeholder="Enter Email"
          id="email"
          className="py-auto h-8"
          onChange={(e) => setEmail(e.target.value)}
        />
      </Form.Item>
      <CustomInput
        label=" Phone Number"
        name="mobileNumber"
        required
        className="w-full"
      />
      {/* <Form.Item
                    name="mobileNumber"
                    label={
                      <span className="font-circular text-neutral300 text-sm  ">
                        Phone Number
                      </span>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please input your phone number!",
                      },
                    ]}
                    className="mb-2"
                  >
                    <Input
                      placeholder="+2349099"
                      type="number"
                      className=""
                    />
                  </Form.Item> */}
      <div className="col-span-2 flex items-start">
        <Form.Item
          name="payroll"
          label={
            <span className="font-circular text-neutral300 text-sm  ">
              Will you be part of employees included in payroll?
            </span>
          }
          rules={[
            {
              required: true,
              message: "Please select an option!",
            },
          ]}
        >
          <Radio.Group>
            <Radio
              value="yes"
              className="text-neutral300"
            >
              Yes
            </Radio>
            <Radio
              value="no"
              className="text-neutral300"
            >
              No
            </Radio>
          </Radio.Group>
        </Form.Item>
        <div className="ml-0.5">
          <Tooltip
            title={`If you choose no, you won’t be eligible to be paid by ${customizationSettings?.brand.platformName} but will still be able to manage your people. This can be changed later`}
          >
            <InformationCircleIcon className="w-5 text-neutral300 hover:text-black cursor-pointer" />
          </Tooltip>
        </div>
      </div>
      <Form.Item
        name="password"
        label={
          <span className="font-circular text-neutral300 text-sm  ">
            Password
          </span>
        }
        rules={[
          {
            required: true,
            message: "Please input your password!",
          },
          {
            //  /(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}/
            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
            message:
              "Password must contain at least 8 characters, one uppercase, one lowercase and one number",
          },
        ]}
        hasFeedback
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        name="confirm"
        label={
          <span className="font-circular text-neutral300 text-sm  ">
            Confirm Password
          </span>
        }
        dependencies={["password"]}
        hasFeedback
        rules={[
          {
            required: true,
            message: "Please confirm your password!",
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve()
              }
              return Promise.reject(
                new Error("The new password that you entered do not match!")
              )
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>
      <div className="col-span-2">
        {/* <Form.Item
          label=""
          name="agree"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(new Error("Should accept agreement")),
            },
          ]}
        >
          <Checkbox>
            Signing up signifies that you have read and agree to the {""}
            <a
              className="text-primary500"
              href="https://www.paidhr.com/terms-of-use"
              target="_blank"
            >
              Terms of Service
            </a>{" "}
            and our{" "}
            <a
              className="text-primary500"
              href="https://www.paidhr.com/privacy-policy"
              target="_blank"
            >
              Privacy Policy
            </a>
            .
          </Checkbox>
        </Form.Item> */}

        <Form.Item
          label=""
          name="sla"
          valuePropName="checked"
          rules={[
            {
              required: true,
              message: "Please accept the service level agreement!",
            },
          ]}
        >
          <Checkbox>
            I have read and agree to the{" "}
            <a
              className="text-primary500"
              href="https://www.paidhr.com/service-level-agreement"
              target="_blank"
            >
              Service Level Agreement
            </a>
            .
          </Checkbox>
        </Form.Item>
      </div>
    </div>
  )

  return (
    <>
      {isPadeDomain() === true ? (
        <Form
          name="create accoynt"
          layout="vertical"
          onFinish={onFinish}
          style={{ maxWidth: 1024 }}
          autoComplete="off"
          initialValues={{
            country: country?.name || "",
            companyName: data?.companyName || "",
            employeeCount: data?.employees || "",
            firstName: data?.firstName || "",
            lastName: data?.lastName || "",
            email: data?.email || "",
            mobileNumber: data?.phone || "",
          }}
        >
          <div>{formContent}</div>
          <div className="mt-4">
            <Button
              type="submit"
              className="w-full"
              color="primary"
              disabled={isLoadingMembers}
              submitting={isLoadingMembers}
            >
              Create Account
            </Button>
          </div>
        </Form>
      ) : (
        <FormSectionWrapper
          name="create accoynt"
          title={`Create your ${customizationSettings?.brand.platformName} account`}
          subtitle="We’ve made managing your workforce really simple - helping you spend less time on desk work and managing routine tasks."
          isActive={true}
          initialValues={{
            country: country?.name,
            companyName: data?.companyName,
            employeeCount: data?.employees,
            firstName: data?.firstName,
            lastName: data?.lastName,
            email: data?.email,
            mobileNumber: data?.phone,
          }}
          layout="vertical"
          form={form}
          onFinish={onFinish}
          style={{ maxWidth: 1024 }}
          autoComplete="off"
          footerBtns={<PrevNextButton showPrevButton={false} />}
          className="font-avenir"
        >
          <div>{formContent}</div>
        </FormSectionWrapper>
      )}
    </>
  )
}

interface Values {
  title: string
  description: string
}
interface IDetails {
  code: string
  country: null
  countryId: number
  companyName: string
  employees: number
  firstName: string
  lastName: string
  email: string
  phone: string
  hasCode: boolean
  isPasswordSet: boolean
}
