import { create } from "zustand"
import { devtools, persist } from "zustand/middleware"
import { APP_ID } from "utils/constants"
import { request } from "utils/request"
import { IFilterOptions } from "stores/interface"
import { IPaginationApi } from "components/custom/Pagination"
import { IFilterPage } from "stores/interface/page"

interface ObjectivesState {
  dptObj: IDptObjResponse[]
  isLoadingGetDptObj: boolean
  keyResult: IDptObjResponse[]
  isLoadingGetKeyResult: boolean
  isLoadingCreateDptObj: boolean
  isLoadingCreateKeyActivity: boolean
  isLoadingCreateKeyTasks: boolean
  isLoadingCreateKeyResult: boolean
  isLoadingCreateKeyMeasurement: boolean
  isLoadingGetKeyResultDetailed: boolean
  isLoadingDptMembers: boolean
  objectiveOwners: IObjectiveOwner[]
  keyResultPerson: IKeyResultPersonResponse[]
  isLoadingUpdateKeyIntiativeStatus: boolean
}
export const GroupType = [
  { label: "Organizational", id: "1" },
  { label: "Departmental", id: "2" },
  // { label: "Team", id: "3" },
  { label: "Individual", id: "4" },
  { label: "Custom Group", id: "5" },
]

export interface IDptObjResponse {
  data: null
}
export interface IObjectiveOwner {
  personId: number
  personTypeId: null
  managerId: number
  employmentStatusId: null
  officeAddressId: null
  employeeTypeId: null
  employeeTitleId: null
  paygradeId: null
  departmentId: null
  identificationNumber: null
  hireDate: Date
  firstName: string
  middleInitial: null
  lastName: string
  fullName: string
  nickName: string
  genderId: null
  dateOfBirth: null
  email: string
  recoveryEmail: null
  mobilePhone: null
  nin: null
  tin: null
  nhfEpn: null
  paymentMethodId: null
  personStatusId: number
  customFields: null
  displayPhoneNumber: null
  welcomeNote: null
  sendEnrollmentReminder: null
  sendDigitalCard: null
  setupPercentage: null
  setupMessage: null
  offerStatusId: null
  isInvited: null
  isOnboarded: null
  isOnboardingCancelled: null
  isNew: null
  offerLastSentDate: null
  isProfilePhotoUploaded: null
  createdDate: null
  recordGuid: string
  isDeleted: boolean
  isActive: boolean
  reports: number
  peers: number
  isOnProbation: null
  contractMonths: null
  contractExpiryDate: null
  exitDate: null
  birthdayCardLastSentDate: null
  anniversaryCardLastSentDate: null
  welcomeCardSent: null
  ewaId: null
  ewaEnabled: null
  companyId: null
}
export interface IKeyResultPersonResponse {
  personId: number
  companyId: null
  personTypeId: number
  managerId: number
  reports: number
  peers: null
  employmentStatusId: number
  officeAddressId: null
  employeeTypeId: number
  employeeTitleId: number
  paygradeId: number
  departmentId: number
  identificationNumber: null
  hireDate: Date
  exitDate: null
  firstName: string
  middleInitial: null
  lastName: string
  fullName: string
  nickName: string
  genderId: number
  dateOfBirth: Date
  email: string
  recoveryEmail: null
  mobilePhone: string
  nin: null
  tin: null
  nhfEpn: null
  paymentMethodId: number
  personStatusId: number
  customFields: null
  displayPhoneNumber: boolean
  isProfilePhotoUploaded: null
  isOnProbation: null
  offerLastSentDate: null
  birthdayCardLastSentDate: null
  anniversaryCardLastSentDate: null
  welcomeCardSent: null
  ewaId: null
  ewaEnabled: null
  uniqueIdentifier: null
  createdDate: Date
  recordGuid: string
  isDeleted: boolean
  isActive: boolean
  managerFirstName: string
  managerMiddleInitial: null
  managerLastName: string
  managerFullName: string
  managerNickName: string
  managerEmail: string
  managerMobilePhone: string
  personType: string
  employmentStatus: string
  employmentStatusAlias: string
  officeFullAddress: null
  officeDisplayName: null
  contractMonths: null
  contractExpiryDate: null
  employeeType: string
  employeeTitle: string
  paygrade: string
  paygradeAlias: string
  annualGross: number
  qualifiedAnnualGross: null
  currencyCode: string
  currencySymbol: string
  department: string
  paymentMethod: string
  personStatus: string
  gender: string
}
export interface IGetDptObjParams extends IFilterOptions {
  period_id: string
  class_id: string
  department_id: string
  owner_id: string
}
export interface IGetKeyResultParams extends IFilterOptions {
  objective_id: string
}
export interface IGetKeyResultDetailedParams extends IFilterOptions {
  period_id: string
  key_result_id: string
}
export interface IGetKeyItemDetailedParams {
  key_result_id: string
}
export interface IGetKeyResultPersonParams {
  objective_class_id: string
  person_id: string
  department_id: string
}
export interface IaddKeyActivity {
  id?: string
  action?: string
  start_date?: string
  due_date?: string
  comment?: string
  statusId?: string
  progress?: string
  file_upload?: string
}
export interface IaddDptObj {
  objective_type_id: string
  objective_class_id: string
  objective_group_id: string
  department_id: string
  owner_id: string
  goal_id: string
  description: string
  start_date: string
  due_date: string
}
export interface IaddDptObjResponse {
  objective_type_id: string
  objective_class_id: string
  department_id: string
  owner_id: string
  goal_id: string
  description: string
  start_date: string
  due_date: string
}

export interface KeyResultResponseDetailed {
  employee_objective_id: string
  employee_objective_key: string
  period_id: string
  period: string
  goal_id: string
  goal: string
  objective_id: string
  objective: string
  objective_class_id: string
  objective_class: string
  objective_type_id: string
  objective_type: string
  objective_department_id: string
  objective_department: string
  key_result_id: string
  key_result: string
  employee_objective_status_id: string
  employee_objective_status: string
  employee_objective_state_id: string
  employee_objective_state: string
  target: string
  progress: string
  summary: string
  score: string
  measurement_unit_id: string
  measurement_unit: string
  currency_symbol: string
  start_date: string
  due_date: string
  owner_key: null
  owner_id: string
  owner_email: null
  person_key: string
  first_name: string
  middle_name: null
  last_name: string
  is_current_user: string
  avatar_url: string
  is_locked: string
  is_closed: string
}
export interface KeyItemsResponseDetailed {
  key_result_item_key: null
  key_result_item_id: number
  key_result_id: number
  description: string
  start_date: string
  due_date: string
  start_date_display: string
  due_date_display: string
  key_result_item_status_id: number
  key_result_item_status: string
  key_result_item_state_id: number
  key_result_item_state: string
  key_result_item_type_id: number
  key_result_item_type: string
  employee_objective_status_id: string
  created_by: string
  created_by_id: number
  created_by_name: string
  created_date: string
  is_deleted: boolean
  is_active: boolean
}
export interface KeyResult {
  key_result_key: string
  key_result_id: number
  objective_id: number
  objective: string
  objective_alias: string
  description: string
  alias: string
  start_date: string
  due_date: string
  start_date_display: string
  due_date_display: string
  target: number
  progress: number
  summary: number
  score: number
  key_result_status_id: number
  key_result_status: string
  key_result_state_id: number
  key_result_state: string
  measurement_unit_id: number
  measurement_unit: string
  currency_code: string
  currency_symbol: string
  qualified_currency: null
  is_locked: boolean
  is_closed: boolean
  is_deleted: boolean
  is_active: boolean
  items: any[]
  employeeObjectives: null
  persons: null
}
export interface IAddKeyResultParams {
  objective_id: string
  description: string
  start_date: string
  due_date: string
  measurement_unit_id: string
  currency_code: string
  is_locked: string
  target: string
  person_id: string
  person_target: string
  person_start_date: string
  person_due_date: string
  person_is_locked: string
}

interface PeriodMethods {
  getDptObjectives: (params: IGetDptObjParams) => Promise<any>
  getKeyResults: (params: IGetKeyResultParams) => Promise<any>
  createDptObj: (params: IaddDptObj) => Promise<any>
  createKeyTask: (params: IaddKeyActivity) => Promise<any>
  createKeyComment: (params: IaddKeyActivity) => Promise<any>
  getKeyResultDetailed: (
    params: IGetKeyResultDetailedParams
  ) => Promise<KeyResultResponseDetailed[]>
  getKeyItemDetailed: (
    params: IGetKeyItemDetailedParams
  ) => Promise<KeyItemsResponseDetailed[]>
  getObjectivesOwner: (params: IFilterPage) => Promise<IObjectiveOwner[]>
  getObjectivesOwnerDpt: (params: any) => Promise<any[]>
  getKeyResultPerson: (
    params: IGetKeyResultPersonParams
  ) => Promise<IKeyResultPersonResponse[]>
  createKeyResult: (params: any[]) => Promise<any>
  createKeyMeasurement: (id: string, params: any[]) => Promise<any>
  updateKeyIntiative: (params: IaddKeyActivity) => Promise<any>
  updateKeyItemStatus: (params: IaddKeyActivity) => Promise<any>
}

export const useObjectivesEmployeeStore = create<
  ObjectivesState & PeriodMethods
>()(
  devtools(
    persist(
      (set, get) => ({
        isLoadingGetDptObj: false,
        dptObj: [],
        isLoadingGetKeyResult: false,
        isLoadingCreateDptObj: false,
        isLoadingCreateKeyActivity: false,
        isLoadingCreateKeyTasks: false,
        isLoadingGetKeyResultDetailed: false,
        isLoadingCreateKeyResult: false,
        isLoadingCreateKeyMeasurement: false,
        isLoadingUpdateKeyIntiativeStatus: false,
        isLoadingDptMembers: false,
        keyResult: [],
        objectiveOwners: [],
        keyResultPerson: [],
        getDptObjectives: async (params) => {
          set({ isLoadingGetDptObj: true })
          const req = await request.get({
            url: "employee/performance/selectobjectives",
            params: new URLSearchParams({
              period_id: String(params.period_id),
            }),

            body: {
              length: String(params.length) || "",
              start: String(params.start) || "",
              search: String(params.search) || "",
            },
          })
          set({
            isLoadingGetDptObj: false,
          })
          return req
        },
        getObjectivesOwner: async (params: IFilterPage) => {
          const req = await request.post({
            url: "admin/performance/getownersforobjective",
            body: {
              q: String(params.q) || "",
              page: String(params.page) || "",
            },
          })
          set({
            objectiveOwners: req,
          })
          return req
        },
        getObjectivesOwnerDpt: async (params) => {
          set({ isLoadingDptMembers: true })
          const req = await request.post({
            url: "admin/performance/getownersfordepartmentalobjective",
            params: new URLSearchParams({
              id: String(params.id),
              q: "",
              page: "",
            }),
            body: {
              q: "",
              page: "",
            },
          })
          set({ isLoadingDptMembers: false })

          return req
        },
        getKeyResultPerson: async (params: IGetKeyResultPersonParams) => {
          const req = await request.get({
            url: "employee/performance/getpersonsforkeyresult",
            params: new URLSearchParams({
              objective_class_id: String(params.objective_class_id),
              person_id: String(params.person_id),
              department_id: String(params.department_id),
            }),
          })
          set({
            keyResultPerson: req.data,
          })
          return req.data
        },
        getKeyResults: async (params: IGetKeyResultParams) => {
          set({ isLoadingGetKeyResult: true })
          const req = await request.get({
            url: "employee/performance/selectkeyresults",
            params: new URLSearchParams({
              objective_id: String(params.objective_id),
            }),

            body: {
              length: String(params.length) || "",
              start: String(params.start) || "",
              search: String(params.search) || "",
            },
          })
          set({
            isLoadingGetKeyResult: false,
          })
          return req
        },
        createDptObj: async (params: IaddDptObj) => {
          set({ isLoadingCreateDptObj: true })
          const req = await request.post({
            url: "admin/performance/add_objective",
            body: {
              objective_type_id: String(params.objective_type_id) || "",
              objective_class_id: String(params.objective_class_id) || "",
              objective_group_id: String(params.objective_group_id) || "",
              department_id: String(params.department_id) || "",
              owner_id: String(params.owner_id) || "",
              goal_id: String(params.goal_id) || "",
              description: String(params.description) || "",
              start_date: String(params.start_date) || "",
              due_date: String(params.due_date) || "",
            },
          })
          set({
            isLoadingCreateDptObj: false,
          })
          return req
        },
        createKeyTask: async (params: IaddKeyActivity) => {
          set({ isLoadingCreateKeyTasks: true })
          const req = await request.post({
            url: "admin/performance/add_key_result_action",
            params: new URLSearchParams({
              id: String(params.id) || "",
              action: String(params.action) || "",
              start_date: String(params.start_date) || "",
              due_date: String(params.due_date) || "",
            }),
          })
          set({
            isLoadingCreateKeyTasks: false,
          })
          return req
        },
        createKeyComment: async (params: IaddKeyActivity) => {
          set({ isLoadingCreateKeyActivity: true })
          const req = await request.post({
            url: "admin/performance/add_key_result_comment",
            params: new URLSearchParams({
              id: String(params.id) || "",
              comment: String(params.comment) || "",
            }),
          })
          set({
            isLoadingCreateKeyActivity: false,
          })
          return req
        },
        getKeyResultDetailed: async (params: IGetKeyResultDetailedParams) => {
          set({ isLoadingGetKeyResultDetailed: true })
          const req = await request.get({
            url: "employee/performance/selectemployeeobjectives",
            params: new URLSearchParams({
              period_id: String(params.period_id),
              key_result_id: String(params.key_result_id),
            }),
            body: {
              length: String(params.length) || "",
              start: String(params.start) || "",
              search: String(params.search) || "",
            },
          })
          set({
            isLoadingGetKeyResultDetailed: false,
          })
          return req.data
        },
        getKeyItemDetailed: async (params: IGetKeyItemDetailedParams) => {
          // set({ isLoadingGetKeyResultDetailed: true })
          const req = await request.get({
            url: "admin/performance/getkeyresultitems",
            params: new URLSearchParams({
              key_result_id: String(params.key_result_id),
            }),
          })
          //   set({
          //  isLoadingGetKeyResultDetailed: false,

          //   })
          return req
        },
        createKeyResult: async (params) => {
          set({ isLoadingCreateKeyResult: true })
          const req = await request.post({
            url: "admin/performance/add_key_result",
            body: params,
          })
          set({
            isLoadingCreateKeyResult: false,
          })
          return req
        },
        createKeyMeasurement: async (id, params) => {
          set({ isLoadingCreateKeyMeasurement: true })
          const req = await request.post({
            url: "admin/performance/edit_key_result_assignment",
            params: new URLSearchParams({
              id: String(id) || "",
            }),
            body: params,
          })
          set({
            isLoadingCreateKeyMeasurement: false,
          })
          return req
        },
        updateKeyIntiative: async (params: IaddKeyActivity) => {
          set({ isLoadingUpdateKeyIntiativeStatus: true })
          const req = await request.post({
            url: "employee/performance/update_employeeobjective_progress",

            headers: { "Content-Type": "multipart/form-data" },
            body: {
              id: String(params.id) || "",
              progress: String(params.progress) || "",
              comment: String(params.comment) || "",
              file_upload: params.file_upload || "",
            },
          })
          set({
            isLoadingUpdateKeyIntiativeStatus: false,
          })
          return req
        },
        updateKeyItemStatus: async (params: IaddKeyActivity) => {
          set({ isLoadingUpdateKeyIntiativeStatus: true })
          const req = await request.post({
            url: "admin/performance/update_key_result_action",
            params: new URLSearchParams({
              id: String(params.id) || "",
              statusId: String(params.statusId) || "",
            }),
          })
          set({
            isLoadingUpdateKeyIntiativeStatus: false,
          })
          return req
        },
      }),
      {
        name: `${APP_ID}.task`,
      }
    )
  )
)
