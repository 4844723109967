interface FormData {
  [key: string]: string | number | boolean
}

interface GroupedData {
  accountNumber?: string
  addBeneficiary?: boolean
  amount?: string
  bank?: string
  usebeneficiary?: boolean
  description?: string
}

export const groupFormDataByIndex = (data: FormData): GroupedData[] => {
  const groupedData: { [key: string]: GroupedData } = {}
  let description = ""

  for (const key in data) {
    const match = key.match(/~(\d+)$/)
    if (match) {
      const index = match[1]
      const fieldName = key.replace(`~${index}`, "")

      if (!groupedData[index]) {
        groupedData[index] = {}
      }

      ;(groupedData[index] as any)[fieldName] = data[key]
    } else if (key === "description") {
      description = data[key] as string
    }
  }

  // Add description to all grouped items
  const result = Object.values(groupedData).map((item) => ({
    ...item,
    description,
  }))

  return result
}
