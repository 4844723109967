import { LoadingIndicator } from "components/custom/Loader"
import { LoadingOutlined } from "@ant-design/icons"
import { Spin } from "antd"

export const StatsCard = ({
  image,
  title,
  text,
  loading = false,
  handleClick,
  id,
}: {
  image: string
  title: string
  text: string | number | undefined
  loading?: boolean
  handleClick?: Function
  id?: string
}) => {
  const antIcon = (
    <LoadingOutlined
      style={{ fontSize: 24 }}
      spin
    />
  )
  return (
    <Spin
      spinning={loading}
      indicator={antIcon}
    >
      <div
        id={id}
        className="font-avenir flex items-center justify-start bg-white border border-[#e5e7eb] rounded-lg py-4"
        onClick={() => {
          handleClick ? handleClick() : () => {}
        }}
      >
        <>
          <div className="flex-shrink-0 mx-3">
            <img
              src={image}
              alt="Amount"
            />
          </div>
          <div>
            <p className="font-avenir text-sm text-neutral500">{title}</p>
            <p className="font-bold text-neutral500 text-lg">{text}</p>
          </div>
        </>
      </div>
    </Spin>
  )
}

export const InfoCard = ({
  title,
  description,
  actions,
  children,
  loading,
}: {
  title: string
  description?: string
  actions?: React.ReactNode
  children: React.ReactNode
  loading: boolean
}) => {
  return (
    <div className="bg-white border border-white shadow-md rounded-xl">
      <div className="py-5 px-6 flex items-center justify-between gap-4">
        <div>
          <h2 className="text-neutral500 font-circular font-bold">{title}</h2>
          <p className="text-dark40 text-sm">{description}</p>
        </div>
        <div>{actions}</div>
      </div>
      <hr className="border-b border-neutral40" />
      <div>
        {loading ? (
          <div className="my-5 py-5 flex items-center justify-center">
            <LoadingIndicator />
          </div>
        ) : (
          <>{children}</>
        )}
      </div>
    </div>
  )
}
