import React, { useState } from "react"
import { LinkButton } from "components"
import print from "../../../assets/svg/print.svg"
import download from "../../../assets/svg/download.svg"

import { formatCurrencyNoSymbol } from "utils/currency"
import { EllipsisHorizontalIcon } from "@heroicons/react/24/outline"

import { useGet } from "utils/useFetch"
import { newBaseUrl } from "utils/newbaseurl"
import { formatDateReadable, formatTimeReadble } from "utils/formatDate"
import { Alert, Dropdown, Spin, Table } from "antd"
import { useNavigate } from "react-router-dom"
import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"

const Invoice: React.FC<{}> = () => {
  const navigate = useNavigate()
  const { isLoading: isInvoicesLoading, data: invoiceData } = useGet<any>({
    url: `${newBaseUrl}/v1/employer/invoices`,
    external: true,
  })
  const TableData =
    invoiceData?.data?.docs?.map((transaction: any, index: any) => {
      return {
        key: index,
        date: {
          date: formatDateReadable(String(transaction?.createdAt)),
          time: formatTimeReadble(String(transaction?.createdAt)),
        },

        amount: `₦ ${formatCurrencyNoSymbol(Number(transaction.amount))}`,
        paid: `₦ ${formatCurrencyNoSymbol(Number(transaction.amount_paid))}`,
        status: {
          id: transaction.status,
          title: transaction.status,
        },
        action: {
          id: transaction._id,
        },
      }
    }) || []
  const TableColumns =
    [
      {
        title: "Date",
        dataIndex: "date",
        render: (item: { date: string; time: string }) => (
          <div className="whitespace-nowrap flex flex-col ">
            <span className={` font-avenir text-sm text-[#42526D]`}>
              {item.date},
            </span>
            <span className={` font-avenir text-xs text-neutral300`}>
              {item.time}
            </span>
          </div>
        ),
      },

      {
        title: "Amount",
        dataIndex: "amount",
        render: (amount: string) => (
          <span
            className={`whitespace-nowrap  font-avenir text-sm text-[#42526D] `}
          >
            {amount}{" "}
          </span>
        ),
      },

      {
        title: "Status",
        dataIndex: "status",
        render: (status: { id: string; title: any; method: string }) => (
          <span
            className={`text-sm font-medium py-1 block text-center w-[80px] rounded-[4px]

                    ${
                      status?.title == "PAID"
                        ? "bg-success100 text-success800"
                        : ""
                    }

                    ${status?.title == "UNPAID" ? "bg-neutral40 " : ""}

                    `}
          >
            {status?.title == "PAID" ? "Paid" : "Pending"}
          </span>
        ),
      },
      {
        title: " ",
        dataIndex: "action",
        render: (action: { id: string }) => (
          // <div className={`items-center flex gap-[35px] `}>
          //   <img
          //     alt="print"
          //     src={print}
          //     className="h-8 w-8 cursor-pointer"
          //   />
          //   <img
          //     alt="download"
          //     src={download}
          //     className="h-8 cursor-pointer w-8"
          //   />
          // </div>
          <Dropdown
            menu={{
              items: actionItems(action),
            }}
          >
            <button>
              <EllipsisHorizontalIcon className="w-6 h-6 text-[#004AF5]" />
            </button>
          </Dropdown>
        ),
      },
    ] || []
  const actionItems = (action: { id: string }) => {
    const { id } = action
    let arrayOptions: any = []
    {
      id &&
        (arrayOptions = [
          ...arrayOptions,
          {
            key: "1",
            label: (
              <span
                onClick={() => navigate(`/dashboard/ewa/invoice/view?x=${id}`)}
              >
                View
              </span>
            ),
          },
        ])
    }

    return arrayOptions
  }
  return (
    <DashboardWrapper>
      {isInvoicesLoading ? (
        <div className="flex justify-center w-full h-screen items-center">
          <Spin />
        </div>
      ) : invoiceData ? (
        <div className="flex flex-col gap-5">
          <h5 className="text-lg font-bold text-neutral700 ">Invoice</h5>

          <div
            className="bg-white rounded-5px"
            id="ewa-invoice-listing"
          >
            <Table
              columns={TableColumns}
              dataSource={TableData}
              loading={isInvoicesLoading}
              className="text-[#42526D]"
              pagination={{
                defaultPageSize: 20,
              }}
              scroll={{
                x: "100%",
              }}
            />
          </div>
        </div>
      ) : (
        <div className="w-full md:w-[80%]">
          <div className="p-4 md:p-5 md:pr-10 mb-6 bg-[#EBEDF0] ">
            <div className="flex  mb-4  items-center justify-between">
              <h5 className="text-neutral700 font-bold text-base">
                Earned wage access not turned on
              </h5>
              <LinkButton
                to="/dashboard/settings/hr-and-payroll/ewa"
                title="Next"
                color="primary"
                className="font-extrabold"
              >
                Update
              </LinkButton>
            </div>
            <p className="text-[#42526D] text-sm font-normal ">
              Earned wage access allows employees to access a portion of their
              earned wages before their regular payday. This service aims to
              provide greater financial flexibility and control for employees,
              especially for those who might face unexpected expenses or
              financial emergencies between pay periods.
            </p>
          </div>
          <Alert
            message="Turn on the earned wage access to enable your employees request for payment before regular pay-day. Go to settings to turn on"
            type="info"
            showIcon
            className=" bg-white py-1 sm:h-[64px] font-avenir text-neutral500 text-sm w-full flex justify-between rounded items-center "
          />
        </div>
      )}
    </DashboardWrapper>
  )
}
export default Invoice
