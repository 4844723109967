import { Form, Radio, Space } from "antd"
import React, { useState } from "react"
import { Button, DashboardContainer } from "components"
import PrevNextButton from "pages/dashboard/documents/custom-form/PrevNextButton"
import FormSectionWrapper from "../FormSectionWrapper"
import AntDesignSelect from "components/antd/AntDesignSelect"
import { usePost } from "utils/useFetch"
import { AntDesignInput } from "components/antd/AntDesignInput"
import TextArea from "antd/es/input/TextArea"
import { AntDesignRadio } from "components/antd/AntDesignRadio"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { useNavigate } from "react-router-dom"
import { useAppNotificationStore } from "stores"
import { LoadingIndicatorWhite } from "components/custom/LoaderWhite"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"

const NewCustomField: React.FC<{}> = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { toast } = useAppNotificationStore()
  const [form] = Form.useForm()
  const [errorMessage, setErrorMessage] = useState("")
  const [dropdownSelected, setDropdownSelected] = useState(false)
  const [customBody, setCustomBody] = useState({
    description: "",
    alias: "",
    custom_field_group_id: "",
    custom_field_group: "",
    response_type_id: "",
    response_options: "",
    is_admin: "",
  })
  const reqBody = {
    q: "",
    page: "",
  }
  const { isLoading: isGroupLoading, data: groups } = usePost<ICustomGroup[]>({
    url: `/admin/custom_fields/getgroups`,
    body: reqBody,
  })

  const { isLoading: isLoadingCheck, mutate: check } = useMutation({
    mutationFn: async (body: string) => {
      const res = await axiosInstance.get(
        `/admin/custom_fields/check?description=${body}`
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data.status !== "error") {
      } else {
        setErrorMessage("Custom field name is already taken")
        toast.error(`Custom field name is already taken`)
      }
    },
  })
  const invalidateQueries = () => {
    queryClient.invalidateQueries({
      queryKey: [`/admin/custom_fields/select`],
    })
  }

  const { isLoading: isLoading, mutate: submitCustom } = useMutation({
    mutationFn: async (body: any) => {
      const res = await axiosInstance.post(`/admin/custom_fields/add`, body)
      return res.data
    },
    onSuccess: (data) => {
      if (data) {
        mixPanelEvent("settings-customization-custom-fields-add-success")
        {
          data.msg && toast.success(data.msg)
        }
        navigate("/dashboard/settings/customization/custom-fields")
        invalidateQueries()
      } else {
        toast.error(data.msg)
      }
    },
  })
  const onFinish = (values: any) => {
    mixPanelEvent("settings-customization-custom-fields-add-init")
    setCustomBody({
      ...customBody,
      description: values.name,
      response_type_id: values?.how,
      is_admin: values?.who,
      custom_field_group: values?.group,
    })
    const body = {
      description: values.name,
      alias: customBody.alias,
      custom_field_group_id: customBody.custom_field_group_id,
      custom_field_group: values?.group,
      response_type_id: values?.how,
      response_options: customBody.response_options,
      is_admin: values?.who,
    }

    submitCustom(body)
  }
  return (
    <DashboardContainer>
      <FormSectionWrapper
        name="1.1 Create New Custom Field"
        title="1.1 Create New Custom Field"
        subtitle="Add a custom field to store any info you like on your employees’ profiles."
        isActive={true}
        layout="vertical"
        form={form}
        onFinish={onFinish}
        style={{ maxWidth: 1024 }}
        autoComplete="off"
        footerBtns={
          <div className="flex justify-end items-center gap-4">
            <Button
              color="secondary"
              type="button"
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              title="save"
              submitting={isLoading}
            >
              Save
            </Button>
          </div>
        }
      >
        <div className=" lg:grid lg:grid-cols-2 gap-x-10  px-4 md:px-6 xl:px-[60px]">
          <AntDesignSelect
            label="Group"
            name="group"
            required
            isLoading={isGroupLoading}
            onChange={(selected) => {
              const selectedOption = groups?.find(
                (option) => option.description === selected
              )
              {
                selectedOption &&
                  setCustomBody({
                    ...customBody,
                    custom_field_group_id: String(
                      selectedOption?.customFieldGroupId
                    ),
                  })
              }
            }}
            options={
              !groups
                ? []
                : groups?.map((bank) => ({
                    label: bank.description,
                    value: bank.description,
                  }))
            }
            placeholder="Please select"
          />
          <div>
            <AntDesignInput
              label="Name"
              name="name"
              required={true}
              placeholder="Ex. Genotype"
              onBlur={(e) => {
                check(e.target.value)
              }}
            />
          </div>
          <Form.Item
            required
            className="col-span-2"
            name="helperText"
            label={
              <span className="font-circular text-neutral300 text-sm ">
                Helper text
              </span>
            }
          >
            <TextArea
              rows={3}
              placeholder=""
              onChange={(e) => {
                setCustomBody({
                  ...customBody,
                  alias: String(e.target.value),
                })
              }}
            />
          </Form.Item>

          <AntDesignRadio
            label="Who should fill out this field?"
            required
            name="who"
          >
            <Space direction="vertical">
              <Radio value="yes"> Admins </Radio>
              <Radio value="no"> Employees </Radio>
            </Space>
          </AntDesignRadio>
          <AntDesignRadio
            label="How should this field be filled?"
            required
            name="how"
          >
            <Space direction="vertical">
              <Radio
                value="1"
                onClick={() => setDropdownSelected(true)}
              >
                {" "}
                Dropdown{" "}
              </Radio>
              <Radio
                value="2"
                onClick={() => setDropdownSelected(false)}
              >
                {" "}
                Textbox{" "}
              </Radio>
              <Radio
                value="3"
                onClick={() => setDropdownSelected(false)}
              >
                {" "}
                File upload{" "}
              </Radio>
            </Space>
          </AntDesignRadio>

          {dropdownSelected && (
            <Form.Item
              required
              className="col-span-2"
              name="options"
              label={
                <span className="font-circular text-neutral300 text-sm ">
                  Options
                </span>
              }
            >
              <TextArea
                rows={3}
                placeholder="Separate options with a semicolon(;). Example: Yes;No"
                onChange={(e) => {
                  setCustomBody({
                    ...customBody,
                    response_options: String(e.target.value),
                  })
                }}
              />
            </Form.Item>
          )}
        </div>
      </FormSectionWrapper>
    </DashboardContainer>
  )
}
export default NewCustomField

export interface ICustomGroup {
  customFieldGroupId: number
  description: string
  createdDate: null
  createdBy: null
  updatedDate: null
  updatedBy: null
  recordGuid: null
  recordVersion: null
  isDeleted: boolean | null
  isActive: boolean | null
  createdByName: null
  updatedByName: null
  companyId: null
  company: null
  customFields: any[]
}
