import { Form, InputNumber } from "antd"
import { useState } from "react"
import { Button, P2 } from "components"
import { useGet } from "utils/useFetch"
import { newBaseUrl } from "utils/newbaseurl"
import { useMutation } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { LoadingIndicatorWhite } from "components/custom/LoaderWhite"
import { formatCurrencyNoSymbol } from "utils/currency"
import { useNavigate } from "react-router-dom"
import { useAppNotificationStore } from "stores"
import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"
import FormSectionWrapper from "pages/employee-dashboard/FormSectionWrapper"
import { CustomInput, CustomSelect } from "pages/employee-dashboard/form"
import PrevNextButton from "pages/employee-dashboard/form/PrevNextButton"
import { IWithdrawalReasonsData } from "../interface/withdrawal"
import { IEarningsWalletData } from "../interface/wallet"
import { divideEarningsByPercent } from "./EarnedWageAccess"
import EnterEwaPin from "./modals/EnterPin"

const Withdrawal = () => {
  const navigate = useNavigate()
  const { toast } = useAppNotificationStore()
  const [form] = Form.useForm()
  const [showPassword, setShowPassword] = useState(false)
  const [fee, setFee] = useState(0)
  const [otherReasonTrue, setOtherReasonTrue] = useState(false)
  const [withdrawalData, setWithdrawalData] = useState<IBody>({
    amount: "",
    reasonId: "",
    transactionPin: "",
    reasonText: "",
    channel: "WEB",
  })

  const onFinish = (values: any) => {
    setShowPassword(true)
  }
  const { isLoading: isWithdrawalsLoading, data: withdrawalsReasons } =
    useGet<IWithdrawalReasonsData>({
      url: `${newBaseUrl}/v1/withdrawal-reasons`,
      external: true,
    })
  const { isLoading: isWithdrawalInfoLoading, data: withdrawalsInfo } =
    useGet<any>({
      url: `${newBaseUrl}/v1/misc/settings`,
      external: true,
    })
  const {
    isLoading: isEarningWalletLoading,
    data: earningWalletData,
    refetch,
  } = useGet<IEarningsWalletData>({
    url: `${newBaseUrl}/v1/earnings/wallet`,
    external: true,
  })
  const invalidateQueries = () => {
    refetch()
  }

  const { isLoading: isLoadingCheck, mutate: checkAmount } = useMutation({
    mutationFn: async (body: any) => {
      const res = await axiosInstance.get(
        `${newBaseUrl}/v1/fees/calculate?amount=${withdrawalData?.amount}`
      )

      return res
    },
    onSuccess: (data: any) => {
      setFee(data?.data?.data.fee)
      if (data) {
      } else {
        {
          data.msg && toast.error(data.msg)
        }
      }
    },
    onError: (data: any) => {},
  })
  const { isLoading: isProcessingWithdrawal, mutate: processWithdrawal } =
    useMutation({
      mutationFn: async (body: IBody) => {
        const res: any = await axiosInstance.post(
          `${newBaseUrl}/v1/earnings/withdraw`,
          body
        )
        return res.data
      },
      onSuccess: (data) => {
        if (data.success) {
          {
            data.message && toast.success(data.message)
          }
          mixPanelEvent("empl-wallet-withdraw-success")
          navigate("/employee/dashboard/wallet/earned-wage-access/overview")
        } else {
          {
            data.message && toast.error(data.message)
          }
          setShowPassword(false)
        }
      },
      onError: (data: any) => {
        data?.response?.data?.message &&
          toast.error(data?.response?.data?.message)
      },
    })

  const handleWithdraw = (pin: string) => {
    mixPanelEvent("empl-wallet-withdraw-init")
    processWithdrawal({
      ...withdrawalData,
      amount: Number(withdrawalData.amount),
      transactionPin: pin,
    })
  }

  const handleCheckValue = (value: number): boolean => {
    const minimumWithdrawalAmount =
      withdrawalsInfo?.data?.minimumWithdrawalAmount
    const maximumWithdrawalAmount =
      withdrawalsInfo?.data?.maximumWithdrawalAmount
    return value >= minimumWithdrawalAmount && value <= maximumWithdrawalAmount
  }

  return (
    <DashboardWrapper>
      <>
        {isEarningWalletLoading ? (
          <div className=" z-50 flex justify-center items-center right-0 top-0 left-0 bottom-0 py-10">
            <LoadingIndicatorWhite />
          </div>
        ) : (
          <div className="flex  w-full">
            {showPassword ? (
              <EnterEwaPin
                title={`Enter Transaction Pin  to confirm the withdrawal of ₦${formatCurrencyNoSymbol(
                  Number(withdrawalData?.amount)
                )}`}
                onClose={() => setShowPassword(false)}
                open={showPassword}
                loading={isProcessingWithdrawal}
                handleSubmit={handleWithdraw}
              />
            ) : (
              <div className=" w-full sm:w-[850px]">
                <FormSectionWrapper
                  name="Withdraw"
                  title="Withdraw"
                  // subtitle=" Withdrawn amount will be deposited into your bank account"
                  subtitle={`Your available balance is N
                          ${formatCurrencyNoSymbol(
                            Number(
                              earningWalletData?.data?.availableBalance || 0
                            )
                          )}`}
                  isActive={true}
                  initialValues={{
                    bank: earningWalletData?.data?.employee?.bankName || "",
                    accountNumber:
                      earningWalletData?.data?.employee?.bankAccountNumber ||
                      "",
                  }}
                  layout="vertical"
                  form={form}
                  onFinish={onFinish}
                  style={{ maxWidth: 1024 }}
                  autoComplete="off"
                  footerBtns={
                    <div className="flex gap-2 items-end justify-end">
                      <Button
                        type="button"
                        onClick={() => navigate(-1)}
                        color="secondary"
                      >
                        Cancel
                      </Button>
                      <PrevNextButton showPrevButton={false} />
                    </div>
                  }
                >
                  <div className="flex flex-col flex-wrap gap-10 px-4 md:px-6 xl:px-[60px]">
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-10 w-full">
                      <CustomInput
                        label="Bank"
                        name="bank"
                        // required
                        disabled={true}
                      />
                      <CustomInput
                        label="Account number"
                        name="accountNumber"
                        // required
                        disabled={true}
                      />

                      <Form.Item
                        className="!mb-0"
                        name="amount"
                        label={
                          <span className="font-circular text-neutral300 text-sm">
                            Amount
                          </span>
                        }
                        rules={[
                          {
                            required: true,
                            message:
                              "Please input the amount you want to withdraw",
                          },
                          {
                            validator: (_, value) => {
                              const minimumAmount =
                                withdrawalsInfo?.data?.minimumWithdrawalAmount
                              if (!value || value >= minimumAmount) {
                                return Promise.resolve()
                              }
                              return Promise.reject(
                                new Error(
                                  `Amount must be at least ₦${minimumAmount}`
                                )
                              )
                            },
                          },
                          () => ({
                            validator: (_, value) => {
                              if (
                                value &&
                                Number(value) >
                                  Number(
                                    earningWalletData?.data?.availableBalance ||
                                      0
                                  )
                              ) {
                                return Promise.reject(
                                  "Your withdrawal amount is more than your balance"
                                )
                              }
                              return Promise.resolve()
                            },
                          }),
                        ]}
                        extra={
                          <P2 className="text-padeSubtext mt-1 min-h-[14px]">
                            {withdrawalData?.amount && fee > 0 && (
                              <p className="text-center text-subText3">
                                You will be charged NGN
                                {formatCurrencyNoSymbol(Number(fee))} for this
                                transaction
                              </p>
                            )}
                          </P2>
                        }
                      >
                        <InputNumber<number>
                          formatter={(value) =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          parser={(value) =>
                            value?.replace(/,/g, "") as unknown as number
                          }
                          className="w-full"
                          placeholder="e.g 500"
                          onChange={(e: any) => {
                            setWithdrawalData({
                              ...withdrawalData,
                              amount: e,
                            })
                          }}
                          onBlur={(e: any) => {
                            const inputValue = parseFloat(e)
                            if (isNaN(inputValue)) {
                            } else {
                              const isValid = handleCheckValue(inputValue)
                              if (isValid) {
                                checkAmount(e)
                              } else {
                                toast.error(
                                  `Withdrawal amount is not valid, minimum withdrawal is N${formatCurrencyNoSymbol(
                                    Number(
                                      withdrawalsInfo?.data
                                        ?.minimumWithdrawalAmount
                                    )
                                  )} and maximum withdrawal is N${formatCurrencyNoSymbol(
                                    Number(
                                      divideEarningsByPercent(
                                        earningWalletData?.data
                                          ?.totalEarnings || 0,
                                        50
                                      )
                                    )
                                  )} `
                                )
                              }
                            }
                          }}
                        />
                      </Form.Item>

                      <CustomSelect
                        label="Purpose"
                        name="purpose"
                        required
                        onChange={(selected) => {
                          let owner = selected.split("-")
                          let name = owner[0]
                          owner = owner[1]
                          setWithdrawalData({
                            ...withdrawalData,
                            reasonId: owner,
                          })
                          if (name == "others") {
                            setOtherReasonTrue(true)
                          } else {
                            setOtherReasonTrue(false)
                          }
                        }}
                        isLoading={isWithdrawalsLoading}
                        options={
                          isWithdrawalsLoading
                            ? []
                            : withdrawalsReasons?.data?.docs?.map((state) => ({
                                label: state.name,
                                value: `${state.name}-${state._id}`,
                              }))
                        }
                        placeholder="Please select"
                      />
                      {otherReasonTrue && (
                        <CustomInput
                          label="Reason"
                          name="reason"
                          required={otherReasonTrue ? true : false}
                          onChange={(e) => {
                            setWithdrawalData({
                              ...withdrawalData,
                              reasonText: e.target.value,
                            })
                          }}
                        />
                      )}
                    </div>
                    {withdrawalData?.amount && fee > 0 && (
                      <p className="text-center text-subText3">
                        You will be charged NGN
                        {formatCurrencyNoSymbol(Number(fee))} for this
                        transaction
                      </p>
                    )}
                  </div>
                </FormSectionWrapper>
              </div>
            )}
          </div>
        )}
      </>
    </DashboardWrapper>
  )
}

export default Withdrawal
interface IBody {
  amount: string | number
  reasonId: string
  transactionPin: string
  channel: string
  reasonText: string
}
