import RequireRoutePermit from "RequireRoutePermit"
import Statements from "pages/dashboard/wallets/Statements"
import TransactionsNew from "pages/dashboard/wallets/TransactionsNew"
import CompanyVerification from "pages/dashboard/wallets/integration/CompanyVerification"
import Integration from "pages/dashboard/wallets/integration/Integration"
import React from "react"
import { Route } from "react-router-dom"

const Wallet = React.lazy(() => import("../pages/dashboard/wallets/Wallets"))
const DirectDebit = React.lazy(
  () => import("../pages/dashboard/wallets/DirectDebit")
)

const Transactions = React.lazy(
  () => import("../pages/dashboard/wallets/Transactions")
)

const TransactionHistory = React.lazy(
  () => import("../pages/dashboard/wallets/ViewTransactionHistory")
)

const WalletRoutes = () => {
  return (
    <>
      <Route
        path="overview"
        element={
          <RequireRoutePermit allowedPermissions={["can_view_wallet_overview"]}>
            <Wallet />
          </RequireRoutePermit>
        }
      />

      <Route
        path="direct-debit"
        element={
          // <RequireRoutePermit allowedPermissions={["can_view_wallet_overview"]}>
          <DirectDebit />
          // </RequireRoutePermit>
        }
      />

      <Route
        path="transactions"
        element={
          <RequireRoutePermit
            allowedPermissions={["can_view_wallet_transactions"]}
          >
            <Transactions />
          </RequireRoutePermit>
        }
      />
      <Route
        path="transactions-new"
        element={
          <RequireRoutePermit
            allowedPermissions={["can_view_wallet_transactions"]}
          >
            <TransactionsNew />
          </RequireRoutePermit>
        }
      />
      <Route
        path="integration"
        element={
          <RequireRoutePermit
            allowedPermissions={["can_view_wallet_transactions"]}
          >
            <Integration />
          </RequireRoutePermit>
        }
      />
      <Route
        path="statements"
        element={
          <RequireRoutePermit
            allowedPermissions={["can_view_wallet_transactions"]}
          >
            <Statements />
          </RequireRoutePermit>
        }
      />
      <Route
        path="integration/company-verification"
        element={
          <RequireRoutePermit
            allowedPermissions={["can_view_wallet_transactions"]}
          >
            <CompanyVerification />
          </RequireRoutePermit>
        }
      />
      <Route
        path="transaction-history"
        element={
          <RequireRoutePermit
            allowedPermissions={["can_view_wallet_transactions"]}
          >
            <TransactionHistory />
          </RequireRoutePermit>
        }
      />
    </>
  )
}

export default WalletRoutes
