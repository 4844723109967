import {
  EllipsisHorizontalIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { Dropdown, Input, Table } from "antd"
import { Button, DashboardContainer } from "components"
import { LoadingIndicatorWhite } from "components/custom/LoaderWhite"
import ImageComponent from "components/custom/image"
import CustomModal from "components/new-stuff/ui/modals/CustomModal"
import { useMemo, useState } from "react"
import { Link } from "react-router-dom"
import { useAppNotificationStore } from "stores"
import { axiosInstance } from "utils/request"
import { usePost } from "utils/useFetch"

interface ExitRes {
  data: ExitData[]
}

interface ExitData {
  person_id: string
  person_key: string
  first_name: string
  middle_name: string
  last_name: string
  avatar_url: string
  department: string
  exit_type: string
  exit_date: string
}

const Exits = () => {
  const queryClient = useQueryClient()
  const { toast } = useAppNotificationStore()
  const [search, setSearch] = useState("")
  const [typeId, setTypeId] = useState("3")
  const [activeDeleteId, setActiveDeleteId] = useState("")
  const [activeRehireId, setActiveRehireId] = useState("")
  const [openRehireModal, setOpenRehireModal] = useState(false)
  const reqBody = {
    search: "",
    length: "10",
    start: "1",
  }
  const invalidateQueries = () => {
    refetch()
    queryClient.invalidateQueries({
      queryKey: [`admin/people/selectexits`, reqBody],
    })
  }

  const {
    isLoading: isLoadingSurvey,
    data: exitData,
    refetch,
  } = usePost<ExitRes>({
    url: `admin/people/selectexits`,
    body: reqBody,
  })

  const { isLoading: isLoadingRehire, mutate: submitRehire } = useMutation({
    mutationFn: async () => {
      const res = await axiosInstance.post(
        `/admin/employees/rehire?id=${activeRehireId}`
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data.status !== "error") {
        setOpenRehireModal(false)
        invalidateQueries()

        {
          data.msg && toast.success(data.msg)
        }
      } else {
        setOpenRehireModal(false)

        {
          data.msg && toast.error(data.msg)
        }
      }
    },
    onError: (data: any) => {
      setOpenRehireModal(false)
      {
        data.msg && toast.error(data.msg)
      }
    },
  })
  const TableData = useMemo(
    () =>
      exitData?.data
        ?.filter((item) => {
          if (!search) return true
          const fullname = `${item?.first_name} ${
            item?.middle_name ? item?.middle_name : ""
          } ${item?.last_name}`

          return fullname?.toLowerCase().includes(search.toLowerCase())
        })
        ?.map((exit: any) => ({
          key: exit?.person_id,
          employee: {
            name: `${exit?.first_name} ${
              exit?.middle_name && exit?.middle_name
            } ${exit?.last_name}`,
            img: exit?.avatar_url,
            fName: exit.first_name,
            lName: exit.last_name,
            mName: exit.middle_name,
          },
          department: exit?.department,
          exitType: {
            type: exit?.exit_type,
            date: exit?.exit_date,
          },
          action: {
            id: exit.person_id,
            key: exit.person_key,
          },
        })) || [],
    [exitData, search]
  )

  const TableColumns = [
    {
      title: "Employee",
      dataIndex: "employee",
      render: (employee: {
        name: string
        img: string
        fName: string
        lName: string
        mName: string
      }) => (
        <div className="flex items-center gap-2 text-[#42526D]font-avenir text-sm">
          <span className="shrink-0">
            <ImageComponent
              className="w-8 h-8 rounded-full"
              src={employee.img}
              alt=""
            />
          </span>

          {employee.fName ? (
            <span className="text-[#42526D] capitalize font-avenir text-sm">
              {employee.fName}
              {employee.mName ? ` ${employee.mName} ` : " "} {employee.lName}
            </span>
          ) : (
            "-"
          )}
        </div>
      ),
    },
    {
      title: "Department",
      dataIndex: "department",
      render: (department: string) => (
        <span
          className={`whitespace-nowrap  font-avenir text-sm text-[#42526D]
               `}
        >
          {department}
        </span>
      ),
    },
    {
      title: "Exit Type",
      dataIndex: "exitType",
      render: (exitType: { type: string; date: string }) => (
        <div className="flex flex-col ">
          <span
            className={`whitespace-nowrap  font-avenir text-sm text-[#42526D]
               `}
          >
            {exitType.type}
          </span>
          <span
            className={`  font-avenir text-xs  text-neutral300 font-thin
               `}
          >
            {exitType.date}
          </span>
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (data: { key: string; id: number }) => (
        <Dropdown
          menu={{
            items: actionItems(data),
          }}
        >
          <button>
            <EllipsisHorizontalIcon className="w-6 h-6 text-primary500" />
          </button>
        </Dropdown>
      ),
    },
  ]
  //  three dots on each row
  const actionItems = (data: { key: string; id: number }) => [
    {
      key: "1",
      label: (
        <button
          onClick={() => {
            setOpenRehireModal(true)
            setActiveRehireId(String(data?.id))
          }}
          className="w-full text-left text-neutral500"
        >
          Rehire
        </button>
      ),
    },
    {
      key: "2",
      label: (
        <Link
          className="w-full text-left text-neutral500"
          to={`/dashboard/employee/${data.key}/job-and-pay`}
        >
          View
        </Link>
      ),
    },
  ]

  return (
    <div className="relative !font-avenir text-[#42526D]">
      <DashboardContainer>
        <div className="flex flex-col h-full">
          <div className="bg-white  flex items-center justify-between gap-4 py-[10px] px-4">
            <div className="w-full  flex items-center gap-4">
              <div className="max-w-[200px]">
                <Input
                  placeholder="Search"
                  prefix={
                    <MagnifyingGlassIcon className="w-4 h-4 text-neutral-300" />
                  }
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value)
                  }}
                  className="font-avenir "
                />
              </div>
            </div>
          </div>
          <Table
            columns={TableColumns}
            dataSource={TableData}
            loading={isLoadingSurvey}
            className="text-[#42526D]"
            pagination={{
              defaultPageSize: 20,
            }}
            scroll={{
              x: "100%",
            }}
          />
        </div>
      </DashboardContainer>

      <CustomModal
        title="Rehire Employee"
        onCancel={() => {
          setOpenRehireModal(false)
        }}
        open={openRehireModal}
        width={500}
        footer={
          <div className="flex justify-end gap-4">
            <Button
              color="neutral"
              onClick={() => {
                setOpenRehireModal(false)
              }}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              className=""
              onClick={() => {
                submitRehire()
              }}
              disabled={isLoadingRehire}
              submitting={isLoadingRehire}
            >
              Confirm
            </Button>
          </div>
        }
      >
        <div className=" flex flex-col gap-4">
          <p className="text-[#15294B] text-sm mb-6 ">
            If you rehire this employee, they’ll be able to partake in all
            company activities and will be included in future payrolls. Click
            Confirm to continue.
          </p>
        </div>
      </CustomModal>
    </div>
  )
}
export default Exits

export interface ISurvey {
  survey_id: string
  survey_key: string
  survey_status_id: string
  survey_status: string
  survey_type_id: string
  survey_type: null
  description: string
  is_active: string
}
