import {
  ChevronDownIcon,
  EllipsisHorizontalIcon,
  InformationCircleIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline"
import {
  Button,
  DashboardContainer,
  TopActionBar,
  H6,
  ModalWrapper,
} from "../../../../components"
import { IGetAllPeople } from "stores/people/people"
import { Link, useNavigate } from "react-router-dom"
import { useGet, usePost } from "utils/useFetch"
import AntDCustomTable from "components/antd/AntDCustomTable"
import { Dropdown, Select, Input, Table, Form, Spin } from "antd"
import { useMemo, useState, useTransition } from "react"
import ImageComponent from "components/custom/image"
import { useMutation, useQuery } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"

import { PersonData } from "./interface/confirmation"
import { useAppNotificationStore } from "stores"

interface IDepartmentRes {
  id: number
  text: string
}
async function fetchProjects() {
  const { data } = await axiosInstance.get("/admin/people/select", {
    params: new URLSearchParams({
      type: "Employees",
      //departmentId: "",
      search: "",
      start: "1",
      length: "1000",
    }),
  })
  return data
}

const Confirmation: React.FC<{}> = () => {
  const navigate = useNavigate()
  const { toast } = useAppNotificationStore()
  const [, startTransition] = useTransition()
  const [showConfirmBtn, setShowConfirmBtn] = useState(false)
  const [search, setSearch] = useState("")
  const [departmentId, setDepartmentId] = useState("")
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false)
  const [selectedPersons, setSelectedPersons] = useState<any[]>([])
  const [activeConfirmEmp, setActiveConfirmEmp] = useState({
    fullName: "",
    person_id: "",
  })
  const {
    isLoading: isLoadingConfirmation,
    data: confirmationData,
    refetch,
  } = useGet<PersonData>({
    url: `/admin/people/selectforconfirmation`,
  })

  const departmentPostBody = {
    q: "",
    page: "",
  }
  const { isLoading: isLoadingConfirmAll, mutate: confirmAll } = useMutation({
    mutationFn: async () => {
      const res = await axiosInstance.post(
        `/admin/employees/save_confirm_batch`
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data.status !== "error") {
        setOpenConfirmationModal(false)
        refetch()

        setTimeout(
          () => toast.success("Employees has been confirmed successfully"),
          100
        )
      } else {
        setOpenConfirmationModal(false)

        setTimeout(() => toast.error("Unable to confirm employees"), 100)
      }
    },
    onError: (data) => {
      setOpenConfirmationModal(false)

      setTimeout(() => toast.error("Unable to confirm employees"), 100)
    },
  })
  const { isLoading: isLoadingConfirmSelected, mutate: confirmSelected } =
    useMutation({
      mutationFn: async (body: any) => {
        const res = await axiosInstance.post(
          `/admin/employees/save_confirm`,
          body
        )
        return res.data
      },
      onSuccess: (data) => {
        if (data.status !== "error") {
          setOpenConfirmationModal(false)
          setShowConfirmBtn(false)
          refetch()
          setActiveConfirmEmp({
            fullName: "",
            person_id: "",
          })
          setTimeout(
            () => toast.success("Employees has been confirmed successfully"),
            100
          )
        } else {
          setOpenConfirmationModal(false)
          setShowConfirmBtn(false)
          setTimeout(() => toast.error("Unable to confirm employees"), 100)
          setActiveConfirmEmp({
            fullName: "",
            person_id: "",
          })
        }
      },
      onError: (data) => {
        setOpenConfirmationModal(false)
        setShowConfirmBtn(false)
        setTimeout(() => toast.error("Unable to confirm employees"), 100)
        setActiveConfirmEmp({
          fullName: "",
          person_id: "",
        })
      },
    })
  const { isLoading: isDepartmentDataLoading, data: departmentData } = usePost<
    IDepartmentRes[]
  >({
    url: "/admin/departments/getothers",
    body: departmentPostBody,
  })

  const filteredData = useMemo(() => {
    if (!confirmationData?.data) {
      return []
    }

    let newResult = []

    if (search) {
      const searchLowerCase = search.toLowerCase()
      newResult = confirmationData.data.filter((item) => {
        const idMatch = item.person_id?.toString().includes(searchLowerCase)
        const fullName = `${item.first_name} ${item.middle_name} ${item.last_name}`
        const emailMatch = item?.email?.toLowerCase().includes(searchLowerCase)
        const fullNameMatch = fullName.toLowerCase().includes(searchLowerCase)
        return emailMatch || fullNameMatch || idMatch
      })
    } else if (departmentId) {
      const department = departmentData?.find(
        (item) => item.id.toString() === departmentId
      )
      const departmentTextLowerCase = department?.text.toLowerCase()
      newResult = confirmationData.data.filter((item) => {
        const match =
          item?.department?.toLowerCase() === departmentTextLowerCase

        return match
      })
    } else {
      return confirmationData.data
    }

    return newResult
  }, [confirmationData?.data, search, departmentId])

  const TableData = useMemo(
    () =>
      filteredData?.map((person) => ({
        key: person.person_id,
        fullname: {
          name: ` ${person.first_name} ${person.last_name}`,
          fName: person.first_name,
          lName: person.last_name,
          mName: person.middle_name,
          avatar: person.avatar_url,
        },
        email: person.email,
        department: {
          department: person.department,
          role: person.employee_title,
        },
        confirmation: `${person.confirmation_days_late} days late`,
        // role: person.employee_title,
        status: {
          personstatus: person.person_status,
          personId: person.person_status_id,
        },
        action: {
          personKey: person.person_key,
          personId: person.person_id,
          name: ` ${person.first_name} ${person.last_name}`,
        },
        phone: person.mobile_phone,
      })) || [],
    [filteredData]
  )

  const TableColumns = useMemo(
    () =>
      [
        {
          title: "Name",
          dataIndex: "fullname",
          render: (item: {
            name: string
            avatar: string
            fName: string
            lName: string
            mName: string
          }) => (
            <div className="flex items-center gap-2">
              <span className="shrink-0">
                <ImageComponent
                  className="w-7 h-7 rounded-full shrink-0"
                  src={item.avatar}
                  alt=""
                />
              </span>
              <span
              // className="text-[#42526D] font-medium"
              >
                <span className="uppercase font-semibold text-neutral500">
                  {item.fName}
                </span>{" "}
                {item.mName ? ` ${item.mName} ` : " "} {item.lName}
              </span>
            </div>
          ),
        },
        {
          title: "Email Address",
          dataIndex: "email",
        },
        {
          title: "Department",
          dataIndex: "department",
          render: (item: { department: string; role: string }) => (
            <div className="flex flex-col gap-2">
              <span>{item.department}</span>
              <span className=" text-[11px] !text-neutral200  font-light">
                {item.role}
              </span>{" "}
            </div>
          ),
        },
        {
          title: "Confirmation",
          dataIndex: "confirmation",
        },
        {
          title: "Phone",
          dataIndex: "phone",
        },
        {
          title: "Status",
          dataIndex: "status",
          render: (status: { personstatus: string; personId: string }) => (
            <span
              className={`text-sm font-medium py-1 block text-center w-[80px] rounded-[4px]
                    ${
                      status.personId === "1"
                        ? "bg-success100 text-success800"
                        : ""
                    }
                    ${
                      status.personId === "2"
                        ? "bg-neutral40 text-neutral500"
                        : ""
                    }
                    ${
                      status.personId === "3"
                        ? "bg-[#FEE3B1] text-[#E69702]"
                        : ""
                    }
                    ${
                      status.personId === "4" ? "bg-danger50 text-neutral0" : ""
                    }
                    ${
                      status.personId === "5" ? "bg-danger50 text-neutral0" : ""
                    }
                    `}
            >
              {status.personstatus}
            </span>
          ),
        },

        {
          title: "",
          dataIndex: "action",
          key: "action",
          render: (data: {
            personKey: string
            personId: number
            name: string
          }) => (
            <Dropdown
              menu={{
                items: actionItems(data),
              }}
            >
              <button>
                <EllipsisHorizontalIcon className="w-6 h-6 text-[#004AF5]" />
              </button>
            </Dropdown>
          ),
        },
      ] || [],
    []
  )

  //  three dots on each row
  const actionItems = (data: {
    personKey: string
    personId: number
    name: string
  }) => [
    {
      key: "1",
      label: (
        <span
          onClick={() => {
            setOpenConfirmationModal(true)
            setActiveConfirmEmp({
              fullName: data.name,
              person_id: String(data.personId),
            })
          }}
        >
          {" "}
          Confirm
        </span>
      ),
    },
    // {
    //   key: "2",
    //   label: (
    //     <Link to={`/dashboard/confirmation/view-response/${data.personKey}`}>
    //       View Response
    //     </Link>
    //   ),
    // },
  ]
  const rowSelection = {
    onChange: (
      selectedRowKeys: React.Key[],
      selectedRows: any[],
      info: any
    ) => {
      if (selectedRows.length > 0) {
        const selectedRowPersonId = selectedRows.map((itm: any) => itm.key)
        setSelectedPersons(selectedRowPersonId)
        setShowConfirmBtn(true)
      } else {
        setShowConfirmBtn(false)
      }
    },
  }
  const handleSelected = () => {
    if (activeConfirmEmp.fullName !== "") {
      confirmSelected({
        person_list: [activeConfirmEmp.person_id],
        send_email: "yes",
      })
    } else {
      const body = {
        person_list: selectedPersons,
        send_email: "yes",
      }
      confirmSelected(body)
    }
  }
  return (
    <div className="relative !font-avenir text-[#42526D]">
      <TopActionBar>
        <div className="flex justify-between w-full">
          <div className="flex gap-2 items-center">
            <Input
              placeholder="Search"
              prefix={
                <MagnifyingGlassIcon className="w-4 h-4 text-neutral-300" />
              }
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className="font-avenir "
            />
            <Select
              onChange={(selected) => {
                if (selected) {
                  const depnt = departmentData?.find(
                    (department) => department.text === selected
                  )
                  if (depnt) {
                    startTransition(() => {
                      setDepartmentId(depnt?.id?.toString())
                    })
                  }
                } else {
                  setDepartmentId("")
                }
              }}
              loading={isDepartmentDataLoading}
              allowClear
              filterOption={(input, option) => {
                if (option) {
                  const value = (option.value as string) || ""
                  return value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                } else {
                  return false
                }
              }}
              placeholder="Filter by department"
              // className="mb-0 min-w-[156px] [&_.ant-select-selector]:!min-h-[36px] flex items-center"
              showSearch={false}
            >
              {departmentData
                ?.map((department) => ({
                  label: department.text,
                  value: department.text,
                }))
                ?.map((option, index) => (
                  <Select.Option
                    key={index}
                    value={option.value}
                  >
                    {option.label}
                  </Select.Option>
                ))}
            </Select>
          </div>

          {/* <div className="flex gap-8">
            <Button
              color="primary"
              title="Confirm all"
            >
              Confirm all employees
            </Button>
          </div> */}
        </div>
        {/* Table */}
      </TopActionBar>

      <DashboardContainer>
        <div className="flex flex-col h-full">
          <div className="flex items-center  justify-between mb-4">
            <H6 className="">Confirmation</H6>
            <div className="flex ">
              {showConfirmBtn && (
                <Button
                  color="primary"
                  title="Confirm all"
                  onClick={() => setOpenConfirmationModal(true)}
                >
                  Confirm selected employees
                </Button>
              )}
            </div>
          </div>
          <Table
            columns={TableColumns}
            dataSource={TableData}
            loading={isLoadingConfirmation || isDepartmentDataLoading}
            className="text-[#42526D] min-h-screen"
            pagination={{
              total: confirmationData?.total,
              defaultPageSize: 10,
            }}
            rowSelection={{
              type: "checkbox",
              ...rowSelection,
            }}
            rowClassName={"[&_>_*]:!font-avenir text-[#42526D]"}
          />
        </div>
      </DashboardContainer>
      {openConfirmationModal && (
        <ModalWrapper
          title={
            activeConfirmEmp.fullName !== ""
              ? `Confirm ${activeConfirmEmp.fullName}?`
              : "Employee Confirmation"
          }
          // subTitle=""
          onClose={() => {
            setOpenConfirmationModal(false)
            setActiveConfirmEmp({
              fullName: "",
              person_id: "",
            })
          }}
        >
          <div className="p-5 items-center  flex flex-col">
            <div>
              <InformationCircleIcon
                width={100}
                height={100}
                className="text-[#FAB941]"
              />
            </div>
            {/* <Form layout="vertical">
              <div>
                <AntDesignCheckbox
                  id="agree"
                  label="Send questionnaire to employee before confirmation"
                  onChange={() => setSendQuestionnaire(!sendQuestionnaire)}
                  required
                />
              </div>
              {sendQuestionnaire && (
                <AntDesignSelect
                  label="Questionnaire"
                  name="cutOff"
                  // isLoading={isBanksDataLoading}
                  onChange={(selected) => {
                    const selectedOption = QuestionnaireList?.find(
                      (option) => option === selected
                    )
                  }}
                  required
                  options={QuestionnaireList?.map((option) => ({
                    label: option,
                    value: option,
                  }))}
                  // options={
                  //   isBanksDataLoading
                  //     ? []
                  //     : banks?.map((bank) => ({
                  //         label: bank.description,
                  //         value: bank.description,
                  //       }))
                  // }
                  placeholder=""
                />
              )}
                </Form>*/}
            <p className="text-sm text-neutral500 my-2">
              Once you confirm this employee. Their profile will be updated in
              the system, ensuring seamless access to company resources and
              privileges.
            </p>
            <div className="py-5 w-full  flex gap-4 ">
              <Button
                title="cancel"
                color="neutral"
                className="w-full"
                onClick={() => {
                  setOpenConfirmationModal(false)
                  setActiveConfirmEmp({
                    fullName: "",
                    person_id: "",
                  })
                }}
              >
                Cancel
              </Button>

              <Button
                title="download"
                color="primary"
                className="w-full"
                onClick={() => {
                  handleSelected()
                }}
              >
                {isLoadingConfirmSelected ? <Spin /> : "Confirm"}
              </Button>
            </div>
          </div>
        </ModalWrapper>
      )}
    </div>
  )
}

export default Confirmation
