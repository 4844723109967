import { RadioInput, Button, Label } from "components"
import { useEffect, useState } from "react"
import { useGet } from "../../../../../utils/useFetch"
import { CustomSelect } from "../../../people/people/form"
import { LoadingIndicatorWhite } from "components/custom/LoaderWhite"
import { Link, useNavigate } from "react-router-dom"

import { axiosInstance } from "utils/request"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useAppNotificationStore, useAuthStore } from "stores"
import { Select, Checkbox, Form, Input, Space, Radio, Tooltip } from "antd"
import { IWalletOverViewData } from "../../../wallets/interface"
import { formatCurrencyNoSymbol } from "utils/currency"
import SegmentWrapper from "components/custom/SegmentWrapper"
import {
  ChevronDownIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline"
import { CheckboxChangeEvent } from "antd/es/checkbox"
import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"
import usePermissions from "hooks/usePermissions"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"
import { AntDesignRadio } from "components/antd/AntDesignRadio"
import { IWalletPade } from "pages/dashboard/wallets/interface/wallet"
import { walletBaseUrl, walletPublicKey } from "utils/newbaseurl"

const EmployeePaymentSchedule = () => {
  const navigate = useNavigate()
  const { toast } = useAppNotificationStore()
  const { user } = useAuthStore()
  const [form] = Form.useForm()
  const walletId = user?.organisationWalletId
  const [transferType, setTransferType] = useState("Single")
  const [useSameSettingsEmployees, setUseSameSettingsEmployees] = useState(true)
  const [useSameSettingsContractor, setUseSameSettingsContractor] =
    useState(true)
  const [activeMultiplePersonEmp, setActiveMultiplePersonEmp] = useState("")
  const [activeMultiplePersonCont, setActiveMultiplePersonCont] = useState("")
  const [addContToPayment, setAddContToPayment] = useState(false)
  const includeExitedValue = Form.useWatch("includeExited", form)
  const [formBodySingle, setFormBodySingle] = useState<IFormData>({
    description: "",
    person_id: "",
    person_name: "",
    person_bank: "",
    person_account_number: "",
    amount: "",
    au_comment: "",
    state: "",
  })
  const [formBodyMultipleEmp, setFormBodyMultipleEmp] = useState<any>({
    description: "",
    amount: "",
    persons: [],
    others: [],
    au_comment: "",
    state: "",
  })
  const [formBodyMultipleCont, setFormBodyMultipleCont] = useState<any>({
    description: "",
    amount: "",
    persons: [],
    others: [],
    au_comment: "",
    state: "",
  })
  const [scheduleConfirmation, setScheduleConfirmation] = useState(false)
  const [requiredAmountError, setRequiredAmountError] = useState(false)
  const [nameError, setNameError] = useState("")

  const [canCreate] = usePermissions({
    allowedPermissions: ["can_create_pay_schedules"],
  })
  const { isLoading: isEmployeeLoading, data: employees } = useGet<IEmployee[]>(
    {
      url: `/admin/people/getforselectionwithaccountdetails?active=${!includeExitedValue}`,
      enabled: includeExitedValue !== undefined,
    }
  )
  const { isLoading: isContractorsLoading, data: contractors } = useGet<any[]>({
    url: `/admin/contractors/getforselectionwithaccountdetails?search=""`,
  })

  const { isLoading: isLoadingOverview, data: walletData } =
    useQuery<IWalletPade>({
      queryKey: [`${walletBaseUrl}/v1/wallets/${walletId}`],
      queryFn: async () => {
        const res = await axiosInstance.get(
          `${walletBaseUrl}/v1/wallets/${walletId}?populate=virtualAccounts`,
          {
            headers: {
              api_key: walletPublicKey,
            },
          }
        )
        return res.data
      },

      // keepPreviousData: true,
      refetchOnWindowFocus: false,
      cacheTime: 24 * 60 * 60 * 1000,
    })
  const ngnBalance = walletData?.data?.balances?.find(
    (balance) => balance.currency === "NGN"
  )
  const { isLoading: isCheckingScheduleName, mutate: checkScheduleName } =
    useMutation({
      mutationFn: async (body: any) => {
        const res = await axiosInstance.get(
          `/admin/payment_schedules/check_custom_payment_schedule?description=${body.description}&payment_schedule_id=${body.id}`
        )
        return res.data
      },
      onSuccess: (data) => {
        if (data.status === "error" && data.msg) {
          setNameError(data.msg)

          toast.error(data.msg)
        }
      },
      onError: (data: any) => {
        setNameError(data.msg)
        toast.error(data.msg)
      },
    })
  const { isLoading: isSubmitting, mutate: submit } = useMutation({
    mutationFn: async (body: any) => {
      const res = await axiosInstance.post(
        "/admin/payment_schedules/create_custom_payment_schedule",
        body
      )
      return res.data
    },
    onSuccess: (data) => {
      {
        data.msg && toast.success(data.msg)
      }
      mixPanelEvent("pay-schedule-custom-add-success")
      navigate("/dashboard/payment-schedules/custom")
    },
    onError: (data: any) => {
      {
        data.msg && toast.error(data.msg)
      }
    },
  })
  const handleCreateSinglePayment = () => {
    const singleBody = [
      {
        description: formBodySingle.description,
        person_id: formBodySingle.person_id,
        amount: formBodySingle.amount,
        au_comment: "",
        state: "",
      },
    ]

    submit(singleBody)
  }
  const handleCreateMultiplePayment = () => {
    const found = formBodyMultipleEmp?.persons?.find(
      (per: any, idx: any) => per.amount == ""
    )

    if (found) {
      setRequiredAmountError(true)
    } else {
      const multipleBodyEmp = formBodyMultipleEmp.persons?.map(
        (per: any, index: any) => {
          return {
            description: formBodyMultipleEmp.description,
            person_id: String(per.person_id),
            contractor_id: "",
            amount: per.amount,
            au_comment: "",
            state: "",
          }
        }
      )
      const multipleBodyCont = formBodyMultipleCont.persons?.map(
        (per: any, index: any) => {
          return {
            description: formBodyMultipleEmp.description,
            contractor_id: String(per.contractor_id),
            person_id: "",
            amount: per.amount,
            au_comment: "",
            state: "",
          }
        }
      )

      const updatedBody = [...multipleBodyEmp, ...multipleBodyCont]

      submit(updatedBody)
    }
  }

  const onChangeEmployees = (e: CheckboxChangeEvent) => {
    setUseSameSettingsEmployees(e.target.checked)
    if (e.target.checked) {
      const updatedPersons = formBodyMultipleEmp.persons.map((per: any) => {
        return {
          person_name: per.person_name,
          person_id: String(per.person_id),
          person_bank: per.person_bank,
          person_account_number: per.person_account_number,
          amount: formBodyMultipleEmp.amount,
        }
      })

      setFormBodyMultipleEmp({
        ...formBodyMultipleEmp,
        persons: updatedPersons,
      })
    } else {
      const updatedPersonsNoAmount = formBodyMultipleEmp.persons.map(
        (per: any) => {
          return {
            person_name: per.person_name,
            person_id: String(per.person_id),
            person_bank: per.person_bank,
            person_account_number: per.person_account_number,
            amount: "",
          }
        }
      )

      setFormBodyMultipleEmp({
        ...formBodyMultipleEmp,
        persons: updatedPersonsNoAmount,
      })
    }
  }
  const onChangeContractors = (e: CheckboxChangeEvent) => {
    setUseSameSettingsContractor(e.target.checked)
    if (e.target.checked) {
      const updatedPersons = formBodyMultipleCont.persons.map((per: any) => {
        return {
          person_name: per.person_name,
          contractor_id: String(per.contractor_id),
          person_bank: per.person_bank,
          person_account_number: per.person_account_number,
          amount: formBodyMultipleCont.amount,
        }
      })

      setFormBodyMultipleCont({
        ...formBodyMultipleCont,
        persons: updatedPersons,
      })
    } else {
      const updatedPersonsNoAmount = formBodyMultipleCont.persons.map(
        (per: any) => {
          return {
            person_name: per.person_name,
            contractor_id: String(per.contractor_id),
            person_bank: per.person_bank,
            person_account_number: per.person_account_number,
            amount: "",
          }
        }
      )

      setFormBodyMultipleCont({
        ...formBodyMultipleCont,
        persons: updatedPersonsNoAmount,
      })
    }
  }
  useEffect(() => {
    setRequiredAmountError(false)
  }, [formBodyMultipleEmp.persons])

  return (
    <DashboardWrapper>
      <div className=" flex flex-col md:flex-row items-start justify-start mt-5 gap-10">
        <div className="w-full lg:w-10/12">
          <SegmentWrapper
            title="Custom Schedule for Employees"
            // subtitle="You can send payment schedules to your employees."
          >
            <Form
              layout="vertical"
              form={form}
              initialValues={{
                includeExited: false,
              }}
            >
              <div className="w-full  bg-white  rounded-md border-neutral-300 px-4 md:px-6 lg:px-8 xl:px-[60px]">
                <div id="custom-payment-transfer-type">
                  <Label required="true">Choose type of transfer</Label>
                  <div className="mt-2 flex items-center gap-4">
                    <label className="flex items-center justify-center">
                      <RadioInput
                        name="transfer-type"
                        onChange={() => {
                          setTransferType("Single")
                          setScheduleConfirmation(false)
                        }}
                        checked={transferType == "Single" ? true : false}
                      />
                      <span className="ml-2 text-sm text-[#5D6B82]">
                        Single transfer
                      </span>
                    </label>
                    <label className="flex items-center justify-center">
                      <RadioInput
                        name="transfer-type"
                        onChange={() => {
                          setTransferType("Multiple")
                          setScheduleConfirmation(false)
                        }}
                        checked={transferType == "Multiple" ? true : false}
                      />
                      <span className="ml-2 text-sm text-[#5D6B82]">
                        Multiple transfer
                      </span>
                    </label>
                  </div>
                </div>
                {transferType == "Single" ? (
                  <>
                    <div className=" mt-6 flex flex-col md:grid grid-cols-2 gap-x-6 gap-y-4  md:gap-y-6 items-start">
                      <div
                        className="col-span-2 w-full"
                        id="custom-payment-desc"
                      >
                        {" "}
                        <Form.Item
                          className="!mb-0"
                          name="amountSingle"
                          label={
                            <span className="text-neutral500 text-sm font-avenir flex items-center">
                              Payment description
                              <Tooltip
                                title="Mamixum of 50 characters"
                                trigger={["hover"]}
                              >
                                <span>
                                  <ExclamationCircleIcon className="w-4 h-4 opacity-60 hover:opacity-100" />
                                </span>
                              </Tooltip>
                            </span>
                          }
                          extra={
                            nameError && (
                              <span className="text-danger500">
                                {nameError}
                              </span>
                            )
                          }
                          rules={[
                            {
                              validator: async (_, value) => {
                                const wordCount = value
                                  ? value.trim().split(/\s+/).length
                                  : 0
                                if (wordCount > 50) {
                                  throw new Error(
                                    "Description must be 50 words or less"
                                  )
                                }
                              },
                            },
                          ]}
                        >
                          <Input
                            value={formBodySingle.description}
                            placeholder="Enter"
                            maxLength={50}
                            onChange={(e) => {
                              const inputValue = e.target.value
                              setNameError("") // Clear error state
                              setFormBodySingle({
                                // Update form state
                                ...formBodySingle,
                                description: inputValue,
                              })
                            }}
                            onBlur={(e) => {
                              if (e.target.value) {
                                // Perform additional onBlur actions if needed
                                checkScheduleName({
                                  description: e.target.value,
                                  id: "-1",
                                })
                              }
                            }}
                          />
                        </Form.Item>
                      </div>
                      <div
                        className="w-full"
                        id="custom-payment-amount"
                      >
                        <Label
                          className="mb-3"
                          required="true"
                        >
                          Select employee
                        </Label>

                        <div className="mt-1 w-full">
                          <CustomSelect
                            showSearch
                            label=""
                            name="employees"
                            required
                            onChange={(selected) => {
                              const person = employees?.find(
                                (emp) => emp.full_name === selected
                              )
                              if (person) {
                                setFormBodySingle({
                                  ...formBodySingle,
                                  person_name: person?.full_name,
                                  person_id: String(person?.person_id),
                                  person_bank: person.bank,
                                  person_account_number: person.account_number,
                                })
                              }
                            }}
                            isLoading={isEmployeeLoading}
                            options={
                              isEmployeeLoading
                                ? []
                                : employees?.map((dept) => ({
                                    label: dept.full_name,
                                    value: dept.full_name,
                                  }))
                            }
                            placeholder="Select employee"
                          />
                          <Form.Item
                            name="includeExited"
                            valuePropName="checked"
                            className="!-mt-4"
                          >
                            <Checkbox>Include exited employees</Checkbox>
                          </Form.Item>
                        </div>
                      </div>
                      <div className="w-full">
                        <Label
                          className="mb-2"
                          required="true"
                        >
                          Enter amount
                        </Label>
                        <div className="mt-1">
                          <Input
                            type="number"
                            addonBefore="₦"
                            style={{ width: "100%" }}
                            value={formBodySingle.amount}
                            onChange={(e) => {
                              setFormBodySingle({
                                ...formBodySingle,
                                amount: e.target.value,
                              })
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center gap-3 ">
                      <Checkbox
                        className="text-sm text-[#5D6B82]"
                        defaultChecked={false}
                        onChange={() =>
                          setScheduleConfirmation(!scheduleConfirmation)
                        }
                      >
                        I confirm the payment details above
                      </Checkbox>
                    </div>
                    <div className="flex justify-end w-full gap-6  py-4 bg-white border-t mt-14 shadow-footer  items-center">
                      <Link to="/dashboard/payment-schedules/custom">
                        <Button
                          title="Previous"
                          color="secondary"
                        >
                          Cancel
                        </Button>
                      </Link>

                      <Button
                        title="Next"
                        color="primary"
                        submitting={isSubmitting}
                        onClick={() => {
                          mixPanelEvent("pay-schedule-custom-add-init")
                          if (canCreate) {
                            handleCreateSinglePayment()
                          } else {
                            toast.unauthorized()
                          }
                        }}
                        disabled={
                          formBodySingle.description == "" ||
                          formBodySingle.person_id == "" ||
                          formBodySingle.amount == "" ||
                          scheduleConfirmation == false ||
                          nameError !== ""
                            ? true
                            : false
                        }
                      >
                        Confirm{" "}
                      </Button>
                    </div>
                  </>
                ) : (
                  <div className=" mt-6 flex flex-col w-full  gap-y-4">
                    <div
                      className=""
                      id="custom-payment-desc"
                    >
                      {" "}
                      <Form.Item
                        className="!mb-0"
                        name="amountMultiple"
                        label={
                          <span className="text-neutral500 text-sm font-avenir flex items-center">
                            Payment description
                            <Tooltip
                              title="Mamixum of 50 characters"
                              trigger={["hover"]}
                            >
                              <span>
                                <ExclamationCircleIcon className="w-4 h-4 opacity-60 hover:opacity-100" />
                              </span>
                            </Tooltip>
                          </span>
                        }
                        extra={
                          nameError !== "" && (
                            <span className="text-danger500">{nameError} </span>
                          )
                        }
                      >
                        <Input
                          color="neutral"
                          maxLength={50}
                          value={formBodyMultipleEmp.description}
                          placeholder="Enter"
                          onChange={(e) => {
                            setNameError("")
                            setFormBodyMultipleEmp({
                              ...formBodyMultipleEmp,
                              description: e.target.value,
                            })
                          }}
                          onBlur={(e) => {
                            {
                              e.target.value &&
                                checkScheduleName({
                                  description: e.target.value,
                                  id: "-1",
                                })
                            }
                          }}
                        />
                      </Form.Item>
                    </div>
                    <div
                      className="mt-4"
                      id="custom-payment-amount"
                    >
                      <Label
                        className="mb-2"
                        required="true"
                      >
                        Select employees
                      </Label>

                      <div className="mt-2">
                        <Select
                          mode="multiple"
                          style={{ width: "100%" }}
                          placeholder="Please select"
                          loading={isEmployeeLoading}
                          onSelect={(value: any) => {
                            const person = employees?.find(
                              (emp) => emp.full_name === value
                            )

                            if (person) {
                              setFormBodyMultipleEmp({
                                ...formBodyMultipleEmp,
                                persons: [
                                  ...formBodyMultipleEmp.persons,
                                  {
                                    person_name: person?.full_name,
                                    person_id: String(person?.person_id),
                                    person_bank: person.bank,
                                    person_account_number:
                                      person.account_number,
                                    amount:
                                      useSameSettingsEmployees == true
                                        ? formBodyMultipleEmp.amount
                                        : "",
                                  },
                                ],
                              })
                            }
                          }}
                          onDeselect={(value: any) => {
                            const filteredPersons =
                              formBodyMultipleEmp.persons?.filter(
                                (emp: any) => emp.person_name !== value
                              )

                            setFormBodyMultipleEmp({
                              ...formBodyMultipleEmp,
                              persons: filteredPersons,
                            })
                          }}
                          onChange={(values: string[]) => {
                            return []
                          }}
                          options={
                            isEmployeeLoading
                              ? []
                              : employees?.map((person) => ({
                                  label: person.full_name,
                                  value: person.full_name,
                                }))
                          }
                        />
                        <div className="mt-3">
                          <Form.Item
                            name="includeExited"
                            valuePropName="checked"
                            className="!-mt-4"
                          >
                            <Checkbox>Include exited employees</Checkbox>
                          </Form.Item>
                        </div>
                      </div>
                      <div className="mt-1">
                        <Checkbox
                          defaultChecked={true}
                          className="text-sm text-[#5D6B82]"
                          onChange={onChangeEmployees}
                        >
                          Use same setting for all selected employees
                        </Checkbox>
                      </div>
                    </div>
                    {useSameSettingsEmployees == true ? (
                      <div className="mt-4">
                        <Label>Amount</Label>

                        <div className="mt-1">
                          <Input
                            type="number"
                            className="!h-8"
                            addonBefore="₦"
                            style={{ width: "100%" }}
                            defaultValue={formBodyMultipleEmp.amount}
                            onChange={(e) => {
                              const updatedPersons =
                                formBodyMultipleEmp.persons.map((per: any) => {
                                  return {
                                    person_name: per.person_name,
                                    person_id: String(per.person_id),
                                    person_bank: per.person_bank,
                                    person_account_number:
                                      per.person_account_number,
                                    amount: e.target.value,
                                  }
                                })

                              setFormBodyMultipleEmp({
                                ...formBodyMultipleEmp,
                                persons: updatedPersons,
                                amount: e.target.value,
                              })
                            }}
                            placeholder="Enter amount"
                          />
                        </div>
                      </div>
                    ) : formBodyMultipleEmp.persons.length ? (
                      <div className="mt-4 flex flex-col ">
                        <Label
                          className="mb-2"
                          required="true"
                        >
                          Employee amount settings
                          {requiredAmountError && (
                            <span className="ml-1 text-xs mt-0.5   text-danger500">
                              (required)
                            </span>
                          )}
                        </Label>
                        {formBodyMultipleEmp.persons.map(
                          (person: any, index: any) => (
                            <div className="border border-[#C2C7D0] mb-2 rounded-5px overflow-hidden">
                              <div
                                key={index}
                                className={`p-3  cursor-pointer     justify-between w-full  flex items-center ${
                                  activeMultiplePersonEmp == person.person_id &&
                                  "border-b border-[#C2C7D0] bg-background"
                                }`}
                                onClick={() => {
                                  if (
                                    activeMultiplePersonEmp != person.person_id
                                  ) {
                                    setActiveMultiplePersonEmp(person.person_id)
                                  } else {
                                    setActiveMultiplePersonEmp("")
                                  }
                                }}
                              >
                                <h6 className=" text-neutral700 text-sm font-medium font-avenir">
                                  {" "}
                                  {person?.person_name}
                                </h6>{" "}
                                <ChevronDownIcon className="w-4 h-4 " />
                              </div>
                              {activeMultiplePersonEmp == person.person_id && (
                                <div className="px-3 mt-3">
                                  <Form layout="vertical">
                                    <Form.Item
                                      label="Amount"
                                      name="amount"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Please input amount!",
                                        },
                                      ]}
                                    >
                                      <Input
                                        type="number"
                                        addonBefore="₦"
                                        style={{ width: "100%" }}
                                        // value={ person.amount }
                                        defaultValue={person.amount}
                                        onChange={(e) => {
                                          const updatedPersons =
                                            formBodyMultipleEmp.persons.map(
                                              (per: any) => {
                                                if (
                                                  per.person_id ===
                                                  person.person_id
                                                ) {
                                                  return {
                                                    person_name:
                                                      per.person_name,
                                                    person_id: String(
                                                      per.person_id
                                                    ),
                                                    person_bank:
                                                      per.person_bank,
                                                    person_account_number:
                                                      per.person_account_number,
                                                    amount: e.target.value,
                                                  }
                                                } else {
                                                  // Keep the person object as is
                                                  return per
                                                }
                                              }
                                            )

                                          setFormBodyMultipleEmp({
                                            ...formBodyMultipleEmp,
                                            persons: updatedPersons,
                                          })
                                        }}
                                      />
                                    </Form.Item>
                                  </Form>
                                </div>
                              )}
                            </div>
                          )
                        )}{" "}
                        {requiredAmountError && (
                          <span className=" text-xs mt-0.5   text-danger500">
                            All amounts fields are required!
                          </span>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    <Form layout="vertical">
                      <AntDesignRadio
                        label="Would you like to include contractors in this payment schedule"
                        name="access"
                        value={addContToPayment}
                        required
                      >
                        <Space direction="horizontal">
                          <Radio
                            value={true}
                            checked={addContToPayment ? true : false}
                            onChange={() => {
                              setAddContToPayment(true)
                            }}
                          >
                            Yes
                          </Radio>
                          <Radio
                            value={false}
                            checked={addContToPayment === false ? true : false}
                            onChange={() => {
                              setAddContToPayment(false)
                            }}
                          >
                            No
                          </Radio>
                        </Space>
                      </AntDesignRadio>
                    </Form>
                    {/* CONTRACTORS */}
                    {addContToPayment && (
                      <div className="">
                        <div
                          className="mb-4"
                          id="custom-payment-amount"
                        >
                          <Label
                            className="mb-2"
                            required="true"
                          >
                            Select contractors
                          </Label>

                          <div className="mt-2">
                            <Select
                              mode="multiple"
                              style={{ width: "100%" }}
                              placeholder="Please select"
                              loading={isContractorsLoading}
                              onSelect={(value: any) => {
                                const person = contractors?.find(
                                  (emp) => emp.name === value
                                )

                                if (person) {
                                  setFormBodyMultipleCont({
                                    ...formBodyMultipleCont,
                                    persons: [
                                      ...formBodyMultipleCont.persons,
                                      {
                                        person_name: person?.name,
                                        contractor_id: String(
                                          person?.contractor_id
                                        ),
                                        person_bank: person.bank,
                                        person_account_number:
                                          person.account_number,
                                        amount:
                                          useSameSettingsContractor == true
                                            ? formBodyMultipleCont.amount
                                            : "",
                                      },
                                    ],
                                  })
                                }
                              }}
                              onDeselect={(value: any) => {
                                const filteredPersons =
                                  formBodyMultipleCont.persons?.filter(
                                    (emp: any) => emp.person_name !== value
                                  )

                                setFormBodyMultipleCont({
                                  ...formBodyMultipleCont,
                                  persons: filteredPersons,
                                })
                              }}
                              onChange={(values: string[]) => {
                                return []
                              }}
                              options={
                                isContractorsLoading
                                  ? []
                                  : contractors?.map((person) => ({
                                      label: person.name,
                                      value: person.name,
                                    }))
                              }
                            />
                          </div>
                          <div className="mt-1">
                            <Checkbox
                              defaultChecked={true}
                              className="text-sm text-[#5D6B82]"
                              onChange={onChangeContractors}
                            >
                              Use same setting for all selected contractors
                            </Checkbox>
                          </div>
                        </div>
                        {useSameSettingsContractor == true ? (
                          <div className="mt-4">
                            <Label>Contractor amount</Label>

                            <div className="mt-1">
                              <Input
                                type="number"
                                className="!h-8"
                                addonBefore="₦"
                                style={{ width: "100%" }}
                                defaultValue={formBodyMultipleCont.amount}
                                placeholder="Enter amount"
                                onChange={(e) => {
                                  const updatedPersons =
                                    formBodyMultipleCont.persons.map(
                                      (per: any) => {
                                        return {
                                          person_name: per.person_name,
                                          contractor_id: String(
                                            per.contractor_id
                                          ),
                                          person_bank: per.person_bank,
                                          person_account_number:
                                            per.person_account_number,
                                          amount: e.target.value,
                                        }
                                      }
                                    )

                                  setFormBodyMultipleCont({
                                    ...formBodyMultipleCont,
                                    persons: updatedPersons,
                                    amount: e.target.value,
                                  })
                                }}
                              />
                            </div>
                          </div>
                        ) : formBodyMultipleCont.persons.length ? (
                          <div className="mt-4 flex flex-col ">
                            <Label
                              className="mb-2"
                              required="true"
                            >
                              Contractor amount settings
                              {requiredAmountError && (
                                <span className="ml-1 text-xs mt-0.5   text-danger500">
                                  (required)
                                </span>
                              )}
                            </Label>
                            {formBodyMultipleCont.persons.map(
                              (person: any, index: any) => (
                                <div className="border border-[#C2C7D0] mb-2 rounded-5px overflow-hidden">
                                  <div
                                    key={index}
                                    className={`p-3  cursor-pointer     justify-between w-full  flex items-center ${
                                      activeMultiplePersonCont ==
                                        person.contractor_id &&
                                      "border-b border-[#C2C7D0] bg-background"
                                    }`}
                                    onClick={() => {
                                      if (
                                        activeMultiplePersonCont !=
                                        person.contractor_id
                                      ) {
                                        setActiveMultiplePersonCont(
                                          person.contractor_id
                                        )
                                      } else {
                                        setActiveMultiplePersonCont("")
                                      }
                                    }}
                                  >
                                    <h6 className=" text-neutral700 text-sm font-medium font-avenir">
                                      {" "}
                                      {person?.person_name}
                                    </h6>{" "}
                                    <ChevronDownIcon className="w-4 h-4 " />
                                  </div>
                                  {activeMultiplePersonCont ==
                                    person.contractor_id && (
                                    <div className="px-3 mt-3">
                                      <Form layout="vertical">
                                        <Form.Item
                                          label="Amount"
                                          name="amount"
                                          rules={[
                                            {
                                              required: true,
                                              message: "Please input amount!",
                                            },
                                          ]}
                                          required
                                        >
                                          <Input
                                            type="number"
                                            addonBefore="₦"
                                            style={{ width: "100%" }}
                                            // value={ person.amount }
                                            defaultValue={person.amount}
                                            onChange={(e) => {
                                              const updatedPersons =
                                                formBodyMultipleCont.persons.map(
                                                  (per: any) => {
                                                    if (
                                                      per.contractor_id ===
                                                      person.contractor_id
                                                    ) {
                                                      return {
                                                        person_name:
                                                          per.person_name,
                                                        contractor_id: String(
                                                          per.contractor_id
                                                        ),
                                                        person_bank:
                                                          per.person_bank,
                                                        person_account_number:
                                                          per.person_account_number,
                                                        amount: e.target.value,
                                                      }
                                                    } else {
                                                      // Keep the person object as is
                                                      return per
                                                    }
                                                  }
                                                )

                                              setFormBodyMultipleCont({
                                                ...formBodyMultipleCont,
                                                persons: updatedPersons,
                                              })
                                            }}
                                          />
                                        </Form.Item>
                                      </Form>
                                    </div>
                                  )}
                                </div>
                              )
                            )}{" "}
                            {requiredAmountError && (
                              <span className=" text-xs mt-0.5   text-danger500">
                                All amounts fields are required!
                              </span>
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                    <div className="flex items-center gap-3 ">
                      <Checkbox
                        className="text-sm text-[#5D6B82]"
                        defaultChecked={false}
                        onChange={() =>
                          setScheduleConfirmation(!scheduleConfirmation)
                        }
                      >
                        I confirm the payment details above
                      </Checkbox>
                    </div>
                    <div className="flex  items-center justify-end w-full gap-6  py-4 bg-white border-t mt-14 shadow-footer ">
                      <Link to="/dashboard/payment-schedules/custom">
                        <Button
                          title="Previous"
                          color="secondary"
                        >
                          Cancel
                        </Button>
                      </Link>

                      <Button
                        title="Next"
                        color="primary"
                        submitting={isSubmitting}
                        onClick={() => {
                          mixPanelEvent("pay-schedule-custom-add-init")

                          if (canCreate) {
                            handleCreateMultiplePayment()
                          } else {
                            toast.unauthorized()
                          }
                        }}
                        disabled={
                          formBodyMultipleEmp.description == "" ||
                          formBodyMultipleEmp.persons.length == 0 ||
                          scheduleConfirmation == false ||
                          nameError !== ""
                            ? true
                            : false
                        }
                      >
                        Confirm
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </Form>
          </SegmentWrapper>
        </div>{" "}
        {(formBodySingle.person_id !== "" ||
          formBodyMultipleEmp.persons.length > 0 ||
          formBodyMultipleCont.persons.length > 0) && (
          <div className="w-full lg:w-5/12 lg:border-l-2 border-#C2C7D0 lg:pl-10">
            <div className="h-auto bg-primary700  rounded-[10px] px-4 py-5 justify-between flex flex-col mb-7 ">
              <h3 className="text-white font-extrabold text-base mb-2">
                Payment Information
              </h3>
              <div className="border  border-white w-full" />
              {isLoadingOverview ? (
                <div className="w-full flex justify-center items-center py-3 mt-4">
                  <LoadingIndicatorWhite />
                </div>
              ) : (
                <>
                  <div className="grid grid-cols-2 my-4">
                    <p className="text-white text-sm">Wallet balance</p>

                    <p className="text-white text-sm font-bold text-right">
                      ₦
                      {ngnBalance?.balance &&
                        formatCurrencyNoSymbol(
                          Number(ngnBalance?.balance) || 0
                        )}
                    </p>
                  </div>
                  <div className="grid grid-cols-2 my-3">
                    <p className="text-white text-sm">
                      Total transaction amount
                    </p>
                    <p className="text-white text-sm font-bold text-right">
                      {transferType == "Single"
                        ? `   ₦ ${formBodySingle.amount}`
                        : `₦ ${formatCurrencyNoSymbol(
                            formBodyMultipleEmp.persons.reduce(function (
                              acc: any,
                              obj: any
                            ) {
                              return Number(acc) + Number(obj.amount)
                            },
                            0)
                          )}`}
                    </p>
                  </div>
                </>
              )}
            </div>
            {transferType == "Single" &&
              formBodySingle.amount !== "" &&
              formBodySingle.person_id !== "" && (
                <div className="mt-10">
                  <p className="font-bold text-[#42526D]">Preview</p>
                  <hr className="my-4 border-b border-[#C2C7D0]" />
                  <div className="space-y-4 border-b border-[#C2C7D0] mb-4 pb-4">
                    <div className="grid grid-cols-2">
                      <span className="text-[#98A1B0] text-sm">
                        Beneficiary
                      </span>
                      <span className="text-[#243757] font-bold text-sm">
                        {formBodySingle.person_name}
                      </span>
                    </div>
                    <div className="grid grid-cols-2">
                      <span className="text-[#98A1B0] text-sm">Bank Name</span>
                      <span className="text-[#243757] font-bold text-sm">
                        {formBodySingle.person_bank}
                      </span>
                    </div>
                    <div className="grid grid-cols-2">
                      <span className="text-[#98A1B0] text-sm">
                        Account Number
                      </span>
                      <span className="text-[#243757] font-bold text-sm">
                        {formBodySingle.person_account_number}
                      </span>
                    </div>
                    <div className="grid grid-cols-2">
                      <span className="text-[#98A1B0] text-sm">Amount</span>
                      <span className="text-[#243757] font-bold text-sm">
                        ₦ {formBodySingle.amount}
                      </span>
                    </div>
                  </div>
                </div>
              )}

            {transferType == "Multiple" &&
              (formBodyMultipleEmp.persons.length > 0 ||
                formBodyMultipleCont.persons.length) && (
                <div className="mt-10">
                  <p className="font-bold text-[#42526D]">Preview</p>
                  <hr className="my-4 border-b border-[#C2C7D0]" />
                  <div className=" h-[500px] overflow-y-scroll scrollbar-hide ">
                    {formBodyMultipleEmp.persons.map(
                      (item: any, index: any) => (
                        <div
                          key={index}
                          className="space-y-4 border-b border-[#C2C7D0] mb-4 pb-4"
                        >
                          <div className="grid grid-cols-2">
                            <span className="text-[#98A1B0] text-sm">
                              Beneficiary
                            </span>
                            <span className="text-[#243757] font-bold text-sm">
                              {item.person_name}
                            </span>
                          </div>
                          <div className="grid grid-cols-2">
                            <span className="text-[#98A1B0] text-sm">
                              Bank Name
                            </span>
                            <span className="text-[#243757] font-bold text-sm">
                              {item.person_bank}
                            </span>
                          </div>
                          <div className="grid grid-cols-2">
                            <span className="text-[#98A1B0] text-sm">
                              Account Number
                            </span>
                            <span className="text-[#243757] font-bold text-sm">
                              {item.person_account_number}
                            </span>
                          </div>
                          <div className="grid grid-cols-2">
                            <span className="text-[#98A1B0] text-sm">
                              Amount
                            </span>
                            <span className="text-[#243757] font-bold text-sm">
                              ₦{" "}
                              {formatCurrencyNoSymbol(Number(item.amount || 0))}
                            </span>
                          </div>
                        </div>
                      )
                    )}
                    {formBodyMultipleCont.persons.map(
                      (item: any, index: any) => (
                        <div
                          key={index}
                          className="space-y-4 border-b border-[#C2C7D0] mb-4 pb-4"
                        >
                          <div className="grid grid-cols-2">
                            <span className="text-[#98A1B0] text-sm">
                              Beneficiary
                            </span>
                            <span className="text-[#243757] font-bold text-sm">
                              {item.person_name}
                            </span>
                          </div>
                          <div className="grid grid-cols-2">
                            <span className="text-[#98A1B0] text-sm">
                              Bank Name
                            </span>
                            <span className="text-[#243757] font-bold text-sm">
                              {item.person_bank}
                            </span>
                          </div>
                          <div className="grid grid-cols-2">
                            <span className="text-[#98A1B0] text-sm">
                              Account Number
                            </span>
                            <span className="text-[#243757] font-bold text-sm">
                              {item.person_account_number}
                            </span>
                          </div>
                          <div className="grid grid-cols-2">
                            <span className="text-[#98A1B0] text-sm">
                              Amount
                            </span>
                            <span className="text-[#243757] font-bold text-sm">
                              ₦{" "}
                              {formatCurrencyNoSymbol(Number(item.amount || 0))}
                            </span>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              )}
          </div>
        )}
      </div>
    </DashboardWrapper>
  )
}

export default EmployeePaymentSchedule

interface IBalance {
  balance: string
  text: string
  currency: string
  icon: string
}

interface IBank {
  name: string
  icon: string
}
interface IEmployee {
  person_id: number
  full_name: string
  identification_number: string | null
  person_status_id: number
  bank?: string
  account_number?: string
}
interface ISelectedEmployee {
  name: string
  person_id: string
  amount: string
}
interface IFormData {
  description: string
  person_id?: string
  person_name?: string
  person_bank?: string
  person_account_number?: string
  amount: string
  au_comment: string
  state: string
  employees?: any[]
}
