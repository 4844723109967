import { useState } from "react"
import { formatCurrencyNoSymbol } from "utils/currency"
// import { IStatementData } from "../interface/statement"
import { Select, Table, DatePicker, Tooltip } from "antd"
import dayjs from "dayjs"
import { Button, DashboardContainer } from "components"
import { useAuthStore } from "stores"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"
import { walletBaseUrl, walletPublicKey } from "utils/newbaseurl"
import { useQuery } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { formatDateReadable, formatTimeReadble } from "utils/formatDate"
import Balance from "../../../assets/images/balance_icon.svg"

import { StatsCard } from "../documents/company-details/Cards"
import { IWalletPade } from "./interface/wallet"
import { InformationCircleIcon } from "@heroicons/react/24/outline"
import { dateData } from "./Statements"
import { CSVLink } from "react-csv"

const TransactionsNew = () => {
  const { user } = useAuthStore()
  const walletId = user?.organisationWalletId
  const [productType, setProductType] = useState("All")
  const [startDate, setStartDate] = useState(
    dayjs(new Date()).subtract(7, "day").format("YYYY/MM/DD") || ""
  )
  const [endDate, setEndDate] = useState(
    dayjs(new Date())?.format("YYYY/MM/DD") || ""
  )
  const [isCustom, setIsCustom] = useState(false)

  const [activePeriod, setActivePeriod] = useState<dateData>({
    id: "1",
    period: "One Week",
    startDate: "",
    endDate: "",
  })
  const {
    isLoading: isTransactionLoading,
    data: transactionsData,
    refetch: refetchTranData,
  } = useQuery<any>({
    queryKey: [
      `${walletBaseUrl}/v1/wallets/${walletId}/payment-transactions?limit=0&startDate=${
        startDate ? dayjs(startDate).format("YYYY-MM-DD") : ""
      }&endDate=${endDate ? dayjs(endDate).format("YYYY-MM-DD") : ""}`,
    ],
    queryFn: async () => {
      const res = await axiosInstance.get(
        `${walletBaseUrl}/v1/wallets/${walletId}/payment-transactions?limit=0&startDate=${
          startDate ? dayjs(startDate).format("YYYY-MM-DD") : ""
        }&endDate=${endDate ? dayjs(endDate).format("YYYY-MM-DD") : ""}`,
        {
          headers: {
            api_key: walletPublicKey,
          },
        }
      )
      return res.data
    },
    onSuccess: (data: any) => {
      mixPanelEvent("wallet-statement-listing")
    },

    // keepPreviousData: true,
    refetchOnWindowFocus: false,
    cacheTime: 24 * 60 * 60 * 1000,
  })
  const { isLoading: isLoadingOverview, data: newWalletData } =
    useQuery<IWalletPade>({
      queryKey: [`${walletBaseUrl}/v1/wallets/${walletId}`],
      queryFn: async () => {
        const res = await axiosInstance.get(
          `${walletBaseUrl}/v1/wallets/${walletId}?populate=virtualAccounts`,
          {
            headers: {
              api_key: walletPublicKey,
            },
          }
        )
        return res.data
      },

      // keepPreviousData: true,
      refetchOnWindowFocus: false,
      cacheTime: 24 * 60 * 60 * 1000,
    })
  const body = {
    q: "",
    page: "",
  }
  const { isLoading: isLoadingProduct, data: productData } = useQuery<
    WalletProduct[]
  >({
    queryKey: [`/commonutils/getwalletproducts?is_collection=false`],
    queryFn: async () => {
      const res = await axiosInstance.post(
        `/commonutils/getwalletproducts?is_collection=false`,
        body
      )
      return res.data
    },

    refetchOnWindowFocus: false,
    cacheTime: 24 * 60 * 60 * 1000,
  })
  const ngnBalance = newWalletData?.data?.balances?.find(
    (balance) => balance.currency === "NGN"
  )
  const StatementTableData =
    transactionsData?.data?.docs
      ?.filter((rev: any) => {
        if (productType === "All") {
          return transactionsData?.data?.docs
        } else {
          const product = rev.product
          return product?.toLowerCase().includes(productType?.toLowerCase())
        }
      })
      ?.map((item: any) => ({
        key: item._id,
        date: {
          date: formatDateReadable(String(item?.createdAt)),
          time: formatTimeReadble(String(item?.createdAt)),
        },
        amount: {
          value: item.amount,
          charge: item.fee,
        },
        type: item.product || "-",

        reference: item.reference,
        recipient: item.accountName || "-",
        bank: {
          bank_name: item.bankName || "-",
          account_number: item.accountNumber,
        },
        remark: item.description,

        status: {
          status: item.status,
          statusMessage: item?.statusMessage,
        },
      })) || []

  const StatementTableColumns =
    [
      {
        title: "Date",
        dataIndex: "date",
        render: (item: { date: string; time: string }) => (
          <div className="whitespace-nowrap flex flex-col ">
            <span className={` font-avenir text-sm text-[#42526D]`}>
              {item.date},
            </span>
            <span className={` font-avenir text-xs text-neutral300`}>
              {item.time}
            </span>
          </div>
        ),
      },
      {
        title: "Amount",
        dataIndex: "amount",
        render: (amount: { value: string; charge: string }) => (
          <div className={`font-avenir text-sm text-[#42526D] flex-nowrap`}>
            <p>₦{formatCurrencyNoSymbol(Number(amount.value))}</p>
            <span className="text-xs flex-nowrap`">
              Charge: ₦{formatCurrencyNoSymbol(Number(amount.charge))}
            </span>
          </div>
        ),
      },
      {
        title: "Type",
        dataIndex: "type",
        render: (type: string) => (
          <div className={`font-avenir text-sm text-[#42526D] capitalize`}>
            <p>{type}</p>
          </div>
        ),
      },
      {
        title: "Reference",
        dataIndex: "reference",
        render: (reference: string) => (
          <div
            className={`font-avenir text-sm text-[#42526D] w-[150px] overflow-hidden`}
          >
            <p>{reference}</p>
          </div>
        ),
      },
      {
        title: "Recipient",
        dataIndex: "recipient",
        render: (recipient: string) => (
          <div className={`font-avenir text-sm text-[#42526D] w-[150px]`}>
            <p>{recipient}</p>
          </div>
        ),
      },
      {
        title: "Bank",
        dataIndex: "bank",
        render: (bank: { bank_name: string; account_number: string }) => (
          <div className={`font-avenir text-sm text-[#42526D] flex-nowrap`}>
            <p>{bank.bank_name}</p>
            <span className="text-xs flex-nowrap`">{bank.account_number}</span>
          </div>
        ),
      },
      {
        title: "Remark",
        dataIndex: "remark",
        render: (remark: string) => (
          <div
            className={`font-avenir text-sm text-[#42526D] w-[170px] overflow-hidden`}
          >
            <p>{remark}</p>
          </div>
        ),
      },
      {
        title: "Status",
        dataIndex: "status",
        render: (status: { status: string; statusMessage: string }) => (
          <div className="flex items-center ">
            <span
              className={`font-avenir text-sm  font-medium py-1 block  text-center w-[100px] rounded-[4px]  capitalize
                    ${
                      status.status === "successful"
                        ? "bg-success100 text-success800"
                        : status.status === "failed"
                        ? "bg-danger50 text-danger500"
                        : "bg-neutral40 text-neutral500"
                    }

                    `}
            >
              {status.status}
            </span>
            {status.status === "failed" && (
              <Tooltip
                title={status?.statusMessage}
                className="ml-1 cursor-pointer"
              >
                <InformationCircleIcon className="h-5 w-5" />
              </Tooltip>
            )}
          </div>
        ),
      },
    ] || []
  const cardData = [
    {
      id: "balance",
      icon: Balance,
      title: "Balance",
      value: ` ₦ ${
        ngnBalance?.balance
          ? formatCurrencyNoSymbol(Number(ngnBalance?.balance) || 0)
          : 0
      }`,
    },
  ]
  const periods: dateData[] = [
    {
      id: "1",
      period: "One Week",
      startDate: "",
      endDate: "",
    },
    {
      id: "2",
      period: "One Month",
      startDate: "",
      endDate: "",
    },

    {
      id: "3",
      period: "Custom",
      startDate: "",
      endDate: "",
    },
  ]
  const headers = [
    // { label: "Date", key: "createdAt" },
    { label: "Date", key: "createdAt" },
    { label: "Recipient", key: "accountName" },
    { label: "Amount", key: "amount" },
    { label: "Charge", key: "fee" },
    { label: "Type", key: "product" },
    { label: "Bank", key: "bankName" },
    { label: "Account Number", key: "accountNumber" },
    { label: "Reference", key: "reference" },
    { label: "Status", key: "status" },
    { label: "Remark", key: "description" },
  ]

  const csvProps = {
    filename: "transactions.csv",
    headers: headers,
    data: transactionsData?.data?.docs || [],
  }
  return (
    <DashboardContainer>
      <div className="flex flex-col gap-5">
        <div className="flex justify-between w-full items-center">
          <h5 className="text-lg font-bold text-neutral700 ">Transactions</h5>
          <div>
            <CSVLink {...csvProps}>
              <Button
                color="primary"
                title="Download"
              >
                Download
              </Button>
            </CSVLink>
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 ">
          {cardData.map((card, idx) => (
            <StatsCard
              id={card.id}
              key={idx}
              image={card.icon}
              title={card.title}
              text={card.value}
            />
          ))}
        </div>
        <div className=" bg-white my-4 px-4 pt-5">
          <div className="w-full flex justify-between items-center">
            <div className=" w-40 flex justify-start pb-4">
              <Select
                defaultValue="All"
                style={{ width: "100%" }}
                onChange={(value) => {
                  setProductType(value)
                }}
                options={
                  productData
                    ? [
                        { value: "All", label: "All" },
                        ...productData.map((item) => ({
                          value: item.description,
                          label: item.description,
                        })),
                      ]
                    : []
                }
                className="font-avenir  w-fit"
              />
            </div>
            <div className="flex gap-2 justify-end items-center mb-4">
              <div className="bg-[#F3F5FB] rounded-sm cursor-pointer flex ">
                {periods?.map((it: dateData) => (
                  <div
                    onClick={() => {
                      if (it?.id === "1") {
                        setStartDate(
                          dayjs(new Date())
                            .subtract(7, "day")
                            .format("YYYY/MM/DD") || ""
                        )
                        setEndDate(
                          dayjs(new Date())?.format("YYYY/MM/DD") || ""
                        )
                      } else if (it?.id === "2") {
                        setStartDate(
                          dayjs(new Date())
                            .subtract(30, "day")
                            .format("YYYY/MM/DD") || ""
                        )
                        setEndDate(
                          dayjs(new Date())?.format("YYYY/MM/DD") || ""
                        )
                      } else {
                        setIsCustom(true)
                        setActivePeriod({
                          id: "3",
                          period: "Custom",
                          startDate: "",
                          endDate: "",
                        })
                        return
                      }
                      setActivePeriod(it)
                      setIsCustom(false)
                    }}
                    key={it?.id}
                    className={`${
                      it.id === activePeriod?.id && "bg-primary500 text-white"
                    } whitespace-nowrap text-[#42526D] h-full w-full p-2 rounded-md`}
                  >
                    {it?.period}
                  </div>
                ))}
              </div>
              {isCustom && (
                <div className="flex gap-2">
                  <DatePicker
                    placeholder="Start date"
                    onChange={(value) => {
                      setStartDate(value?.format("YYYY/MM/DD") || "")
                      setEndDate("")
                    }}
                    disabledDate={(date) => {
                      return date.isAfter(dayjs())
                    }}
                  />
                  <DatePicker
                    placeholder="End date"
                    disabled={!startDate}
                    onChange={(value) => {
                      setEndDate(value?.format("YYYY/MM/DD") || "")
                    }}
                    disabledDate={(date) => {
                      const testDate = dayjs(startDate)
                      const before = date.isBefore(testDate)
                      const after = date.isAfter(testDate.add(1, "M"))
                      const afterToday = date.isAfter(dayjs())

                      return before || after || afterToday
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <Table
            columns={StatementTableColumns}
            dataSource={StatementTableData}
            loading={isTransactionLoading}
            className="text-[#42526D]"
            pagination={{
              defaultPageSize: 20,
            }}
            scroll={{
              x: "100%",
            }}
          />
        </div>
      </div>
    </DashboardContainer>
  )
}

export default TransactionsNew
interface WalletProduct {
  walletProductId: number
  description: string
  isDeleted: boolean | null
  isActive: boolean | null
  isCollection: boolean
}
