import { Alert, Input, Tooltip } from "antd"
import { CustomInput } from "components/new-stuff/form"
import { Button } from "components"
import { walletBaseUrl, walletPublicKey } from "utils/newbaseurl"
import FormSectionWrapper from "components/new-stuff/form/FormSectionWrapper"
import { useNavigate } from "react-router-dom"
import { InformationCircleIcon } from "@heroicons/react/24/outline"
import { useMutation } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { useAppNotificationStore, useAuthStore } from "stores"
import { IUserWalletID, KycLevel } from "../interface"

const Bvn = ({
  setCurrentPage,
  formBody,
  setFormBody,
  isActive,
  form,
  Form,
  status,
  walletData,
  currentLevel,
}: {
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
  formBody: any
  setFormBody: any
  isActive: boolean
  form: any
  Form: any
  status?: "not setup" | "completed" | "pending" | string
  walletData: IUserWalletID | undefined
  currentLevel: null | KycLevel
}) => {
  const navigate = useNavigate()
  const { toast } = useAppNotificationStore()
  const { user } = useAuthStore()
  const EmployeeWalletID = user?.userWalletId

  const bvn = Form.useWatch("bvn", form)
  const firstName = Form.useWatch("bvn_first_name", form)
  const lastName = Form.useWatch("bvn_last_name", form)

  const onFinish = (values: any) => {
    setFormBody({
      ...formBody,
      bvn_firstName: firstName,
      bvn_lastName: lastName,
      bvn,
    })

    if (
      formBody?.bvn_firstName === firstName &&
      formBody?.bvn_lastName === lastName &&
      formBody?.bvn === bvn
    ) {
      if (walletData?.data?.pinSet) {
        currentLevel?.level === 2
          ? setCurrentPage(3)
          : navigate("/employee/dashboard/wallet/overview")
      } else {
        navigate("/employee/dashboard/wallet/create-pin")
      }
    } else {
      submit({
        bvnFirstName: firstName,
        bvnLastName: lastName,
        walletId: EmployeeWalletID ? EmployeeWalletID : "",
        bvn,
      })
    }
  }

  const { isLoading: isLoading, mutate: submit } = useMutation({
    mutationFn: async (body: IBody) => {
      const res: any = await axiosInstance.post(
        `${walletBaseUrl}/v1/wallets/kyc/verify-bvn`,
        body,
        {
          headers: {
            api_key: walletPublicKey,
          },
        }
      )

      if (res?.data?.success) {
        return res?.data
      } else {
        return res.response?.data
      }
    },
    onSuccess: (data: any) => {
      if (data.success) {
        data.message && toast.success(data.message)
        if (walletData?.data?.pinSet) {
          navigate("/employee/dashboard/wallet/overview")
        } else {
          navigate("/employee/dashboard/wallet/create-pin")
        }
      } else {
        data.message && toast.error(data.message)
        if (data.code === 409) {
          if (walletData?.data?.pinSet) {
            navigate("/employee/dashboard/wallet/overview")
          } else {
            navigate("/employee/dashboard/wallet/create-pin")
          }
        }
      }
    },
    onError: (data: any) => {
      data?.response?.data?.message &&
        toast.error(data?.response?.data?.message)

      // To Check if BVN HAS BEEN VERIFIED OR NOT
      if (data?.response?.status === 409) {
        if (walletData?.data?.pinSet) {
          // navigate("/employee/dashboard/wallet/overview")
        } else {
          navigate("/employee/dashboard/wallet/create-pin")
        }
      }
    },
  })

  return (
    <FormSectionWrapper
      isActive={isActive}
      name="Tier 2 KYC (BVN)"
      title="Tier 2 KYC (BVN)"
      subtitle="You have a daily withdrawal limit of up to N1,000,000.00"
      form={form}
      layout="vertical"
      showDropdown
      onDropDown={() => {
        isActive ? setCurrentPage(0) : setCurrentPage(2)
      }}
      status={status}
      footerBtns={
        <div className="flex  items-center justify-between ">
          <div className="flex items-center">
            <Button
              color="secondary"
              type="button"
              onClick={() => {
                if (walletData?.data?.pinSet) {
                  navigate("/employee/dashboard/wallet/overview")
                } else {
                  navigate("/employee/dashboard/wallet/create-pin")
                }
              }}
            >
              Skip to Wallet{" "}
            </Button>
            {currentLevel?.level === 1 && (
              <Tooltip
                title={
                  "If you skip to wallet, you won't be able to make transactions outside PaidHR. Please provide your BVN for inter-bank transfers"
                }
              >
                <InformationCircleIcon className="ml-1 w-4 h-4" />
              </Tooltip>
            )}
          </div>

          <div className="flex justify-end items-center gap-4">
            <Button
              color="secondary"
              type="button"
              onClick={() => {
                setCurrentPage(1)
              }}
            >
              Previous
            </Button>

            <Button
              color="primary"
              type="button"
              onClick={onFinish}
              submitting={isLoading}
            >
              Save
            </Button>
          </div>
        </div>
      }
      initialValues={{
        bvn_first_name: formBody?.bvn_firstName,
        bvn_last_name: formBody?.bvn_lastName,
        bvn: formBody?.bvn,
      }}
    >
      <div className="space-y-6">
        <div className="  px-4 md:px-6 lg:px-8 xl:px-[60px] pb-5">
          <h6 className=" text-padeBlack font-bold">What you get in Tier 2</h6>
          <p>
            You get a PaidHR bank account that can be used for inter bank
            transactions
          </p>

          <div className="border border-[#6c7a9326] mt-[34px] rounded-5px ">
            <div className="lg:px-[29px] py-[21px] border-b-2 border-[#6c7a9326] ">
              <h6 className=" text-padeBlack font-bold">Link BVN</h6>
            </div>
            <div className="w-full px-[29px] py-6">
              <Alert
                message={
                  "We take your security very seriously and so we will not store any information that may be an infringement on your privacy. What this means is that we will not have access to your other bank accounts or financial history. Your information is safe with us."
                }
                type="info"
                showIcon
                className="  font-avenir text-neutral500 text-sm w-full flex justify-between items-center "
              />

              <div className="mt-10 flex flex-col md:grid grid-cols-2 gap-x-10">
                <CustomInput
                  label={"First name"}
                  name="bvn_first_name"
                  required
                  disabled={formBody?.bvn_firstName ? true : false}
                />
                <CustomInput
                  label={"Last name"}
                  name="bvn_last_name"
                  required
                  disabled={formBody?.bvn_lastName ? true : false}
                />
                <div className="col-span-2">
                  <Form.Item
                    label={
                      <span className="text-neutral300 text-sm flex items-center">
                        BVN (dial *565*0# if you don’t remember your BVN)
                        <Tooltip title=" Kindly input the BVN of a director in your organization">
                          <InformationCircleIcon className="w-4 h-4 ml-1" />
                        </Tooltip>
                      </span>
                    }
                    name="bvn"
                    rules={[
                      { required: true, message: "Please enter this field" },
                      { pattern: /^\d{11}$/, message: "BVN must be 11 digits" },
                    ]}
                  >
                    <Input
                      type="text"
                      placeholder="11 digits"
                      className="font-circular text-neutral400"
                      disabled={formBody?.bvn ? true : false}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FormSectionWrapper>
  )
}

export default Bvn

interface IBody {
  bvnFirstName?: string
  bvnLastName?: string
  walletId: string
  bvn: string
}
