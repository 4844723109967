import { Progress } from "antd"
type setupProps = {
  percent?: number
}
const ProgressBar: React.FC<setupProps> = ({ percent }) => {
  return (
    <div className="emp_progress">
      <Progress
        percent={percent}
        className=" text-white"
        style={{
          color: "white",
        }}
      />
    </div>
  )
}
export default ProgressBar
