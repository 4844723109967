import { useState, useEffect } from "react"
import { Alert, Form, Input, Tooltip } from "antd"

import FormSectionWrapper from "pages/dashboard/people/people/FormSectionWrapper"
import PrevNextButton from "pages/dashboard/people/people/form/PrevNextButton"

import { CustomSelect } from "pages/dashboard/people/people/form"
import { useGet, usePost } from "utils/useFetch"
import { IBanksData, ICountry } from "pages/dashboard/people/people/typings"
import { ICurrencies, IKYCData, KYCWalletBody } from "./interface/kyc"
import { userInfo } from "os"
import { useMutation } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"
import { CustomInput } from "components/new-stuff/form"
import { InformationCircleIcon } from "@heroicons/react/24/outline"

export const BvnTab = ({
  setActiveKey,
  isActive,
  setUserInfo,

  userInfo,
}: {
  isActive: boolean
  userInfo: KYCWalletBody
  setUserInfo: Function
  setActiveKey: React.Dispatch<React.SetStateAction<string[]>>
  // kyc: IKYCData | undefined
}) => {
  const [form] = Form.useForm()

  const [updateStatus, setUpdateStatus] = useState("")
  const [alpha2Code, setAlpha2Code] = useState<string>("")
  const { isLoading: isLoading, mutate: submit } = useMutation({
    mutationFn: async (body: KYCWalletBody) => {
      const res = await axiosInstance.post(
        `/admin/company/update_verification`,
        body
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data.status === "success") {
        mixPanelEvent("settings-integration-payment-config-edit-success")
      }
    },
  })

  const onFinish = (values: Values) => {
    const { first_name, last_name, bvn_number } = values
    mixPanelEvent("settings-integration-payment-config-edit-init")
    setUserInfo({
      ...userInfo,

      rep_bvn: bvn_number,
      rep_first_name: first_name,
      rep_last_name: last_name,
    })

    submit({
      rep_bvn: bvn_number,
      rep_first_name: first_name,
      rep_last_name: last_name,
    })
    setActiveKey(["3"])
  }
  const reqBody = {
    q: "",
    page: "",
  }
  const { isLoading: isCountriesLoading, data: countries } = usePost<
    ICountry[]
  >({ url: "/commonutils/getcountries", body: reqBody })
  const { isLoading: isCurrencyLoading, data: currencies } = usePost<
    ICurrencies[]
  >({ url: "/commonutils/getcurrencies?forpayment=true", body: reqBody })
  const {
    data: banks,
    isLoading: isBanksDataLoading,
    refetch,
  } = usePost<IBanksData[]>({
    url: `/commonutils/getbanksbycountrycode?id=${alpha2Code}`,
    enabled: !!alpha2Code,
    body: reqBody,
  })

  const { isLoading: isGetKYCLoading, data: kyc } = useGet<IKYCData>({
    url: `/admin/company/view/settings?id=verify&x=`,
  })

  useEffect(() => {
    if (kyc?.verification) {
      setAlpha2Code(kyc?.verification?.country_code)
    }
  }, [kyc])

  return (
    <FormSectionWrapper
      name="BVN"
      title="BVN"
      subtitle="Enter bank account information where your withdrawals will be deposited"
      isActive={isActive}
      initialValues={{
        first_name: kyc?.verification?.verificationEntities
          ? kyc?.verification?.verificationEntities[0]?.first_name
          : "",
        last_name: kyc?.verification?.verificationEntities
          ? kyc?.verification?.verificationEntities[0]?.last_name
          : "",
        bvn_number: kyc?.verification?.verificationEntities
          ? kyc?.verification?.verificationEntities[0]?.bvn
          : "",
      }}
      layout="vertical"
      form={form}
      onFinish={onFinish}
      style={{ maxWidth: 1024 }}
      autoComplete="off"
      footerBtns={<PrevNextButton showPrevButton={false} />}
    >
      <div className=" px-4 md:px-6 xl:px-[50px]">
        <Alert
          message={
            "We take your security very seriously and so we will not store any information that may be an infringement on your privacy. What this means is that we will not have access to your other bank accounts or financial history. Your information is safe with us."
          }
          type="info"
          showIcon
          // closable
          // description={
          //   "You will be able to pay your employees from your PaidHR account using Direct Deposit after your company verification is complete. Contact us for more information."
          // }
          className="  font-avenir text-neutral500 text-sm w-full flex justify-between items-center "
        />
        <h6 className=" font-bold my-5">
          Kindly enter the BVN details of one of the directors of your
          organization
        </h6>
        <div className="flex flex-col md:grid grid-cols-2 gap-x-10">
          <CustomInput
            required
            label={
              <span className="text-neutral300 text-sm flex  items-center  ">
                First name of director{" "}
                <Tooltip title=" Kindly input the first name of a director in your organization">
                  <InformationCircleIcon className="w-4 h-4  ml-1" />
                </Tooltip>
              </span>
            }
            name="first_name"
          />
          <CustomInput
            required
            label={
              <span className="text-neutral300 text-sm flex  items-center  ">
                Last name of director{" "}
                <Tooltip title=" Kindly input the last name of a director in your organization">
                  <InformationCircleIcon className="w-4 h-4  ml-1" />
                </Tooltip>
              </span>
            }
            name="last_name"
          />

          <Form.Item
            label={
              <span className="text-neutral300 text-sm flex items-center">
                BVN (dial *565*0# if you don’t remember your BVN)
                <Tooltip title=" Kindly input the BVN of a director in your organization">
                  <InformationCircleIcon className="w-4 h-4 ml-1" />
                </Tooltip>
              </span>
            }
            name="bvn_number"
            rules={[
              { required: true, message: "Please enter this field" },
              { pattern: /^\d{11}$/, message: "BVN must be 11 digits" },
            ]}
          >
            <Input
              type="text"
              placeholder="11 digits"
              className="font-circular text-neutral400"
            />
          </Form.Item>
        </div>
      </div>
    </FormSectionWrapper>
  )
}
interface Values {
  first_name: string
  last_name: string
  bvn_number: string
}
