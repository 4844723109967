import { Button, Label, P2 } from "components"
import React, { useEffect, useState } from "react"
import { Select, Checkbox, Form, Spin, Input, InputNumber } from "antd"
import { CustomInput } from "components/new-stuff/form"
import { Link, useNavigate } from "react-router-dom"
import { formatCurrencyNoSymbol } from "utils/currency"
import AntToggle from "components/inputs/AntToggle"
import { IPreviewData } from "../SendMoneyBank"
import EnterPin from "../pin/EnterPin"
import { walletBaseUrl, walletPublicKey } from "utils/newbaseurl"
import { useMutation, useQuery } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { useAppNotificationStore, useAuthStore } from "stores"
import { ITransferBankBody } from "./interface"
import { IBeneficiaries, IResolveBank } from "../beneficiaries/interface"
import { getAcronym } from "../Wallet"
import { KycLevel } from "../kyc/interface"
interface Props {
  setPreviewData: React.Dispatch<React.SetStateAction<IPreviewData[]>>
  currentLevel: null | KycLevel
  amountOverBalance: boolean
  allBeneficiariesFilled: boolean
  fee: string
  setFee: React.Dispatch<React.SetStateAction<string>>
  submitFee: (body: any) => void
  setTotal: (body: any) => void
  x?: string | null
}

const SingleBank = ({
  setPreviewData,
  currentLevel,
  amountOverBalance,
  fee,
  setFee,
  submitFee,
  setTotal,
  allBeneficiariesFilled,

  x,
}: Props) => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const { user } = useAuthStore()
  const EmployeeWalletID = user?.userWalletId
  const { toast } = useAppNotificationStore()
  const [useBeneficiary, setUseBeneficiary] = useState(false)
  const [saveBeneficiary, setSaveBeneficiary] = useState(false)
  const [showPinModal, setShowPinModal] = useState(false)
  const [accountName, setAccountName] = useState("")
  const selectedBank = Form.useWatch("bank", form)
  const accountNumber = Form.useWatch("account_number", form)
  const amount = Form.useWatch("amount", form)
  const description = Form.useWatch("description", form)
  const [beneficiaryInfo, setBeneficiaryInfo] = useState({
    bankCode: "",
    accountNumber: "",
    accountName: "",
    bankName: "",
  })

  const { isLoading: getBanksLoading, data: banksData } = useQuery<any>({
    queryKey: [`${walletBaseUrl}/v1/wallets/banks`],
    queryFn: async () => {
      const res = await axiosInstance.get(`${walletBaseUrl}/v1/wallets/banks`, {
        headers: {
          api_key: walletPublicKey,
        },
      })
      return res.data
    },

    refetchOnWindowFocus: false,
    cacheTime: 24 * 60 * 60 * 1000,

    onSuccess: (data: any) => {},
  })
  const { isLoading: isLoadingBeneficiaries, data: beneficiariesData } =
    useQuery<IBeneficiaries>({
      queryKey: [
        `${walletBaseUrl}/v1/wallets/${EmployeeWalletID}/transfer-beneficiaries`,
      ],
      queryFn: async () => {
        const res = await axiosInstance.get(
          `${walletBaseUrl}/v1/wallets/${EmployeeWalletID}/transfer-beneficiaries`,
          {
            headers: {
              api_key: walletPublicKey,
            },
          }
        )
        return res.data
      },

      // keepPreviousData: true,
      refetchOnWindowFocus: false,
      cacheTime: 24 * 60 * 60 * 1000,
      onSuccess: (data: IBeneficiaries) => {
        if (x) {
          const benFound = data?.data?.find((ben) => ben._id === x)
          if (benFound) {
            setAccountName(benFound?.accountName)
            form.setFieldValue("bank", benFound?.bankCode)
            form.setFieldValue("account_number", benFound?.accountNumber)
            setBeneficiaryInfo({
              bankCode: benFound?.bankCode,
              accountNumber: benFound?.accountNumber,
              accountName: benFound?.accountName,
              bankName: benFound?.bankName,
            })
          }
        }
      },
    })
  const { isLoading: getBankDetails, data: bankDetails } =
    useQuery<IResolveBank>({
      queryKey: [
        `${walletBaseUrl}/v1/wallets/resolve-account`,
        accountNumber,
        selectedBank,
      ],
      queryFn: async () => {
        const res = await axiosInstance.post(
          `${walletBaseUrl}/v1/wallets/resolve-account`,
          {
            accountNumber: accountNumber,
            bankCode: selectedBank,
            currency: "NGN",
          },

          {
            headers: {
              api_key: walletPublicKey,
            },
          }
        )

        return res.data
      },
      enabled:
        selectedBank !== "" &&
        accountNumber?.toString().length === 10 &&
        !useBeneficiary,
      // refetchOnWindowFocus: false,
      // cacheTime: 24 * 60 * 60 * 1000,
      retry: 1,
      onSuccess: (data: any) => {
        if (data.success) {
          setAccountName(data?.data?.accountName)
        } else {
        }
      },
      onError: (data: any) => {
        toast.error(data?.response?.data?.message)
        setAccountName("")
        setBeneficiaryInfo({
          ...beneficiaryInfo,
          accountName: "",
        })
      },
    })
  const { isLoading: isLoading, mutate: submit } = useMutation({
    mutationFn: async (body: ITransferBankBody) => {
      const res = await axiosInstance.post(
        `/employee/wallet/initiate_bank_transfer`,
        body,
        {
          headers: {
            api_key: walletPublicKey,
          },
        }
      )

      return res?.data
    },
    onSuccess: (data: any) => {
      if (data.status === "success") {
        data.msg && toast.success(data.msg)
        navigate("/employee/dashboard/wallet/overview")
      } else {
        data.msg && toast.error(data.msg)
      }
    },
    onError: (data: any) => {
      data.msg && toast.error(data.msg)
    },
  })

  useEffect(() => {
    setPreviewData([
      {
        beneficiary: useBeneficiary
          ? beneficiaryInfo?.accountName
          : accountName,
        bank_name: beneficiaryInfo?.bankName,
        account_number: useBeneficiary
          ? beneficiaryInfo?.accountNumber
          : accountNumber,
        amount: amount ?? "0",
      },
    ])
  }, [beneficiaryInfo, amount, accountNumber, accountName, useBeneficiary])
  const sampleAmounts = [5000, 10000, 20000, 50000]
  const handleSubmit = (value: string) => {
    submit({
      pin: value,
      payments: [
        {
          accountNumber: useBeneficiary
            ? String(beneficiaryInfo?.accountNumber)
            : String(accountNumber),
          bankCode: useBeneficiary ? beneficiaryInfo?.bankCode : selectedBank,
          amount: Number(amount),
          currency: "NGN",
          description: description,
          addToBeneficiary: saveBeneficiary,
          reference: description,
        },
      ],
    })
  }
  const onFinish = (values: any) => {
    // if (fee !== "") {
    setShowPinModal(true)
    // }
  }

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
      >
        <>
          <div className="px-4 md:px-6 lg:px-8 xl:px-[40px]">
            <div className="  mt-5 flex flex-col  items-start w-full">
              <div className="w-full">
                <CustomInput
                  label="Payment description"
                  name="description"
                  required
                />{" "}
              </div>
              <div className="mb-6">
                <Label className="mb-3">Select amount </Label>
                <div className="flex  flex-wrap items-center gap-2">
                  {sampleAmounts.map((amount, index) => (
                    <button
                      key={index}
                      className="h-[36px] w-[97px] border border-[#6c7a9326] rounded-5px bg-neutral30  focus:bg-padeLightBlue  focus:border-primary500"
                      onClick={(e) => {
                        e.preventDefault()
                        form.setFieldValue("amount", String(amount))
                        submitFee({
                          walletId: EmployeeWalletID,
                          payments: [
                            {
                              amount: Number(amount),
                              currency: "NGN",
                            },
                          ],
                        })
                      }}
                    >
                      ₦ {formatCurrencyNoSymbol(amount)}
                    </button>
                  ))}
                </div>
              </div>
              <div className="w-full">
                <Form.Item
                  label={
                    <span className="text-neutral300 text-sm flex items-center">
                      Enter amount{" "}
                    </span>
                  }
                  name="amount"
                  required
                  // rules={[
                  //   {
                  //     validator: (_, value) => {
                  //       const limit =
                  //         currentLevel && currentLevel.level > 2
                  //           ? Number.MAX_SAFE_INTEGER
                  //           : 200000
                  //       if (!value || value <= limit) {
                  //         return Promise.resolve()
                  //       }
                  //       return Promise.reject(
                  //         new Error(
                  //           `You can only send a maximum of ${formatCurrencyNoSymbol(
                  //             limit
                  //           )} to other banks, kindly upgrade your account `
                  //         )
                  //       )
                  //     },
                  //   },
                  //   {
                  //     validator: (_, value) => {
                  //       const minimumAmount = 200
                  //       if (!value || value >= minimumAmount) {
                  //         return Promise.resolve()
                  //       }
                  //       return Promise.reject(
                  //         new Error(`Amount must be at least ₦${minimumAmount}`)
                  //       )
                  //     },
                  //   },
                  // ]}
                >
                  <InputNumber<number>
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) =>
                      value?.replace(/,/g, "") as unknown as number
                    }
                    className="w-full font-circular text-neutral400"
                    addonBefore="₦"
                    placeholder=""
                    onBlur={() => {
                      const amounts = [
                        {
                          amount: Number(amount),
                          currency: "NGN",
                        },
                      ]
                      if (amount) {
                        submitFee({
                          walletId: EmployeeWalletID,
                          payments: amounts,
                        })
                      }
                    }}
                    onChange={() => {
                      setFee("")
                      setTotal("")
                    }}
                  />
                </Form.Item>
              </div>
              <div className=" flex  items-center gap-9 mb-6">
                <Label>
                  Select from saved beneficiaries to send
                  <div className="relative mt-1 h-6">
                    {beneficiariesData?.data
                      ?.slice(0, 5)
                      ?.map((item, index) => (
                        <div
                          key={index}
                          className="h-8 w-8 text-center rounded-full bg-[#E8F0FE] flex justify-center items-center text-[10px] font-bold absolute border-2 border-white"
                          style={{ left: `${index * 20}px` }}
                        >
                          {getAcronym(item?.accountName)}
                        </div>
                      ))}
                  </div>
                </Label>

                <AntToggle
                  name="usebeneficiary"
                  label=""
                  // loading={isCompanyDetailsLoading }
                  checked={useBeneficiary}
                  onChange={(e: any) => {
                    setUseBeneficiary(!useBeneficiary)
                  }}
                />
              </div>
              {/* USE BENEFICIARY OPTION */}
              {useBeneficiary ? (
                <div className="w-full">
                  <div className="mt-1">
                    <Form.Item
                      label={
                        <span className="font-circular text-neutral300 text-sm  ">
                          Select beneficiary to send
                        </span>
                      }
                      name="beneficiary"
                      rules={[
                        { required: true, message: "Please enter this field!" },
                      ]}
                    >
                      <Select
                        showSearch
                        loading={isLoadingBeneficiaries}
                        onChange={(value) => {
                          const selected = beneficiariesData?.data?.find(
                            (item) => item.accountName === value
                          )
                          if (selected) {
                            setBeneficiaryInfo({
                              bankCode: selected?.bankCode,
                              accountNumber: selected?.accountNumber,
                              accountName: selected?.accountName,
                              bankName: selected?.bankName,
                            })
                          }
                        }}
                        options={beneficiariesData?.data
                          ?.filter((item) => item.type === "bank")
                          ?.map((item) => ({
                            label: item?.accountName,
                            value: item?.accountName,
                          }))}
                        filterOption={(input, option) => {
                          if (option) {
                            const value = (option?.label as string) || ""
                            return (
                              value
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            )
                          } else {
                            return false
                          }
                        }}
                        placeholder="Select beneficiary"
                      />
                    </Form.Item>
                  </div>
                </div>
              ) : (
                <div className="w-full">
                  <div>
                    <Form.Item
                      label={
                        <span className="font-circular text-neutral300 text-sm  ">
                          Bank
                        </span>
                      }
                      name="bank"
                      rules={[
                        { required: true, message: "Please enter this field!" },
                      ]}
                    >
                      <Select
                        showSearch
                        loading={getBanksLoading}
                        onChange={(value) => {
                          const selected = banksData?.data?.find(
                            (item: any) => item.code === value
                          )
                          setAccountName("")
                          if (selected) {
                            setBeneficiaryInfo({
                              ...beneficiaryInfo,
                              bankName: selected?.name,
                            })
                          }
                        }}
                        options={banksData?.data?.map((item: any) => ({
                          label: item.name,
                          value: item.code,
                        }))}
                        filterOption={(input, option) => {
                          if (option) {
                            const value = (option?.label as string) || ""
                            return (
                              value
                                ?.toLowerCase()
                                ?.indexOf(input?.toLowerCase()) >= 0
                            )
                          } else {
                            return false
                          }
                        }}
                        placeholder=""
                      />
                    </Form.Item>
                  </div>
                  <CustomInput
                    label="Account number"
                    name="account_number"
                    required
                    type="number"
                    disabled={
                      getBankDetails &&
                      selectedBank !== "" &&
                      accountNumber?.toString().length === 10
                    }
                    onChange={() => setAccountName("")}
                    extra={
                      beneficiaryInfo?.bankName !== "" &&
                      accountNumber?.toString().length === 10 &&
                      getBankDetails ? (
                        <div>
                          <Spin className="h-4 w-4" />
                        </div>
                      ) : bankDetails?.data?.accountName ? (
                        <span>{accountName}</span>
                      ) : null
                    }
                  />

                  <div className=" flex  items-center gap-2 mb-6">
                    <Label>Save beneficiary</Label>
                    <AntToggle
                      name="usebeneficiary"
                      label=""
                      checked={saveBeneficiary}
                      onChange={(e: any) => {
                        setSaveBeneficiary(!saveBeneficiary)
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="flex items-center gap-3 ">
              <Form.Item
                name="scheduleConfirmation"
                valuePropName="checked"
                rules={[
                  {
                    required: true,
                    message: "You must confirm the payment details above.",
                  },
                ]}
              >
                <Checkbox className="text-sm text-[#5D6B82]">
                  I confirm the payment details above
                </Checkbox>
              </Form.Item>
            </div>
            {fee && (
              <P2 className="text-padeSubtext mt-1 min-h-[14px]">
                <>
                  You will be charged{" "}
                  <span className="text-inherit font-semibold">
                    NGN
                    {formatCurrencyNoSymbol(Number(fee) || 0)}
                  </span>{" "}
                  for this transaction.
                </>
              </P2>
            )}
          </div>
          <div className="flex justify-end w-full gap-6 px-4 md:px-6 lg:px-8 xl:px-[40px]  py-3 bg-white border-t shadow-footer  items-center">
            <Link to="/employee/dashboard/wallet/overview">
              <Button
                title="Previous"
                color="secondary"
              >
                Cancel
              </Button>
            </Link>

            <Button
              title="Next"
              color="primary"
              submitting={false}
              disabled={amountOverBalance || !allBeneficiariesFilled}
              onClick={(e) => {
                e.preventDefault()
                form.submit()
              }}
            >
              Confirm{" "}
            </Button>
          </div>
        </>
      </Form>
      <EnterPin
        title="Enter  transaction Pin"
        onClose={() => setShowPinModal(false)}
        open={showPinModal}
        loading={isLoading}
        handleSubmit={handleSubmit}
      />
    </>
  )
}

export default SingleBank
